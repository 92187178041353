import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import { CircularProgress } from '@mui/material'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Asset, Plant } from '../../models/Types_Interfaces'
import { GREEN, YELLOW } from '../../Utils/UtilFunctions/constant'
import {
	getFormatedDate,
	getTimeZoneTimeStamp
} from '../../Utils/UtilFunctions/timeZoneFormatter'
import { ReactComponent as DisconnectedIcon } from './disconnectedIcon.svg'

function AirLeaksAlert(props: any) {
	const ticketsList = useSelector((state: any) => state.ticketsList)
	const plantsList = useSelector((state: any) => state.plantsList)
	const levelsList = useSelector((state: any) => state.levelsList)
	const messagesList = useSelector((state: any) => state.messagesList)
	const navigate = useNavigate()

	return (
		<Modal open={props.open} onClose={props.handleClose} closeAfterTransition>
			<Fade in={props.open}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						position: 'absolute' as const,
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: window?.innerWidth < 400 ? '300px' : '550px',
						boxShadow: '24',
						padding: '16px 40px',
						color: 'white',
						borderRadius: '12px',
						backgroundColor:
							props.alert.health_level >= 5 && props.alert.health_level <= 7
								? YELLOW
								: props.alert.health_level >= 8 &&
									  props.alert.health_level <= 10
									? GREEN
									: YELLOW,
						border:
							props.alert.health_level >= 5 && props.alert.health_level <= 7
								? `3px solid ${YELLOW}`
								: props.alert.health_level >= 8 &&
									  props.alert.health_level <= 10
									? `3px solid ${GREEN}`
									: `3px solid ${YELLOW}`
					}}
				>
					<div
						style={{
							marginTop: '20px'
						}}
					>
						{props.alert.health_level >= 5 && props.alert.health_level <= 7 ? (
							<InfoIcon sx={{ fontSize: '60px' }} />
						) : props.alert.health_level >= 8 &&
						  props.alert.health_level <= 10 ? (
							<CheckCircleIcon sx={{ fontSize: '60px' }} />
						) : (
							<DisconnectedIcon />
						)}
					</div>

					<div
						style={{
							marginBottom: '5px',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<h5 style={{ fontSize: '1.2rem' }}>
							{plantsList.plants.find((plant: Plant) => {
								return plant._id === props.alert.plant_id
							}) !== undefined
								? plantsList.plants.find((plant: Plant) => {
										return plant._id === props.alert.plant_id
									}).plant_name
								: 'Plant not found'}
						</h5>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black',
								padding: '12px',
								borderRadius: '3px',
								boxShadow: '1px 1px 0px 0px #000000',
								width: '98%',
								margin: '5px'
							}}
						>
							<h5
								style={{
									fontWeight: '800',
									color: '#000',
									textAlign: 'left',
									fontSize: '12px'
								}}
							>
								Asset Name
							</h5>

							<p style={{ textAlign: 'left', fontSize: '12px' }}>
								{levelsList.levels.find((asset: Asset) => {
									return asset._id === props.alert.asset_id
								}) !== undefined
									? levelsList.levels.find((asset: Asset) => {
											return asset._id === props.alert.asset_id
										}).asset_alias ||
										levelsList.levels.find((asset: Asset) => {
											return asset._id === props.alert.asset_id
										}).asset_name
									: 'Asset Not Found'}
							</p>
						</div>

						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black',
								padding: '12px',
								borderRadius: '3px',
								boxShadow: '1px 1px 0px 0px #000000',
								width: '98%',
								margin: '5px'
							}}
						>
							<h5
								style={{
									fontWeight: '800',
									color: '#000',
									textAlign: 'left',
									fontSize: '12px'
								}}
							>
								Health Status
							</h5>

							<p style={{ textAlign: 'left', fontSize: '12px' }}>
								{props.alert.health_level >= 5 && props.alert.health_level <= 7
									? (props.alert.health_status = 'Alarm')
									: props.alert.health_level >= 8 &&
										  props.alert.health_level <= 10
										? (props.alert.health_status = 'Acceptable')
										: (props.alert.health_status = 'Disconnected')}
							</p>
						</div>
					</div>

					<div
						style={{
							backgroundColor: 'rgba(255, 255, 255, 0.7)',
							color: 'black',
							padding: '12px',
							borderRadius: '3px',
							boxShadow: '1px 1px 0px 0px #000000',
							width: '98%',
							margin: '5px'
						}}
					>
						<h5
							style={{
								fontWeight: '800',
								color: '#000',
								textAlign: 'center',
								fontSize: '12px'
							}}
						>
							Description
						</h5>

						<p>
							{props.alert.message_data !== undefined &&
								`On  ${
									props.alert?.timestamp !== undefined &&
									props.alert?.timestamp !== null &&
									getFormatedDate(
										plantsList.plants.find((plant: any) => {
											return plant._id === props.alert.plant_id
										})?.plant_timezone,
										getTimeZoneTimeStamp(
											plantsList.plants.find((plant: any) => {
												return plant._id === props.alert.plant_id
											})?.plant_timezone,
											props.alert?.timestamp
										),
										'MM/DD/yyyy'
									)
								} at ${
									props.alert?.timestamp !== undefined &&
									props.alert?.timestamp !== null &&
									getFormatedDate(
										plantsList.plants.find((plant: any) => {
											return plant._id === props.alert.plant_id
										})?.plant_timezone,
										getTimeZoneTimeStamp(
											plantsList.plants.find((plant: any) => {
												return plant._id === props.alert.plant_id
											})?.plant_timezone,
											props.alert?.timestamp
										),
										'hh:mm A z'
									)
								}, the health level changed from ${
									props.alert.message_data?.PreviousHealth
								} to ${props.alert.message_data?.CurrentHealth}`}
						</p>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								props.handleClose()
							}}
							type='button'
						>
							Close
						</button>

						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								props.setOpen(false)
								navigate(
									`/maintenance/ticket-details/${props.alert.ticket_id}/${props.alert.message_id}`,
									{
										replace: true
									}
								)
							}}
							disabled={ticketsList.loading || messagesList.loading}
							type='button'
						>
							{(ticketsList.loading || messagesList.loading) && (
								<CircularProgress
									sx={{
										color: 'black',
										height: '1px',
										margin: '0px 20px 0px 10px'
									}}
									size={18}
								/>
							)}
							Go to Ticket
						</button>
					</div>
				</div>
			</Fade>
		</Modal>
	)
}

export default AirLeaksAlert
