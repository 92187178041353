import { FC, useState, createContext } from 'react'
type SidebarContextType = { sidebarToggle: any; toggleSidebar: () => void }

export const SidebarContext = createContext<SidebarContextType>(
	{} as SidebarContextType
)

interface SidebarProviderProps {
	children: any
}

export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
	const [sidebarToggle, setSidebarToggle] = useState(false)
	const toggleSidebar = () => {
		setSidebarToggle(!sidebarToggle)
	}

	return (
		<SidebarContext.Provider value={{ sidebarToggle, toggleSidebar }}>
			{children}
		</SidebarContext.Provider>
	)
}
