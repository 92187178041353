import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Grid, TextField } from '@mui/material'
import { clone, cloneDeep, find, isEmpty, set } from 'lodash'
import { useEffect, useState } from 'react'
import { WQMS_RECIPE_MAPPING_REGEX } from '../../Utils/SelfServiceHelper'

const WQMSRecipeMapping = (props: any) => {
	const { items, onSeqChange, onError } = props

	const [showNewField, setShowNewField] = useState<boolean>(false)
	const [newFieldValue, setNewFieldValue] = useState<any>({})
	const [newFieldError, setNewFieldError] = useState<any>({})
	const [error, setError] = useState<any[]>([])

	const keyAlreadyPresent = (keyValue: string, editIndex?: number) => {
		let valuePresent = false
		items.forEach((element: any, index: number) => {
			if (element.key === keyValue && editIndex === -1) valuePresent = true

			if (element.key === keyValue && editIndex !== index) valuePresent = true
		})

		return valuePresent
	}

	const handleChange = (value: string, property: string, editIndex: number) => {
		const clonedUpdatedItems = cloneDeep(items)
		clonedUpdatedItems.map((item: any, index: number) => {
			if (editIndex === index) {
				item[property] = value
			}
			return item
		})

		const valueCondition =
			property === 'value' && !WQMS_RECIPE_MAPPING_REGEX.test(value)
		const displayValueCondition =
			property === 'key' &&
			(value === JSON.stringify(0) || keyAlreadyPresent(value, editIndex))

		const errorClone = clone(error)
		if (isEmpty(value) || valueCondition || displayValueCondition) {
			onError(true)
			set(errorClone, `[${editIndex}].${property}`, 'Invalid value')
			setError(errorClone)
		} else {
			onError(false)
			set(errorClone, `[${editIndex}].${property}`, undefined)
			setError(errorClone)
		}

		const isErrorPresent = find(errorClone, function (element) {
			if (!isEmpty(element)) {
				return true
			}
		})

		onSeqChange(clonedUpdatedItems, !isErrorPresent)
	}

	const handleNewRecordBlur = (value: string, property: string) => {
		const updatedFieldData = {
			...cloneDeep(newFieldValue),
			[property]: value
		}

		const valueCondition =
			property === 'value' && !WQMS_RECIPE_MAPPING_REGEX.test(value)
		const displayValueCondition =
			property === 'key' &&
			(value === JSON.stringify(0) || keyAlreadyPresent(value, -1))

		const newFieldErrorClone = clone(newFieldError)
		if (isEmpty(value) || valueCondition || displayValueCondition) {
			onError(true)
			set(newFieldErrorClone, `${property}`, 'Invalid value')
			setNewFieldError(newFieldErrorClone)
		} else {
			onError(false)
			set(newFieldErrorClone, `${property}`, undefined)
			setNewFieldError(newFieldErrorClone)
		}

		setNewFieldValue(updatedFieldData)
	}

	const handleNewFieldCheck = () => {
		const updatedData = cloneDeep(items)

		const updatedError = { key: '', tag: '' }

		const valueCondition =
			isEmpty(newFieldValue.value) ||
			!WQMS_RECIPE_MAPPING_REGEX.test(newFieldValue.value)
		const keyCondition =
			isEmpty(newFieldValue.key) ||
			newFieldValue.key === JSON.stringify(0) ||
			keyAlreadyPresent(newFieldValue.key, -1)

		if (keyCondition) {
			onError(true)
			set(updatedError, `key`, 'Invalid value')
		}
		if (valueCondition) {
			onError(true)
			set(updatedError, `value`, 'Invalid value')
		}

		if (valueCondition || keyCondition) {
			setNewFieldError(updatedError)
		} else if (!isEmpty(newFieldValue)) {
			onError(false)
			setNewFieldValue({})
			setNewFieldError({})
			set(updatedData, `[${updatedData.length}]`, {
				...newFieldValue
			})
			onSeqChange(updatedData)
			setShowNewField(false)
		}
	}

	useEffect(() => {
		setError(items.map(() => ''))
	}, [])

	if (isEmpty(items)) return null

	return (
		<div className='selfConfigComponent'>
			<div
				style={{
					marginTop: '16px',
					maxHeight: '400px',
					maxWidth: '500px',
					overflowY: 'auto'
				}}
			>
				{items.map((item: any, index: number) => (
					<Grid
						container
						style={{ margin: '0em 0em', padding: '0em', width: 'auto' }}
						spacing={2}
						key={`${item?.key}`}
					>
						<Grid style={{ padding: '8px 8px 0 0' }} item xs={6}>
							<TextField
								required
								type='number'
								label={`Recipe Key`}
								defaultValue={item.key}
								onBlur={event => {
									event.preventDefault()
									handleChange(event.target.value, 'key', index)
								}}
								error={!!error[index]?.key}
								helperText={error[index]?.key ? error[index]?.key : ''}
							/>
						</Grid>
						<Grid style={{ padding: '8px 8px 0 0' }} item xs={6}>
							<TextField
								required
								label={`Recipe Value`}
								defaultValue={item.value}
								onBlur={event =>
									handleChange(event.target.value, 'value', index)
								}
								error={!!error[index]?.value}
								helperText={error[index]?.value ? error[index]?.value : ''}
							/>
						</Grid>
					</Grid>
				))}
			</div>
			{showNewField ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						paddingTop: '8px'
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%'
						}}
					>
						<TextField
							sx={{ width: '95%' }}
							label='Key'
							type='number'
							onBlur={event => handleNewRecordBlur(event.target.value, 'key')}
							error={!!newFieldError.key}
							helperText={newFieldError.key ? 'Invalid key' : ''}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%'
						}}
					>
						<TextField
							sx={{ width: '95%' }}
							label='Value'
							onBlur={event => handleNewRecordBlur(event.target.value, 'value')}
							error={!!newFieldError.value}
							helperText={newFieldError.value ? 'Invalid value' : ''}
						/>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<CheckIcon onClick={() => handleNewFieldCheck()} />
						<CloseIcon
							onClick={() => {
								setNewFieldValue({})
								setShowNewField(false)
								setNewFieldError({})
							}}
						/>
					</div>
				</div>
			) : (
				<Button
					onClick={() => {
						setShowNewField(true)
						setNewFieldError({})
					}}
				>
					Add New
				</Button>
			)}
		</div>
	)
}

export default WQMSRecipeMapping
