import { BANNER_OPEN, BANNER_CLOSE } from '../Types/banner.constants'

const INITIAL_STATE = {
	type: '',
	message: '',
	open: false
}

export const bannerReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case BANNER_OPEN: {
			const { open = false, message = '', type = '' } = action.payload
			return {
				...state,
				open,
				message,
				type
			}
		}
		case BANNER_CLOSE:
			return {
				...state,
				type: '',
				message: '',
				open: false
			}
		default:
			return state
	}
}
