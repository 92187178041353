import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from '@mui/material'
import TextField from '@mui/material/TextField'
import { clone, isArray, isEmpty } from 'lodash'
import { useState } from 'react'
import { NUMBER_VALIDATION_REGEX } from '../../Utils/SelfServiceHelper'

const RHGradientValues = (props: any) => {
	const { items, onSeqChange, onError } = props
	const [error, setError] = useState<string>('')
	const [editFieldIndex, setEditFieldIndex] = useState<number>(-1)
	const [newFieldValue, setNewFieldValue] = useState<string>('')
	const [hideValidateButton, setHideValidateButton] = useState<boolean>(false)
	const [titleError, setTitleError] = useState<string>('')
	const [selectedOption, setSelectedOption] = useState('')
	// setEditFieldIndex(editIndex);
	// setEditFieldValue(event.target.value);
	const [newGradientValues, setNewGradientValues] = useState<object>({
		yellow: 0,
		orange: 0,
		green: 0,
		red: 0
	})
	const [gradientValues, setGradientValues] = useState<object>({})
	const [errorMessage, setErrorMessage] = useState<string>('')
	const [showAddScreen, setShowAddScreen] = useState<boolean>(false)
	const handleCheck = (value: string, key: string, selectedOption: string) => {
		const updatedData = clone(items)
		const index = updatedData.findIndex(
			(item: any) => item.key === selectedOption
		)
		if (isEmpty(value.toString()) || !NUMBER_VALIDATION_REGEX.test(value)) {
			onError(true)
			setError('Invalid hour value')
		} else if (updatedData[index]['value'][key] !== value) {
			updatedData[index]['value'][key] = Number(value)
			onSeqChange(updatedData)
			handleClose()
		}
	}

	const validateAndSave = () => {
		const updatedData = clone(items)
		const index = updatedData.findIndex(
			(item: any) => item.key === newFieldValue
		)

		if (isEmpty(newFieldValue)) {
			onError(true)
			setTitleError('Enter valid title')
		} else if (!Object.values(newGradientValues).every(value => value > 0)) {
			setErrorMessage('All values should be greater than 0')
		} else {
			if (index !== -1) {
				// If object with same key exists, update it
				updatedData[index] = {
					key: updatedData[index]['key'],
					value: newGradientValues
				}
				setHideValidateButton(true)
				onSeqChange(updatedData)
			} else {
				// If object with same key doesn't exist, add it to the array
				updatedData.push({ key: newFieldValue, value: newGradientValues })
				setHideValidateButton(true)
				onSeqChange(updatedData)
			}
		}
	}

	const handleNewCheck = (value: string) => {
		if (isEmpty(value.toString()) || !NUMBER_VALIDATION_REGEX.test(value)) {
			onError(true)
			setError('Invalid hour value')
		} else {
			handleClose()
		}
		// else if (updatedData[editIndex] !== value) {
		//   const index = updatedData.findIndex((item: any) => item.key === key);

		//   if (index !== -1) {
		//     // If object with same key exists, update it
		//     updatedData[index] = { ...updatedData[index], ...newGradientValues };
		//   } else {
		//     // If object with same key doesn't exist, add it to the array
		//     updatedData.push(newGradientValues);
		//   }
		//   // onSeqChange(updatedData);
		// }
	}

	const handleClose = () => {
		setEditFieldIndex(-1)
		setError('')
		onError(false)
	}

	const handleOptionChange = (event: any) => {
		setSelectedOption(event.target.value)
		setGradientValues(
			items?.filter((item: any) => item.key === event.target.value)?.[0]?.value
		)
	}

	const handleChange = (event: any, key: string, editIndex: number) => {
		setEditFieldIndex(editIndex)
		setGradientValues({ ...gradientValues, [key]: event.target.value })
	}

	const handleNewChange = (event: any, key: string) => {
		if (
			NUMBER_VALIDATION_REGEX.test(event.target.value) ||
			event.target.value === ''
		) {
			setErrorMessage('')
			setNewGradientValues({
				...newGradientValues,
				[key]: Number(event.target.value)
			})
		}
	}

	if (isEmpty(items) || !isArray(items)) return null

	return (
		<>
			{!showAddScreen ? (
				<div style={{ marginTop: 10 }}>
					<div className='simple-inner'>
						<div>
							<FormControl fullWidth>
								<InputLabel id='demo-simple-select-label'>
									Select Hour
								</InputLabel>
								<Select
									labelId='demo-simple-select-label'
									id='demo-simple-select'
									label='Select Category'
									value={selectedOption}
									onChange={handleOptionChange}
								>
									{props?.items.map((option: any) => (
										<MenuItem value={option.key}>{option.key}</MenuItem>
									))}
								</Select>
							</FormControl>

							<ol
								style={{
									listStyleType: 'none',
									padding: '0',
									marginTop: '8px',
									maxHeight: '450px',
									overflow: 'auto'
								}}
							>
								{Object.entries(gradientValues)?.map(([key, value], index) => (
									<li
										key={key}
										style={{
											padding: '5px 5px',
											borderRadius: '2px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between'
										}}
									>
										{editFieldIndex === index ? (
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													width: '100%'
												}}
											>
												{key}{' '}
												<TextField
													defaultValue={value}
													label='hour'
													onChange={event => handleChange(event, key, index)}
													error={!!error}
													helperText={error || ''}
												/>
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center'
													}}
												>
													<CheckIcon
														onClick={() =>
															handleCheck(value, key, selectedOption)
														}
													/>
													<CloseIcon onClick={() => handleClose()} />
												</div>
											</div>
										) : (
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													width: '100%'
												}}
											>
												<div>{key}</div>
												<div>{value}</div>
												<EditIcon onClick={() => setEditFieldIndex(index)} />
											</div>
										)}
									</li>
								))}
							</ol>
						</div>
						<Button onClick={() => setShowAddScreen(true)}>Add New</Button>
					</div>
				</div>
			) : (
				<div className='selfConfigComponent' style={{ marginTop: 10 }}>
					<div className='simple-inner'>
						<TextField
							fullWidth
							label='Title'
							value={newFieldValue}
							onChange={event => {
								setNewFieldValue(event.target.value)
								setTitleError('')
							}}
							error={!!titleError}
							helperText={titleError || ''}
						/>
						<ol
							style={{
								listStyleType: 'none',
								padding: '0',
								marginTop: '8px',
								maxHeight: '450px',
								overflow: 'auto'
							}}
						>
							{Object.entries(newGradientValues)?.map(([key, value], index) => (
								<li
									key={key}
									style={{
										padding: '5px 5px',
										borderRadius: '2px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									{editFieldIndex === index ? (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%'
											}}
										>
											{key}{' '}
											<TextField
												defaultValue={value}
												value={value}
												label='hour'
												onChange={event => handleNewChange(event, key)}
												error={!!error}
												helperText={error || ''}
											/>
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center'
												}}
											>
												<CheckIcon onClick={() => handleNewCheck(value)} />
												<CloseIcon onClick={() => handleClose()} />
											</div>
										</div>
									) : (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%'
											}}
										>
											<div>{key}</div>
											<div>{value}</div>
											<EditIcon onClick={() => setEditFieldIndex(index)} />
										</div>
									)}
								</li>
							))}
						</ol>
						{!hideValidateButton && (
							<>
								{errorMessage && (
									<p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
								)}

								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between'
									}}
								>
									<Button
										variant='outlined'
										onClick={() => {
											setNewFieldValue('')
											setNewGradientValues({
												yellow: 0,
												orange: 0,
												green: 0,
												red: 0
											})
											setShowAddScreen(false)
										}}
										sx={{
											marginTop: '16px'
										}}
									>
										Cancel
									</Button>
									<Button
										variant='contained'
										onClick={() => validateAndSave()}
										sx={{
											marginTop: '16px'
										}}
									>
										Validate
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</>
	)
}

export default RHGradientValues
