import {
	LOAD_SAT_DETAILS,
	LOAD_SAT_DETAILS_SUCCESS,
	LOAD_SAT_DETAILS_ERROR
} from '../Types/satDetails.constants'

const INITIAL_STATE = {
	loading: false,
	satDetails: [],
	error: null
}

export const satDetailsListReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case LOAD_SAT_DETAILS:
			return { ...state, loading: true, satDetails: [] }
		case LOAD_SAT_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				satDetails: action.payload
			}
		case LOAD_SAT_DETAILS_ERROR:
			return { ...state, loading: false, error: action.payload }
		default:
			return state
	}
}
