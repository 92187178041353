import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField
} from '@mui/material'
import { clone, find, get, isArray, isEmpty, set } from 'lodash'
import { useState } from 'react'

const RHGanttChart = (props: any) => {
	const { items, onSeqChange, onError } = props
	const [error, setError] = useState<any>({})
	const [selectedOption, selectOption] = useState('')
	const [editFieldIndex, setEditFieldIndex] = useState<number>(-1)
	const [editFieldValue, setEditFieldValue] = useState<string>('')
	const [showNewField, setShowNewField] = useState<boolean>(false)
	const [newFieldValue, setNewFieldValue] = useState<any>({})

	const getNewElementIndex = (
		axisCategories: number[],
		newCategory: string
	) => {
		let newIndex = 0,
			position = false,
			index = 0
		while (position === false && index < axisCategories.length) {
			if (axisCategories[index] > parseInt(newCategory)) {
				position = true
				newIndex = index
			}
			index++
		}

		if (!position) return axisCategories.length

		return newIndex
	}

	const checkIfValueAlreadyExists = (axisCategory: string, data: any) => {
		let isValuePresent = false
		data.forEach((element: any) => {
			if (element?.key === selectedOption) {
				const valueData = JSON.parse(element.value)
				isValuePresent = valueData.axisCategories.includes(axisCategory)
			}
		})
		return isValuePresent
	}

	const handleCheck = (value: string | any, editIndex?: number) => {
		const updatedData = clone(items)

		if (editIndex) {
			updatedData.forEach((element: any) => {
				if (element?.key === selectedOption) {
					const valueData = JSON.parse(element.value)
					set(valueData, `axisLabel[${editIndex}]`, value)
					element.value = JSON.stringify(valueData)
				}
			})

			onSeqChange(updatedData)
			handleClose()
		} else {
			let errorValue = clone(error)
			if (
				isEmpty(value.axisCategories) ||
				checkIfValueAlreadyExists(value.axisCategories, updatedData)
			) {
				errorValue = {
					...errorValue,
					axisCategories: 'Invalid value'
				}
			} else delete errorValue.axisCategories
			if (isEmpty(value.axisLabel)) {
				errorValue = {
					...errorValue,
					axisLabel: 'Invalid value'
				}
			} else delete errorValue.axisLabel
			if (!isEmpty(errorValue)) {
				setError(errorValue)
				onError(true)
			} else {
				updatedData.forEach((element: any) => {
					if (element?.key === selectedOption) {
						let valueData = JSON.parse(element.value)
						const { axisCategories, axisLabel } = valueData
						const newElementIndex = getNewElementIndex(
							axisCategories,
							value.axisCategories
						)
						const firstHalfAxisCategories = axisCategories.splice(
							0,
							newElementIndex
						)
						const firstHalfAxisLabel = axisLabel.splice(0, newElementIndex)
						valueData = {
							axisCategories: [
								...firstHalfAxisCategories,
								value.axisCategories,
								...axisCategories
							],
							axisLabel: [...firstHalfAxisLabel, value.axisLabel, ...axisLabel]
						}
						element.value = JSON.stringify(valueData)
					}
				})

				onSeqChange(updatedData)
				handleClose()
			}
		}
	}

	const handleClose = () => {
		setEditFieldIndex(-1)
		setEditFieldValue('')
		setError({})
		onError(false)
		setShowNewField(false)
	}

	if (isEmpty(items) || !isArray(items)) return null

	const keyDropdownOptions = items.map((option: any) => option.key)
	const renderValues = find(items, function (item) {
		if (item?.key === selectedOption) {
			return true
		}
	})
	const renderValuesParsed = !isEmpty(renderValues)
		? JSON.parse(renderValues.value)
		: {}
	const rangeDropdownOptions = [
		{ text: 'Weekly (W)', value: 'W' },
		{ text: 'Monthly (M)', value: 'M' },
		{ text: 'Quarterly (Q)', value: 'Q' },
		{ text: 'Semi-Annually (S)', value: 'S' },
		{ text: 'Annually (A)', value: 'A' }
	]

	return (
		<div className='selfConfigComponent'>
			<div className='simple-inner'>
				<FormControl
					variant='outlined'
					fullWidth
					size='medium'
					sx={{ marginTop: '16px', marginBottom: '16px' }}
				>
					<InputLabel id='demo-multiple-name-label'>Select Status</InputLabel>
					<Select
						// multiple
						value={selectedOption}
						onChange={(event: any) => {
							selectOption(event.target.value)
							setEditFieldIndex(-1)
							setEditFieldValue('')
							setShowNewField(false)
						}}
						input={<OutlinedInput label='Select Status' />}
					>
						{keyDropdownOptions.map((option: string) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<ol
					style={{
						listStyleType: 'none',
						padding: '0',
						marginTop: '8px',
						maxHeight: '450px',
						overflow: 'auto'
					}}
				>
					{renderValuesParsed?.axisCategories?.map(
						(item: any, index: number) => (
							<li
								key={item}
								style={{
									padding: '5px 5px',
									borderRadius: '2px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								{editFieldIndex === index ? (
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											width: '100%'
										}}
									>
										<FormControl
											variant='outlined'
											fullWidth
											size='medium'
											sx={{ marginTop: '16px', marginBottom: '16px' }}
										>
											<InputLabel id='demo-multiple-name-label'>
												Select Range
											</InputLabel>
											<Select
												defaultValue={get(
													renderValuesParsed,
													`axisLabel[${index}]`,
													''
												)}
												onChange={(event: any) =>
													setEditFieldValue(event.target.value)
												}
												input={<OutlinedInput label='Select Status' />}
											>
												{rangeDropdownOptions.map((option: any) => (
													<MenuItem key={option?.id} value={option.value}>
														{option.text}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}
										>
											<CheckIcon
												onClick={() => handleCheck(editFieldValue, index)}
											/>
											<CloseIcon onClick={() => handleClose()} />
										</div>
									</div>
								) : (
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											width: '100%'
										}}
									>
										<div>{item}</div>
										<div>
											{get(renderValuesParsed, `axisLabel[${index}]`, '') ||
												'0'}
										</div>
										<div>
											{index !== 0 ? (
												<EditIcon onClick={() => setEditFieldIndex(index)} />
											) : null}
										</div>
									</div>
								)}
							</li>
						)
					)}
				</ol>
				{showNewField ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
							paddingTop: '8px'
						}}
					>
						<TextField
							label='X-axis value'
							type='number'
							onChange={event =>
								setNewFieldValue({
									...newFieldValue,
									axisCategories: event.target.value
								})
							}
							sx={{ width: '250px' }}
							error={!!error?.axisCategories}
							helperText={error?.axisCategories || ''}
						/>
						<FormControl
							variant='outlined'
							fullWidth
							size='medium'
							error={!!error?.axisLabel}
							sx={{ marginLeft: '16px', marginRight: '16px' }}
						>
							<InputLabel id='demo-multiple-name-label'>
								Select Range
							</InputLabel>
							<Select
								onChange={event =>
									setNewFieldValue({
										...newFieldValue,
										axisLabel: event.target.value
									})
								}
								input={<OutlinedInput label='Select Status' />}
							>
								{rangeDropdownOptions.map((option: any) => (
									<MenuItem key={option?.id} value={option.value}>
										{option.text}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>{error?.axisLabel}</FormHelperText>
						</FormControl>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<CheckIcon onClick={() => handleCheck(newFieldValue)} />
							<CloseIcon onClick={() => handleClose()} />
						</div>
					</div>
				) : selectedOption ? (
					<Button onClick={() => setShowNewField(true)}>Add New</Button>
				) : null}
			</div>
		</div>
	)
}

export default RHGanttChart
