export const LOAD_HEAT_MAP_DETAILS = 'LOAD_HEAT_MAP_DETAILS'
export const LOAD_HISTORICAL_DATA = 'LOAD_HISTORICAL_DATA'
export const LOAD_LGV_LIVE_DATA = 'LOAD_LGV_LIVE_DATA'
export const LOAD_LGV_LIVE_DATA_SUCCESS = 'LOAD_LGV_LIVE_DATA_SUCCESS'
export const LOAD_LGV_LIVE_DATA_ERROR = 'LOAD_LGV_LIVE_DATA_ERROR'
export const UPDATE_LGV_LIVE_DATA = 'UPDATE_LGV_LIVE_DATA'
export const LOAD_PLS_LIVE_DATA = 'LOAD_PLS_LIVE_DATA'
export const LOAD_PLS_LIVE_DATA_SUCCESS = 'LOAD_PLS_LIVE_DATA_SUCCESS'
export const LOAD_PLS_LIVE_DATA_ERROR = 'LOAD_PLS_LIVE_DATA_ERROR'

