import axios from '../../Utils/AxiosConfig/axiosConfigSAT'
import { put, call, takeEvery } from 'redux-saga/effects'
import {
	LOAD_SATHIERARCHYDATA,
	LOAD_SATHIERARCHYDATA_SUCCESS,
	LOAD_SATHIERARCHYDATA_ERROR
} from '../Types/satHierarchy.constants'

const getHierarchySATPlants = async (plantCode: any) => {
	try {
		const res = await axios.get(`GetHierarchyForPlant?shortname=${plantCode}`)
		return res.data
	} catch (error: any) {
		throw new Error(error)
	}
}

export function* listHierarchySATPlants(action: any): any {
	try {
		const data = yield call(() =>
			getHierarchySATPlants(action.payload.plantCode).then(res => {
				return res
			})
		)

		yield put({
			type: LOAD_SATHIERARCHYDATA_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: LOAD_SATHIERARCHYDATA_ERROR,
			payload: null
		})
	}
}

export function* listHierarchySATPlantsSaga() {
	yield takeEvery(LOAD_SATHIERARCHYDATA, listHierarchySATPlants)
}
