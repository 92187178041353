import React from 'react'
import { useSelector } from 'react-redux'

function AccountPermission(props: any) {
	const usersList = useSelector((state: any) => state.usersList)
	const accountPermissionData: any =
		usersList.currentAccountPermissions?.length > 0
			? usersList.currentAccountPermissions[0]
			: {}
	const [isVisible, setIsVisible] = React.useState(true)

	const validate = () => {
		if (props.action === 'read') {
			if (
				!accountPermissionData?.role_permissions_allocated[props.module][
					props.pageName
				]['Permission'].read
			) {
				setIsVisible(false)
			}
		} else if (props.action === 'create') {
			if (
				!accountPermissionData?.role_permissions_allocated[props.module][
					props.pageName
				]['Permission'].create
			) {
				setIsVisible(false)
			}
		} else if (props.action === 'modify') {
			if (
				!accountPermissionData?.role_permissions_allocated[props.module][
					props.pageName
				]['Permission'].modify
			) {
				setIsVisible(false)
			}
		} else if (props.action === 'delete') {
			if (
				!accountPermissionData?.role_permissions_allocated[props.module][
					props.pageName
				]['Permission'].delete
			) {
				setIsVisible(false)
			}
		}
	}
	React.useEffect(() => {
		if (Object.keys(accountPermissionData).length > 0) {
			validate()
		}
	}, [])
	return <>{isVisible ? props.children : <></>}</>
}
export default AccountPermission
