import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { DialogTitle, Theme, Typography } from "@material-ui/core";
import { Img } from "react-image";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    ".&MuiPaper-root": {
      overflow: "hidden",
    },
  },
  button: {
    backgroundColor: theme.palette.mode === "dark" ? "none !important" : "",
  },
}));
function ImageViewer(props: any) {
  const { showModal, attachmentURL, setShowModal, setAttachmentURL } = props;
  const [bgImgZoomStyle, setBgImgZoomStyle] = useState({
    background: "",
    backgroundSize: "contain",
    height: "70vh",
    cursor: "zoom-out",
  });

  const [imgZoomStyle, setImgZoomStyle] = useState({
    width: "auto",
    height: "auto",
    display: "none",
  });
  const classes = useStyles();

  const handleClose = () => {
    setShowModal(false);
    setAttachmentURL("");
  };

  const handleImgZoom = () => {
    if (imgZoomStyle.display === "none") {
      setBgImgZoomStyle({
        background: "",
        backgroundSize: "auto",
        height: "70vh",
        cursor: "zoom-out",
      });
      setImgZoomStyle({
        width: "auto",
        height: "auto",
        display: "initial",
      });
    } else {
      setBgImgZoomStyle({
        background: "url('" + attachmentURL + "') no-repeat center center",
        backgroundSize: "contain",
        height: "70vh",
        cursor: "zoom-in",
      });
      setImgZoomStyle({
        width: "auto",
        height: "auto",
        display: "none",
      });
    }
  };

  React.useEffect(() => {
    setBgImgZoomStyle({
      background: "url('" + attachmentURL + "') no-repeat center center",
      backgroundSize: "contain",
      height: "70vh",
      cursor: "zoom-in",
    });
    setImgZoomStyle({
      width: "auto",
      height: "auto",
      display: "none",
    });
  }, [attachmentURL]);

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Image Viewer</Typography>
        <CloseIcon
          style={{
            color: "white",
            fontSize: "25px",
          }}
          onClick={() => {
            handleClose();
          }}
        />
      </DialogTitle>
      <DialogContent style={bgImgZoomStyle} onClick={handleImgZoom}>
        <Img src={attachmentURL} style={imgZoomStyle} />
      </DialogContent>
    </Dialog>
  );
}

export default ImageViewer;
