import { Box, Button, IconButton, Typography, Card } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Avatar } from '@material-ui/core'
import draftToHtml from 'draftjs-to-html'
import PopOverItems, { popOverItem } from './PopOverItems'
import { ticketDetailsStyles } from '../TicketDetailsStyles'
import moment from 'moment-timezone'

const CommentList = (props: any) => {
	const {
		item,
		index,
		anchorElCommentDotPopUp,
		_handleClickCommentDotPupUp,
		openAnchorElCommentDotPopUp,
		setCommentEdit,
		setCommentDelete,
		_getUserInfo,
		handleCloseCommentDotPopUp,
		_handleViewImageInCommentSection,
		userPermission
	} = props
	const classes: any = ticketDetailsStyles()
	let commentObject: any = {}
	const attachmentArray: any = []
	let commentTextCopy = ''
	if (item?.text?.entityMap) {
		if (Object.keys(item?.text?.entityMap).length > 0) {
			let commentText = ''
			item?.text?.blocks?.map((block: any) => {
				if (block?.text?.trim() !== '') {
					commentText = block?.text
					commentTextCopy =
						commentTextCopy.trim().length === 0 ? block?.text : commentTextCopy
					block?.entityRanges?.map((attachment: any) => {
						attachmentArray.push({
							attachmentName: commentText.slice(
								attachment?.offset,
								attachment?.offset + attachment?.length
							),
							attachmentLink: item?.text?.entityMap[attachment?.key]?.data?.url
						})
						commentTextCopy = commentTextCopy.replace(
							commentText.slice(
								attachment?.offset,
								attachment?.offset + attachment?.length
							),
							''
						)
					})
					if (commentObject?.entityMap) {
						if (Object.keys(commentObject?.entityMap)?.length > 0) {
							commentObject = {
								blocks: [
									...(commentObject?.blocks || []),
									{
										...block,
										text: commentTextCopy,
										entityRanges: []
									}
								],
								entityMap: commentObject?.entityMap
							}
						}
					} else {
						if (commentObject?.blocks?.length > 0) {
							commentObject = {
								blocks: [
									...(commentObject?.blocks || []),
									{
										...block,
										text: commentTextCopy,
										entityRanges: []
									}
								]
							}
						} else {
							commentObject = {
								blocks: [
									{
										...block,
										text: commentTextCopy,
										entityRanges: []
									}
								]
							}
						}
					}
				} else {
					if (block?.entityRanges?.length > 0) {
						if (commentObject?.blocks?.length > 0) {
							commentObject = {
								blocks: [...(commentObject?.blocks || []), { ...block }],
								entityMap: {
									...commentObject?.entityMap,
									[block?.entityRanges[0]?.key]:
										item?.text?.entityMap[block?.entityRanges[0]?.key]
								}
							}
						} else {
							commentObject = {
								blocks: [{ ...block }],
								entityMap: {
									[block?.entityRanges[0]?.key]:
										item?.text?.entityMap[block?.entityRanges[0]?.key]
								}
							}
						}
					} else {
						if (commentObject?.blocks?.length > 0) {
							commentObject = {
								...commentObject,
								blocks: [...(commentObject?.blocks || []), { ...block }]
							}
						} else {
							commentObject = {
								...commentObject,
								blocks: [{ ...block }]
							}
						}
					}
				}
			})
		}
	} else {
		commentObject = { blocks: [...(item?.text?.blocks || [])] }
	}
	return (
		<Card className={classes.commentsBox}>
			<Box className={classes.commentsEdit}>
				<IconButton
					aria-controls={anchorElCommentDotPopUp ? 'long-commnet' : undefined}
					aria-expanded={anchorElCommentDotPopUp ? 'true' : undefined}
					aria-haspopup='true'
					onClick={(e: any) => _handleClickCommentDotPupUp(e, item, index)}
					aria-label='Open to show more'
					title='Open to show more'
					disableRipple
					disabled={
						userPermission?.currentUserPermissions?.includes('ARCH_ADMIN') ||
						userPermission?.currentUserPermissions?.includes(
							'ARCH_SCM_ADMIN'
						) ||
						_getUserInfo(item?.from)?.display_name ===
							userPermission?.currentUser?.display_name
							? false
							: true
					}
				>
					<MoreVertIcon />
				</IconButton>
			</Box>

			{/* Edit and delete comment popover items */}
			<PopOverItems
				open={openAnchorElCommentDotPopUp}
				anchorEl={anchorElCommentDotPopUp}
				popOverItmes={[
					{
						label: 'Edit Comment',
						key: 'edit_comment'
					},
					{
						label: 'Delete Comment',
						key: 'delete_comment'
					}
				]}
				popOverItemAction={(item: popOverItem) => {
					if (item.key === 'edit_comment') {
						setCommentEdit(true)
					} else if (item.key === 'delete_comment') {
						setCommentDelete(true)
					}
				}}
				closePopUp={handleCloseCommentDotPopUp}
			/>

			<Box className={classes.userDetail}>
				{_getUserInfo(item?.from)?.profile_image !== '' ? (
					<Box component='img' src={_getUserInfo(item?.from)?.profile_image} />
				) : (
					<Avatar variant='circular' src={''} />
				)}

				<Typography variant='body1'>
					{_getUserInfo(item?.from)?.display_name}
					<Typography variant='body1' component='span'>
						{_getUserInfo(item?.from)?.role}
					</Typography>
				</Typography>
			</Box>

			<Box className={classes.commentsTitle}>
				<div
					className='comment'
					dangerouslySetInnerHTML={{
						__html: draftToHtml(commentObject)
					}}
					style={{
						cursor: item?.text?.entityMap?.[0]?.data?.src ? 'pointer' : ''
					}}
					onClick={() => _handleViewImageInCommentSection(item?.text)}
				/>
			</Box>
			<Box className={classes.commentsFilesAttachment}>
				{attachmentArray.map((attachment: any) => (
					<form
						method='get'
						action={attachment?.attachmentLink}
						target='_blank'
					>
						<Button
							type='submit'
							// onClick={handleClickOpenAttechment}
							variant='outlined'
							// endIcon={<DocumentIcon />}
						>
							{attachment?.attachmentName}
						</Button>
					</form>
				))}
			</Box>
			<Box className={classes.commentsdetail}>
				{`${moment(item.created_on)
					.tz('America/Los_Angeles')
					.format('MM/DD/YYYY, hh:mm A')} PST`}
			</Box>
		</Card>
	)
}

export default CommentList
