import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import { clone, isArray, isEmpty, isString } from 'lodash'
import { useState } from 'react'
import { TAG_VALIDATION_REGEX } from '../../Utils/SelfServiceHelper'

const WQMSTagsEndingString = (props: any) => {
	const { items, onSeqChange, onError, isUseCaseRunHours } = props
	const [searchedTerm, setSearchedTerm] = useState<string>('')
	const [error, setError] = useState<string>('')
	const [editFieldIndex, setEditFieldIndex] = useState<number>(-1)
	const [editFieldValue, setEditFieldValue] = useState<string>('')
	const [showNewField, setShowNewField] = useState<boolean>(false)
	const [newFieldValue, setNewFieldValue] = useState<string>('')

	const handleCheck = (value: string, editIndex: number) => {
		const updatedData = clone(items)
		if (isEmpty(value) || !TAG_VALIDATION_REGEX.test(value)) {
			onError(true)
			setError('Invalid tag value')
		} else if (updatedData[editIndex] !== value) {
			updatedData[editIndex] = value
			onSeqChange(updatedData)
			handleClose()
		}
	}

	const handleClose = () => {
		setEditFieldIndex(-1)
		setEditFieldValue('')
		setError('')
		onError(false)
		setShowNewField(false)
	}

	const handleChange = (event: any, editIndex = -1) => {
		let isError = !!error
		const tagValue = event.target.value
		isError = isEmpty(tagValue) || !TAG_VALIDATION_REGEX.test(tagValue)

		if (isError) {
			onError(true)
			setError('Invalid tag value')
		} else {
			setError('')
		}
		if (editIndex >= 0) {
			setEditFieldIndex(editIndex)
			setEditFieldValue(tagValue)
		} else {
			setNewFieldValue(tagValue)
		}
	}

	const onEditIconClick = (index: number, item: any = '') => {
		setEditFieldIndex(index)
		setEditFieldValue(item)
		setError('')
		setNewFieldValue('')
		setShowNewField(false)
	}

	const onDeleteIconClick = (index: number) => {
		items.splice(index, 1)
		onSeqChange(items)
		setError('')
		setNewFieldValue('')
		setShowNewField(false)
	}

	if (isEmpty(items) || !isArray(items)) return null

	if (!isString(items[0])) return null

	const filteredItems = items.filter((item: string) =>
		searchedTerm
			? item.toLocaleLowerCase().includes(searchedTerm.toLocaleLowerCase())
			: true
	)

	return (
		<div className='selfConfigComponent'>
			<div className='simple-inner'>
				{isUseCaseRunHours ? (
					''
				) : (
					<TextField
						label='Search tag'
						type='text'
						fullWidth
						onChange={event => {
							setSearchedTerm(event.target.value)
						}}
						sx={{ marginTop: '16px' }}
					/>
				)}
				<ol
					style={{
						listStyleType: 'none',
						padding: '0',
						marginTop: '8px',
						maxHeight: '450px',
						overflow: 'auto'
					}}
				>
					{filteredItems.map((item: string, index: number) => (
						<li
							key={item}
							style={{
								padding: '5px 5px',
								borderRadius: '2px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							{editFieldIndex === index ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%'
									}}
								>
									<TextField
										defaultValue={item}
										label='Ending String Value'
										onChange={event => handleChange(event, index)}
										error={!!error}
										helperText={error || ''}
										style={{ width: '93%' }}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center'
										}}
									>
										<CheckIcon
											onClick={() => handleCheck(editFieldValue, index)}
										/>
										<CloseIcon onClick={() => handleClose()} />
									</div>
								</div>
							) : (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%'
									}}
								>
									{item}
									{isUseCaseRunHours ? (
										<div>
											<EditIcon onClick={() => onEditIconClick(index, item)} />

											<CloseIcon onClick={() => onDeleteIconClick(index)} />
										</div>
									) : (
										<div>
											<EditIcon onClick={() => onEditIconClick(index)} />
										</div>
									)}
								</div>
							)}
						</li>
					))}
				</ol>
				{showNewField ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
							paddingTop: '8px'
						}}
					>
						<TextField
							label='Ending String Value'
							onChange={event => handleChange(event)}
							error={!!error}
							helperText={error || ''}
						/>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<CheckIcon
								onClick={() => handleCheck(newFieldValue, items.length)}
							/>
							<CloseIcon onClick={() => handleClose()} />
						</div>
					</div>
				) : (
					<Button
						onClick={() => {
							setEditFieldIndex(-1)
							setShowNewField(true)
							setError('')
						}}
					>
						Add New
					</Button>
				)}
			</div>
		</div>
	)
}

export default WQMSTagsEndingString
