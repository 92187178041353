import { Avatar, Box, Button, Typography } from '@material-ui/core'
import { experimentalStyled, styled } from '@material-ui/core/styles'
import { EditorState, Modifier } from 'draft-js'
import draftUtils from 'draftjs-utils'
import { FC } from 'react'
import { darkLightTextChange } from '../../../../theme/muiGlobalCss'

const appMode = localStorage.getItem('appMode')

const UserAvatar = styled(Avatar)(
	({ theme }) => `
          background-color: ${theme.colors.primary.main};
  `
)

const UserBoxButton = experimentalStyled(Button)(
	({ theme }) => `
          padding-left: ${theme.spacing(1)};
          padding-right: ${theme.spacing(1)};
          background-color: ${appMode === 'Dark' ? '#293368' : 'transparent'};
          '&:hover':{
           background-color: ${appMode === 'Dark' ? '#8476EF' : ''};
          };
          width:450px;
          justify-content:left;
          
  `
)

const UserBoxText = experimentalStyled(Box)(
	({ theme }) => `
          text-align: left;
          padding-left: ${theme.spacing(1)};
  `
)

const UserBoxLabel = experimentalStyled(Typography)(
	({ theme }) => `
          font-weight: ${theme.typography.fontWeightBold};
          color: ${darkLightTextChange(theme)};
          text-transform: capitalize;
          display: block;
  `
)

const UserBoxDescription = experimentalStyled(Typography)(
	({ theme }) => `
          color: ${darkLightTextChange(theme)};
          text-transform: lowercase;
  `
)

type Person = {
	avatar: string
	user_ad_name: string
	mail: string
	userImage: any
}

type UserBoxProps = {
	person: Person
	tempEditor: any
	onEditorChange: any
	setTempEditor: any
}

const selectUser = ({
	person,
	tempEditor,
	onEditorChange,
	setTempEditor
}: UserBoxProps) => {
	const value = person.user_ad_name
	const url = 'mailto:' + person.mail
	const entityKey = tempEditor
		.getCurrentContent()
		.createEntity('MENTION', 'IMMUTABLE', {
			text: `@${person.user_ad_name}`,
			value,
			url
		})
		.getLastCreatedEntityKey()
	const selectedBlock = draftUtils.getSelectedBlock(tempEditor)
	const selectedBlockText = selectedBlock.getText()
	let focusOffset = tempEditor.getSelection().focusOffset
	const mentionIdx = (selectedBlockText.lastIndexOf(' @', focusOffset) || 0) + 1
	let spaceAlreadyPresent = false
	if (selectedBlockText.length === mentionIdx + 1) {
		focusOffset = selectedBlockText.length
	}
	if (selectedBlockText[focusOffset] === ' ') {
		spaceAlreadyPresent = true
	}
	let updatedSelection = tempEditor.getSelection().merge({
		anchorOffset: mentionIdx,
		focusOffset
	})
	let newEditorState = EditorState.acceptSelection(tempEditor, updatedSelection)
	let contentState = Modifier.replaceText(
		newEditorState.getCurrentContent(),
		updatedSelection,
		`@${value}`,
		newEditorState.getCurrentInlineStyle(),
		entityKey
	)
	newEditorState = EditorState.push(
		newEditorState,
		contentState,
		'insert-characters'
	)
	if (!spaceAlreadyPresent) {
		// insert a blank space after mention
		updatedSelection = newEditorState.getSelection().merge({
			anchorOffset: mentionIdx + value.length + 1,
			focusOffset: mentionIdx + value.length + 1
		})
		newEditorState = EditorState.acceptSelection(
			newEditorState,
			updatedSelection
		)
		contentState = Modifier.insertText(
			newEditorState.getCurrentContent(),
			updatedSelection,
			' ',
			newEditorState.getCurrentInlineStyle(),
			undefined
		)
	}

	newEditorState = EditorState.push(
		newEditorState,
		contentState,
		'insert-characters'
	)

	setTempEditor(EditorState.createWithContent(contentState))
	onEditorChange(newEditorState)
}

const Staff: FC<UserBoxProps> = ({
	person,
	tempEditor,
	onEditorChange,
	setTempEditor
}) => {
	const textColor = appMode === 'Dark' ? '#FFF' : ''

	return (
		<UserBoxButton
			onClick={() =>
				selectUser({
					person,
					tempEditor,
					onEditorChange,
					setTempEditor
				})
			}
			sx={{ background: 'rgba(132, 118, 239, 0.2)' }}
		>
			<UserAvatar
				alt={person.user_ad_name}
				src={person.userImage === null ? person.avatar : person.userImage}
			/>
			<UserBoxText>
				<UserBoxLabel variant='body1' style={{ color: textColor }}>
					{person.user_ad_name}
				</UserBoxLabel>
				<UserBoxDescription style={{ color: textColor }}>
					{person.mail}
				</UserBoxDescription>
			</UserBoxText>
		</UserBoxButton>
	)
}

export default Staff
