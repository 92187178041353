import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { cloneDeep, isEmpty } from 'lodash';

const RHVPPDConfig = (props: any) => {
  const { items, onSeqChange, dropdownOptions } = props;

  const handleChange = (newValue: any, userType: string) => {
    const clonedUpdatedItems = cloneDeep(items);

    clonedUpdatedItems[userType] = newValue.map((user: any) => {
      const returnObj = {
        userName: user.user_ad_name,
        userEmail: user.mail,
        userMobile: user.mobile_phone
      }
      return returnObj;
    })

    onSeqChange(clonedUpdatedItems);
  }

  const getSelectedValue = (selectedUsers: any[]) => {
    const selectedUserEmails = selectedUsers.map((user: any) => user.userEmail);
    const selectedUsersObj = dropdownOptions.filter((user: any) => selectedUserEmails.includes(user.mail))

    return selectedUsersObj;
  }

  if (isEmpty(items)) return null;

  const { VP = [], PD = [] } = items;

  return (
    <div className="selfConfigComponent">
      <Autocomplete
        multiple
        getOptionLabel={(option: any) => option.user_ad_name}
        options={dropdownOptions}
        value={getSelectedValue(VP)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="VP Users"
          />
        )}
        onChange={(event, newValue) => handleChange(newValue, 'VP')}
        sx={{
          marginTop: '16px',
          maxWidth: '550px',
          maxHeight: '100px',
          overflow: 'auto',
          overflowX: 'hidden',
        }}
      />
      <Autocomplete
        multiple
        getOptionLabel={(option: any) => option.user_ad_name}
        options={dropdownOptions}
        value={getSelectedValue(PD)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="PD Users"
          />
        )}
        onChange={(event, newValue) => handleChange(newValue, 'PD')}
        sx={{
          marginTop: '16px',
          maxWidth: '550px',
          maxHeight: '100px',
          overflow: 'auto',
          overflowX: 'hidden',
        }}
      />
    </div>
  );
}


export default RHVPPDConfig;
