import {
  LOAD_ASSETS,
  LOAD_ASSETS_SUCCESS,
  LOAD_ASSETS_ERROR,
} from "../Types/assets.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all assets from the api
const getAssets = async () => {
  let assets: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`leveltypes?pageSize=100&page=${page++}`);
    assets = assets.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return assets;
};

export function* listAssets(): any {
  try {
    const data = yield call(() =>
      getAssets().then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_ASSETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_ASSETS_ERROR,
      payload: null,
    });
  }
}

export function* listAssetsSaga() {
  yield takeEvery(LOAD_ASSETS, listAssets);
}
