const SetMultipleUserImage = async () => {
	try {
		const response = {
			status: 200,
			data: null
		}

		if (response !== null && response.status === 200) {
			const data = response.data
			if (data !== null) {
				window.URL = window.URL || window.webkitURL
				return window.URL.createObjectURL(data)
			}
			return data
		} else {
			return null
		}
	} catch (error: any) {
    throw new Error(error)
  }
}

export { SetMultipleUserImage }

