import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	Divider,
	Grid,
	TextField
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import moment from 'moment'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadSnackbar } from '../../../../../Utils/util'
import AutoCompleteDropdown from '../../../../../componets/Inputs/AutoCompleteDropDown'
import { dashboardStyles } from '../DowntimeLandingPageStyles'
import {
	createTicketStatus,
	getLineMpAndFgPlanners,
	getSelectedPlanRegion,
	getSelectedPlantTypes,
	getUniqueLines
} from '../Helpers'
import { ReactComponent as CreateIcon } from '../Icons/create-ticket-icon.svg'
import { ReactComponent as DownIcon } from '../Icons/down-icon.svg'
import {
	getFiltersList,
	getLevelTypes
} from '../Services/SupplyChainTikcetService'
import AvailablePlants from './AvailablePlants'
export interface createUpdateTicketInterface {
	// Dialog title and open parameters
	open: boolean
	title: string

	// Dialong primary button text and action
	primaryButtonText: string
	primaryAction(createTicketObject: any): void

	// Dialog secondary button text and action.
	secondaryButtonText: string
	secondaryAction(): void

	// Handling same dialog for to update the ticket.
	fromUpdate?: boolean
	updateTicketDetails?: any

	plantId: string
}

const CreateUpdateTicket = (
	props: createUpdateTicketInterface
): ReactElement => {
	const classes = dashboardStyles()
	const dispatch = useDispatch()

	const initTicketFormData = {
		plant: null,
		dt_ticket_type: null,
		dt_ticket_priority: null,
		dt_ticket_status: { name: 'Open' },
		dt_start_date: dayjs(moment().format('MM-DD-YYYY HH:MM')),
		dt_end_date: dayjs(moment().add(1, 'days').format('MM-DD-YYYY HH:MM')),
		dt_hours: null,
		dt_requestor_id: null,
		dt_line_id: null,
		createTicketMasterPlanner: null,
		createTicketFgPlanner: null,
		createTicketRegion: '',
		createAddComment: null,
		dt_min_date: dayjs(moment().format('MM-DD-YYYY HH:MM')),
		createTicketBrandPlanner: null,
		project_name: null,
		project_id: null,
		plant_poc_email: null,
		project_poc_email: null
	}

	const initTicketFormDataValidation = {
		errorTicketPlant: false,
		errorTicketRegion: false,
		errorTicketType: false,
		errorTicketLine: false,
		errorTicketMasterPlanner: false,
		errorTicketFgPlanner: false,
		errorTicketHours: false,
		errorTicketMaintenance: false,
		errorAddComment: false,
		errorTicketStatus: false,
		errorTicketBrandPlanner: false
	}
	const [ticketFormData, setTicketFormData] = useState<any>({
		...initTicketFormData
		// plant: availablePlants[0],
	})
	const [ticketFormDataValidation, setTicketFormDataValidation] = useState<any>(
		{
			...initTicketFormDataValidation
		}
	)
	const [dialogLoader, setDialogLoader] = useState<boolean>(true)
	const usersList = useSelector((state: any) => state.usersList)
	const downtimeState = useSelector((state: any) => state.downtime)
	const currentUser = usersList.currentUser
	const availablePlants = AvailablePlants()
	const [downTimeFilters, setDownTimeFilters]: any = useState({
		type: [],
		status: [],
		priority: [],
		master_planner_names: [],
		fg_planner_names: [],
		lines: [],
		brand_planner_names: []
	})

	const init = async () => {
		try {
			const filtersResponse = await getFiltersList(props.plantId)
			if (filtersResponse && filtersResponse?.data) {
				setDownTimeFilters({
					...downTimeFilters,
					status: filtersResponse.data?.status,
					priority: filtersResponse.data?.priority
				})
			}
			await getLevelTypes()
		} catch (error) {
			dispatch(loadSnackbar())
		} finally {
			if (!props.fromUpdate) {
				setDialogLoader(false)
			}
		}
	}

	const populateUpdateTicketDetails = async () => {
		if (props.fromUpdate && availablePlants.length > 0) {
			const updateTicketDetails: any = props.updateTicketDetails
			const id = updateTicketDetails?.plant_id?._id
			const plant = availablePlants.filter((plant: any) => plant?._id === id)
			const filtersResponse = await getFiltersList(props.plantId)

			const master_planner: any = {
				actioner_name: updateTicketDetails?.master_planner?.master_planner_name
			}

			const finished_goods_planner: any = {
				actioner_name:
					updateTicketDetails?.finished_goods_planner
						?.finished_goods_planner_name
			}

			const brand_planner: any = {
				actioner_name: updateTicketDetails?.brand_planner?.brand_planner_name
			}

			if (updateTicketDetails?.dt_ticket_type === 'Water') {
				master_planner.actioner_email =
					updateTicketDetails?.master_planner?.master_planner_mail
				finished_goods_planner.actioner_email =
					updateTicketDetails?.finished_goods_planner?.finished_goods_planner_mail
			}

			if (updateTicketDetails?.dt_ticket_type === 'Beverage') {
				brand_planner.actioner_email =
					updateTicketDetails?.brand_planner?.brand_planner_mail
			}
			const formData = {
				...initTicketFormData,
				plant: { ...plant[0] },
				createTicketRegion: updateTicketDetails?.dt_region,
				project_name: updateTicketDetails?.project_name,
				project_id: updateTicketDetails?.project_id,
				plant_poc_email: updateTicketDetails?.plant_poc?.email,
				project_poc_email: updateTicketDetails?.project_poc?.email,
				dt_ticket_type: {
					name: updateTicketDetails?.dt_ticket_type
				},
				dt_line_id: {
					name: updateTicketDetails?.dt_line_id
				},
				createTicketMasterPlanner: {
					...master_planner
				},
				createTicketFgPlanner: {
					...finished_goods_planner
				},
				createTicketBrandPlanner: {
					...brand_planner
				},
				dt_start_date: dayjs(
					moment(updateTicketDetails?.dt_start_date).format('MM-DD-YYYY HH:MM')
				),
				dt_min_date:
					dayjs(
						moment(updateTicketDetails?.dt_start_date).format(
							'MM-DD-YYYY HH:MM'
						)
					) < dayjs(moment().format('MM-DD-YYYY HH:MM'))
						? dayjs(
								moment(updateTicketDetails?.dt_start_date).format(
									'MM-DD-YYYY HH:MM'
								)
							)
						: dayjs(moment().format('MM-DD-YYYY HH:MM')),

				dt_end_date: dayjs(
					moment(updateTicketDetails?.dt_end_date).format('MM-DD-YYYY HH:MM')
				),
				dt_hours: updateTicketDetails?.dt_hours,

				dt_ticket_status: {
					name: updateTicketDetails.dt_ticket_status
				},
				dt_ticket_priority: {
					name: updateTicketDetails.dt_ticket_priority
				}
			}

			const plantTypes = getSelectedPlantTypes(plant[0])

			const lines = getUniqueLines(plant[0], {
				name: updateTicketDetails?.dt_ticket_type
			})

			const plannerNames = getLineMpAndFgPlanners(plant[0], {
				name: updateTicketDetails?.dt_line_id
			})

			setTimeout(() => {
				setTicketFormData({
					...formData
				})
				setDownTimeFilters({
					...downTimeFilters,
					type: plantTypes,
					lines: lines,
					master_planner_names: plannerNames.mp_planner_names,
					fg_planner_names: plannerNames.fg_planner_names,
					status: filtersResponse.data?.status,
					priority: filtersResponse.data?.priority,
					brand_planner_names: plannerNames.brand_planner_names
				})
				setDialogLoader(false)
			}, 3000)
		}
	}

	const handleSaveTicketCreated = () => {
		let isError = false

		const formDataErrorHandling: any = {
			...initTicketFormDataValidation
		}
		if (!ticketFormData?.plant) {
			formDataErrorHandling.errorTicketPlant = true
			isError = true
		}

		if (!ticketFormData?.createTicketRegion) {
			formDataErrorHandling.errorTicketRegion = true
			isError = true
		}

		if (!ticketFormData?.dt_ticket_type) {
			formDataErrorHandling.errorTicketType = true
			isError = true
		}

		if (!ticketFormData?.dt_line_id) {
			formDataErrorHandling.errorTicketLine = true
			isError = true
		}

		// Validate ticket type is Water.
		if (ticketFormData?.dt_ticket_type?.name === 'Water') {
			if (
				!ticketFormData?.createTicketMasterPlanner ||
				!ticketFormData?.createTicketMasterPlanner?.actioner_name ||
				!ticketFormData?.createTicketMasterPlanner?.actioner_email
			) {
				formDataErrorHandling.errorTicketMasterPlanner = true
				isError = true
			}

			if (
				!ticketFormData?.createTicketFgPlanner ||
				!ticketFormData?.createTicketFgPlanner?.actioner_name ||
				!ticketFormData?.createTicketFgPlanner?.actioner_email
			) {
				formDataErrorHandling.errorTicketFgPlanner = true
				isError = true
			}
		}

		if (ticketFormData?.dt_ticket_type?.name === 'Beverage') {
			if (
				!ticketFormData?.createTicketBrandPlanner ||
				!ticketFormData?.createTicketBrandPlanner?.actioner_name ||
				!ticketFormData?.createTicketBrandPlanner?.actioner_email
			) {
				formDataErrorHandling.errorTicketBrandPlanner = true
				isError = true
			}
		}

		if (!ticketFormData?.dt_hours) {
			formDataErrorHandling.errorTicketHours = true
			isError = true
		}

		if (!ticketFormData?.dt_ticket_priority) {
			formDataErrorHandling.errorTicketMaintenance = true
			isError = true
		}
		// added check to comment is mandatory
		if (
			(!ticketFormData?.createAddComment ||
				ticketFormData?.createAddComment?.trim() === '') &&
			!props?.fromUpdate
		) {
			formDataErrorHandling.errorAddComment = true
			isError = true
		}

		if (!isError) {
			const master_planner: any = {
				master_planner_name:
					ticketFormData?.createTicketMasterPlanner?.actioner_name ?? 'NA',
				master_planner_confirmed: false
			}

			const finished_goods_planner: any = {
				finished_goods_planner_name:
					ticketFormData?.createTicketFgPlanner?.actioner_name ?? 'NA',

				finished_goods_planner_confirmed: false
			}

			const brand_planner: any = {
				brand_planner_name:
					ticketFormData?.createTicketBrandPlanner?.actioner_name ?? 'NA',

				brand_planner_confirmed: false
			}

			if (ticketFormData?.dt_ticket_type?.name === 'Water') {
				master_planner.master_planner_mail =
					ticketFormData?.createTicketMasterPlanner?.actioner_email ?? 'NA'
				finished_goods_planner.finished_goods_planner_mail =
					ticketFormData?.createTicketFgPlanner?.actioner_email ?? 'NA'
			}

			if (ticketFormData?.dt_ticket_type?.name === 'Beverage') {
				brand_planner.brand_planner_mail =
					ticketFormData?.createTicketBrandPlanner?.actioner_email ?? 'NA'
			}

			const paylod: any = {
				plant_id: ticketFormData?.plant?._id,
				dt_ticket_type: ticketFormData?.dt_ticket_type?.name,
				dt_line_id: ticketFormData?.dt_line_id?.name,
				project_name: ticketFormData?.project_name,
				project_id: ticketFormData?.project_id,
				plant_poc_email: ticketFormData?.plant_poc_email,
				project_poc_email: ticketFormData?.project_poc_email,
				master_planner: {
					...master_planner
				},
				finished_goods_planner: {
					...finished_goods_planner
				},
				brand_planner: {
					...brand_planner
				},

				dt_start_date: moment(new Date(ticketFormData?.dt_start_date)).format(
					'MM/DD/YYYY'
				),
				dt_end_date: moment(new Date(ticketFormData?.dt_end_date)).format(
					'MM/DD/YYYY'
				),
				dt_hours: ticketFormData?.dt_hours,
				dt_ticket_status: ticketFormData?.dt_ticket_status?.name,
				dt_ticket_priority: ticketFormData?.dt_ticket_priority?.name,

				// Current login user id
				dt_requestor_id: currentUser._id,
				dt_region: ticketFormData.createTicketRegion
			}

			if (!props.fromUpdate) {
				paylod.comments = [
					{
						attachments: [],
						// Login User Email
						from: currentUser.mail,
						device_name: '',
						is_edited: true,
						created_on: new Date(),
						text: {
							blocks: [
								{
									data: {},
									depth: 0,
									entityRanges: [],
									inlineStyleRanges: [],
									key: ticketFormData?.createAddComment?.trim(),
									text: ticketFormData?.createAddComment?.trim(),
									type: 'unstyled'
								}
							],
							entityMap: {}
						}
					}
				]
			}
			if (props.fromUpdate) {
				paylod._id = props.updateTicketDetails._id
				paylod.dt_ticket_alias = props.updateTicketDetails.dt_ticket_alias
				paylod.modified_by = currentUser?._id
			}

			if (typeof props.primaryAction === 'function') {
				props.primaryAction(paylod)
				handleClose()
			}
		} else {
			setTicketFormDataValidation({
				...formDataErrorHandling
			})
		}
	}

	const handleClose = () => {
		if (typeof props.secondaryAction === 'function') {
			setTicketFormData({
				...initTicketFormData
			})
			setTicketFormDataValidation({
				...initTicketFormDataValidation
			})
			props.secondaryAction()
		}
	}

	const calculateDownTimeHours = () => {
		const startDate = ticketFormData.dt_start_date
		const endDate = ticketFormData.dt_end_date
		const duration = moment.duration(endDate.diff(startDate))
		let hours = Math.ceil(duration.asHours() / 3)
		if (hours <= 0) {
			hours = 0
		}
		setTicketFormData({
			...ticketFormData,
			...ticketFormData,
			dt_hours: hours
		})

		setTicketFormDataValidation({
			...ticketFormDataValidation,
			errorTicketHours: false
		})
	}

	useEffect(() => {
		init()
	}, [props.plantId])

	useEffect(() => {
		const ticketType = ticketFormData?.dt_ticket_type?.name

		// Automatically fill master planner with "NA" for "Plant Director" and "Beverage"
		if (ticketType === 'Plant Director' || ticketType === 'Beverage') {
			setTicketFormData({
				...ticketFormData,
				createTicketMasterPlanner: {
					actioner_name: 'NA',
					actioner_email: 'NA'
				}
			})
		}
	}, [ticketFormData?.dt_ticket_type])

	useEffect(() => {
		populateUpdateTicketDetails()
	}, [props.fromUpdate, availablePlants])

	useEffect(() => {
		calculateDownTimeHours()
	}, [ticketFormData.dt_start_date, ticketFormData.dt_end_date])

	return (
		<Dialog
			open={props.open}
			aria-labelledby='form-dialog-title'
			maxWidth={false}
			className={classes.bookmarkDialog}
		>
			<div className={classes.dialogTitleContainer}>
				<div className='contentWrapper'>
					<div style={{ textAlign: 'center' }}>
						<CreateIcon />
						<DialogTitle id='form-dialog-title'>{props.title}</DialogTitle>
					</div>
					<Divider />
				</div>

				{dialogLoader ? (
					<CircularProgress />
				) : (
					<Box className={classes.createTicketFields}>
						{/* Plant dropdown selection */}
						{props.fromUpdate ? (
							<TextField
								disabled
								id='combo-box-demo'
								label='Plant'
								sx={{
									'& .MuiInputBase-root.Mui-disabled': {
										'& > fieldset': {
											border: `1px solid ${
												ticketFormDataValidation?.errorTicketRegion
													? '#FF0000'
													: '#fff'
											}`
										}
									}
								}}
								value={ticketFormData?.plant?.plant_name}
							/>
						) : (
							<AutoCompleteDropdown
								className={classes.autoDropdown}
								options={availablePlants}
								value={ticketFormData?.plant}
								disabled={props?.fromUpdate}
								handleChange={(event: any, newValue: any) => {
									const region = getSelectedPlanRegion(newValue)
									const types = getSelectedPlantTypes(newValue)
									setTicketFormData({
										...ticketFormData,
										plant: newValue,
										createTicketRegion: region,
										dt_ticket_type: null,
										dt_line_id: null,
										createTicketMasterPlanner: null,
										createTicketFgPlanner: null
									})

									setDownTimeFilters({
										...downTimeFilters,
										type: types,
										master_planner_names: [],
										fg_planner_names: [],
										lines: [],
										brand_planner_names: []
									})

									// based on type populate lines, master planner name, fg planner name
									setTicketFormDataValidation({
										...ticketFormDataValidation,
										errorTicketPlant: false,
										errorTicketType: false,
										errorTicketRegion: false,
										errorTicketMasterPlanner: false,
										errorTicketFgPlanner: false,
										errorTicketLine: false
									})
								}}
								icon={<DownIcon />}
								placeholder='Plant'
								error={ticketFormDataValidation?.errorTicketPlant}
								errorText='This field is required'
								optionLabel={'plant_name'}
								showLabel={true}
								label='Plant'
							/>
						)}

						{/* Region Text field */}
						<TextField
							disabled
							id='combo-box-demo'
							label='Region'
							sx={{
								'& .MuiInputBase-root.Mui-disabled': {
									'& > fieldset': {
										border: `1px solid ${
											ticketFormDataValidation?.errorTicketRegion
												? '#FF0000'
												: '#fff'
										}`
									}
								}
							}}
							value={ticketFormData?.createTicketRegion}
							onChange={e => {
								setTicketFormData({
									...ticketFormData,
									createTicketRegion: e.target.value
								})
								setTicketFormDataValidation({
									...ticketFormDataValidation,
									errorTicketRegion: false
								})
							}}
							error={ticketFormDataValidation?.errorTicketRegion}
							helperText={
								ticketFormDataValidation?.errorTicketRegion
									? 'This field is required'
									: ''
							}
						/>

						{/* Type DropDown field */}
						{props.fromUpdate ? (
							<TextField
								disabled
								id='combo-box-demo'
								label='Type'
								sx={{
									'& .MuiInputBase-root.Mui-disabled': {
										'& > fieldset': {
											border: `1px solid ${
												ticketFormDataValidation?.errorTicketRegion
													? '#FF0000'
													: '#fff'
											}`
										}
									}
								}}
								value={ticketFormData?.dt_ticket_type?.name}
							/>
						) : (
							<AutoCompleteDropdown
								className={classes.autoDropdown}
								disabled={props?.fromUpdate}
								options={
									downtimeState.tabOptions?.category === 'NPI'
										? downTimeFilters.type.filter(
												(type: any) => type.name.toLowerCase() === 'water'
											)
										: downTimeFilters.type
								}
								value={ticketFormData?.dt_ticket_type}
								handleChange={(event: any, newValue: any) => {
									const uniquesLines = getUniqueLines(
										ticketFormData.plant,
										newValue
									)

									setDownTimeFilters({
										...downTimeFilters,
										lines: uniquesLines,
										master_planner_names: [],
										fg_planner_names: [],
										brand_planner_names: []
									})

									setTicketFormData({
										...ticketFormData,
										dt_ticket_type: newValue,
										dt_line_id: null,
										createTicketMasterPlanner: null,
										createTicketFgPlanner: null
									})
									setTicketFormDataValidation({
										...ticketFormDataValidation,
										errorTicketType: false
									})
								}}
								icon={<DownIcon />}
								placeholder='Type'
								error={ticketFormDataValidation?.errorTicketType}
								errorText='This field is required'
								optionLabel='name'
								showLabel={true}
								label='Type'
							/>
						)}
						{/* Line DropDown */}
						{props.fromUpdate ? (
							<TextField
								disabled
								id='combo-box-demo'
								label='Line'
								sx={{
									'& .MuiInputBase-root.Mui-disabled': {
										'& > fieldset': {
											border: `1px solid ${
												ticketFormDataValidation?.errorTicketRegion
													? '#FF0000'
													: '#fff'
											}`
										}
									}
								}}
								value={ticketFormData?.dt_line_id?.name}
							/>
						) : (
							<AutoCompleteDropdown
								className={classes.autoDropdown}
								options={downTimeFilters.lines}
								disabled={props?.fromUpdate}
								value={ticketFormData?.dt_line_id}
								handleChange={(event: any, newValue: any) => {
									const planners = getLineMpAndFgPlanners(
										ticketFormData.plant,
										newValue
									)
									setDownTimeFilters({
										...downTimeFilters,
										master_planner_names: planners.mp_planner_names,
										fg_planner_names: planners.fg_planner_names,
										brand_planner_names: planners.brand_planner_names
									})

									setTicketFormData({
										...ticketFormData,
										dt_line_id: newValue,
										createTicketMasterPlanner: null,
										createTicketFgPlanner: null,
										createTicketBrandPlanner: null
									})
									setTicketFormDataValidation({
										...ticketFormDataValidation,
										errorTicketLine: false
									})
								}}
								icon={<DownIcon />}
								placeholder='Line'
								error={ticketFormDataValidation?.errorTicketLine}
								errorText='This field is required'
								optionLabel='name'
								showLabel={true}
								label='Line'
							/>
						)}

						{/* Brand Planner Name dropdown*/}

						{ticketFormData?.dt_ticket_type?.name === 'Beverage' ? (
							<AutoCompleteDropdown
								className={classes.autoDropdown}
								options={downTimeFilters.brand_planner_names}
								value={ticketFormData?.createTicketBrandPlanner}
								handleChange={(event: any, newValue: any) => {
									setTicketFormData({
										...ticketFormData,
										createTicketBrandPlanner: newValue
									})
									setTicketFormDataValidation({
										...ticketFormDataValidation,
										errorTicketBrandPlanner: false
									})
								}}
								icon={<DownIcon />}
								placeholder='Brand Planner Name'
								error={ticketFormDataValidation?.errorTicketBrandPlanner}
								errorText='This field is required'
								optionLabel='actioner_name'
								showLabel={true}
								label='Brand Planner Name'
							/>
						) : (
							<>
								<AutoCompleteDropdown
									className={classes.autoDropdown}
									options={downTimeFilters.master_planner_names}
									value={ticketFormData?.createTicketMasterPlanner}
									handleChange={(event: any, newValue: any) => {
										setTicketFormData({
											...ticketFormData,
											createTicketMasterPlanner: newValue
										})
										setTicketFormDataValidation({
											...ticketFormDataValidation,
											errorTicketMasterPlanner: false
										})
									}}
									icon={<DownIcon />}
									placeholder='Master Planner Name'
									error={ticketFormDataValidation?.errorTicketMasterPlanner}
									errorText='This field is required'
									optionLabel='actioner_name'
									showLabel={true}
									label='Master Planner Name'
								/>

								{/* FG Planner Name dropdown*/}
								<AutoCompleteDropdown
									className={classes.autoDropdown}
									options={downTimeFilters.fg_planner_names}
									value={ticketFormData?.createTicketFgPlanner}
									handleChange={(event: any, newValue: any) => {
										setTicketFormData({
											...ticketFormData,
											createTicketFgPlanner: newValue
										})
										setTicketFormDataValidation({
											...ticketFormDataValidation,
											errorTicketFgPlanner: false
										})
									}}
									icon={<DownIcon />}
									placeholder='Fg Planner Name'
									error={ticketFormDataValidation?.errorTicketFgPlanner}
									errorText='This field is required'
									optionLabel='actioner_name'
									showLabel={true}
									label='Fg Planner Name'
								/>
							</>
						)}

						<Box className={classes.statusDatePiker}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DatePicker', 'DatePicker']}>
									<DatePicker
										minDate={ticketFormData.dt_min_date}
										format='MM/DD/YYYY'
										label='Plant Downtime Start Date'
										value={ticketFormData?.dt_start_date}
										onChange={newValue =>
											setTicketFormData({
												...ticketFormData,
												dt_start_date: newValue
											})
										}
									/>
									<DatePicker
										minDate={ticketFormData?.dt_start_date}
										format='MM/DD/YYYY'
										label='Plant Downtime End Date'
										value={ticketFormData?.dt_end_date}
										onChange={newValue => {
											setTicketFormData({
												...ticketFormData,
												dt_end_date: newValue
											})
										}}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</Box>

						{/* Plant Downtime Hours tickets */}
						<TextField
							disabled
							id='combo-box-demo'
							label='Plant Downtime Hours'
							value={ticketFormData?.dt_hours}
							onChange={e => {
								setTicketFormData({
									...ticketFormData,
									dt_hours: e.target.value
								})
								setTicketFormDataValidation({
									...ticketFormDataValidation,
									errorTicketHours: false
								})
							}}
							error={ticketFormDataValidation?.errorTicketHours}
							helperText={
								ticketFormDataValidation?.errorTicketHours
									? 'Hours should not be zero'
									: ''
							}
							sx={{
								'& .MuiInputBase-root.Mui-disabled': {
									'& > fieldset': {
										border: `1px solid ${
											ticketFormDataValidation?.errorTicketHours
												? '#FF0000'
												: '#fff'
										}`
									}
								}
							}}
						/>

						{/*Ticket status*/}
						{!props?.fromUpdate && (
							<AutoCompleteDropdown
								className={classes.autoDropdown}
								options={
									// downTimeFilters.status
									createTicketStatus
								}
								value={ticketFormData?.dt_ticket_status}
								handleChange={(event: any, newValue: any) => {
									setTicketFormData({
										...ticketFormData,
										dt_ticket_status: newValue
									})
									setTicketFormDataValidation({
										...ticketFormDataValidation,
										errorTicketStatus: false
									})
								}}
								icon={<DownIcon />}
								placeholder='Ticket Status'
								error={ticketFormDataValidation?.errorTicketStatus}
								errorText='This field is required'
								optionLabel='name'
								showLabel={true}
								label='Ticket Status'
							/>
						)}

						{/* Maintenance Priority dropdown*/}
						<AutoCompleteDropdown
							className={classes.autoDropdown}
							options={downTimeFilters.priority?.filter(
								(item: any) => item?.name !== 'None'
							)}
							value={ticketFormData?.dt_ticket_priority}
							handleChange={(event: any, newValue: any) => {
								setTicketFormData({
									...ticketFormData,
									dt_ticket_priority: newValue
								})
								setTicketFormDataValidation({
									...ticketFormDataValidation,
									errorTicketMaintenance: false
								})
							}}
							icon={<DownIcon />}
							placeholder='Maintenance Priority'
							error={ticketFormDataValidation?.errorTicketMaintenance}
							errorText='This field is required'
							optionLabel='name'
							showLabel={true}
							label='Maintenance Priority'
						/>

						<TextField
							id='combo-box-demo'
							label='Project Name'
							value={ticketFormData?.project_name}
							onChange={e => {
								setTicketFormData({
									...ticketFormData,
									project_name: e.target.value
								})
							}}
							sx={{
								'& .MuiInputBase-root.Mui-disabled': {
									'& > fieldset': {
										border: `1px solid #fff`
									}
								}
							}}
						/>

						<TextField
							id='combo-box-demo'
							label='Project Id'
							value={ticketFormData?.project_id}
							onChange={e => {
								setTicketFormData({
									...ticketFormData,
									project_id: e.target.value
								})
							}}
							sx={{
								'& .MuiInputBase-root.Mui-disabled': {
									'& > fieldset': {
										border: `1px solid #fff`
									}
								}
							}}
						/>
						<TextField
							id='combo-box-demo'
							label='Plant POC Email'
							value={ticketFormData?.plant_poc_email}
							onChange={e => {
								setTicketFormData({
									...ticketFormData,
									plant_poc_email: e.target.value
								})
							}}
							sx={{
								'& .MuiInputBase-root.Mui-disabled': {
									'& > fieldset': {
										border: `1px solid #fff`
									}
								}
							}}
						/>

						<TextField
							id='combo-box-demo'
							label='Project POC Email'
							value={ticketFormData?.project_poc_email}
							onChange={e => {
								setTicketFormData({
									...ticketFormData,
									project_poc_email: e.target.value
								})
							}}
							sx={{
								'& .MuiInputBase-root.Mui-disabled': {
									'& > fieldset': {
										border: `1px solid #fff`
									}
								}
							}}
						/>

						{/* add comment text filed */}
						{!props?.fromUpdate && (
							<TextField
								multiline={true}
								rows={3}
								name='addComment'
								label='Add Comment'
								placeholder='Add Comment'
								autoComplete='off'
								variant='outlined'
								value={ticketFormData?.createAddComment}
								onChange={e => {
									setTicketFormData({
										...ticketFormData,
										createAddComment: e.target.value
									})
									setTicketFormDataValidation({
										...ticketFormDataValidation,
										errorAddComment: false
									})
								}}
								error={ticketFormDataValidation?.errorAddComment}
								helperText={
									ticketFormDataValidation?.errorAddComment
										? 'This field is required'
										: ''
								}
							/>
						)}
					</Box>
				)}
				<Grid item md={12} marginTop={'60px'} className='btnFoot'>
					<Button
						// disabled={dialogLoader}
						onClick={() => {
							handleClose()
						}}
						variant='outlined'
					>
						{props.secondaryButtonText}
					</Button>
					<Button
						disabled={dialogLoader}
						onClick={() => {
							handleSaveTicketCreated()
						}}
						className='saveBtn'
					>
						{props.primaryButtonText}
					</Button>
				</Grid>
			</div>
		</Dialog>
	)
}

export default CreateUpdateTicket
