import {
  LOAD_CIPWASH,
  LOAD_CIPWASH_SUCCESS,
  LOAD_CIPWASH_ERROR,
  GET_CIPWASH_BY_ID,
  GET_CIPWASH_BY_ID_SUCCESS,
  GET_CIPWASH_BY_ID_ERROR,
  LOAD_CIP_EXCEPTIONS,
  LOAD_CIP_EXCEPTIONS_SUCCESS,
  LOAD_CIP_EXCEPTIONS_ERROR,
  LOAD_CIP_EXCEPTIONS_EXPORT,
} from "../Types/cipWash.constants";

const INITIAL_STATE = {
  loading: false,
  wash: [],
  error: null,
  currentWash: null,
  exceptions: [],
};

export const cipWashListReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOAD_CIPWASH:
      return { ...state, loading: true, wash: [] };
    case LOAD_CIPWASH_SUCCESS:
      return {
        ...state,
        loading: false,
        wash: action.payload,
      };
    case LOAD_CIPWASH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        currentWash: null,
      };
    case GET_CIPWASH_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        currentWash: null,
      };

    case GET_CIPWASH_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentWash: action.payload,
      };
    case GET_CIPWASH_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case LOAD_CIP_EXCEPTIONS:
      return {
        ...state,
        loading: true,
        exceptions: []
      };
    case LOAD_CIP_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        exceptions: action.payload,
      };
    case LOAD_CIP_EXCEPTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        exceptions: []
      };
    case LOAD_CIP_EXCEPTIONS_EXPORT:
      return {
        ...state,
        loading: false,
      }
    default:
      return state;
  }
};
