import { Autocomplete, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC } from 'react'

const useStyles = makeStyles(() => ({
	autoDropdown: {
		'& .MuiInputBase-root': {
			display: 'flex'
		},
		'& .MuiInputBase-input': {
			width: '100% !important'
		}
	}
}))

export interface AutoCompleteDropdownProps {
	options: any[]
	value: any
	handleChange: (_event: any, newValue: any) => void
	placeholder: string
	className?: any
	icon?: any
	defaultValue?: string
	error?: boolean
	errorText?: string
	optionLabel?: string
	showLabel?: boolean
	label?: string
	[key: string]: any
}

const AutoCompleteDropdown: FC<AutoCompleteDropdownProps> = ({
	value,
	handleChange,
	placeholder,
	className,
	icon,
	error,
	errorText,
	optionLabel,
	showLabel,
	label,
	...rest
}) => {
	const classes = useStyles()

	return (
		<Autocomplete
			disableClearable
			className={className || classes.autoDropdown}
			getOptionLabel={(option: any) =>
				optionLabel ? option[optionLabel] : option.label
			}
			value={value}
			onChange={(_event: any, newValue: any) => {
				if (typeof handleChange === 'function') {
					handleChange(_event, newValue)
				}
			}}
			popupIcon={icon ?? ''}
			renderInput={(params: any) => (
				<TextField
					{...params}
					placeholder={placeholder}
					error={error ?? false}
					helperText={error ? errorText : ''}
					label={showLabel ? label : ''}
				/>
			)}
			{...rest}
		/>
	)
}

export default AutoCompleteDropdown
