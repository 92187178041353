import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from '@mui/material'
import { clone, cloneDeep, get, isArray, isEmpty, set } from 'lodash'
import { useState } from 'react'
import {
	ALPHANUMERIC_VALIDATION_REGEX,
	TAG_VALIDATION_REGEX
} from '../../Utils/SelfServiceHelper'

function TagGenerator(props: any) {
	const { items, onSeqChange, onError } = props
	const [searchBy, setSearchBy] = useState<string>('tag')
	const [searchedTerm, setSearchedTerm] = useState<string>('')
	const [editFieldIndex, setEditFieldIndex] = useState<number>(-1)
	const [updatedIndex, setUpdatedIndex] = useState<number[]>([])
	const [error, setError] = useState<any>({
		tag: false,
		description: false
	})
	const [updatedRowData, setUpdatedRowData] = useState<any>({})
	const [showNewField, setShowNewField] = useState<boolean>(false)
	const [newFieldValue, setNewFieldValue] = useState<any>({})

	const handleCheck = (editIndex: number, editItem: any) => {
		const updatedData = cloneDeep(items)
		const { tag, description } = updatedRowData
		const updatedError = clone(error)

		if (isEmpty(tag) || !TAG_VALIDATION_REGEX.test(updatedRowData.tag)) {
			onError(true)
			updatedError.tag = true
		}
		if (
			isEmpty(description) ||
			!ALPHANUMERIC_VALIDATION_REGEX.test(updatedRowData.description)
		) {
			onError(true)
			updatedError.description = true
		}

		if (updatedError.tag || updatedError.description) {
			setError(updatedError)
		} else if (!isEmpty(updatedRowData) && !isEmpty(updatedData[editIndex])) {
			const findIndexValue = updatedData.findIndex(
				(item: any) => item.key === editItem.key
			)

			set(updatedData, `[${findIndexValue}]`, {
				...updatedData[findIndexValue],
				...updatedRowData
			})
			onSeqChange(updatedData)
			handleClose()
		}
	}

	const handleNewFieldCheck = () => {
		const updatedData = cloneDeep(items)
		const { key, tag, description } = newFieldValue
		const updatedError = { key: false, tag: false, description: false }

		if (
			isEmpty(key) ||
			!ALPHANUMERIC_VALIDATION_REGEX.test(newFieldValue.key)
		) {
			onError(true)
			updatedError.key = true
		}
		if (isEmpty(tag) || !TAG_VALIDATION_REGEX.test(newFieldValue.tag)) {
			onError(true)
			updatedError.tag = true
		}
		if (
			isEmpty(description) ||
			!ALPHANUMERIC_VALIDATION_REGEX.test(newFieldValue.description)
		) {
			onError(true)
			updatedError.description = true
		}

		if (updatedError.tag || updatedError.description || updatedError.key) {
			setError(updatedError)
		} else if (!isEmpty(newFieldValue)) {
			const isPresent = updatedData.findIndex(
				(data: any) => data.key === newFieldValue.key
			)
			const placementIndex = isPresent >= 0 ? isPresent : updatedData.length
			set(updatedData, `[${placementIndex}]`, {
				...newFieldValue
			})
			onSeqChange(updatedData)
			setShowNewField(false)
			handleClose()
		}
	}

	const handleClose = () => {
		setError({})
		onError(false)
		setUpdatedIndex([])
		setEditFieldIndex(-1)
		setUpdatedRowData({})
	}

	const handleChange = (value: string, type: string, editIndex: number) => {
		const updatedData = cloneDeep(items)
		const newRowData = {
			...clone(updatedRowData),
			[type]: value
		}
		const { tag, description } = newRowData
		const updatedError = { tag: false, description: false, ...cloneDeep(error) }

		if (type === 'tag') {
			updatedError.tag = isEmpty(tag) || !TAG_VALIDATION_REGEX.test(tag)
		}

		if (type === 'description') {
			updatedError.description =
				isEmpty(description) || !ALPHANUMERIC_VALIDATION_REGEX.test(description)
		}

		if (updatedError.tag || updatedError.description) {
			onError(true)
			setError(updatedError)
		} else {
			setError({})
		}

		const toBeEditedField = get(updatedData, `[${editIndex}].${type}`, null)
		if (toBeEditedField && toBeEditedField !== value) {
			setUpdatedIndex([...updatedIndex, editIndex])
			setUpdatedRowData(newRowData)
		}
	}

	const handleNewRecordBlur = (value: string, type: string) => {
		const updatedFieldData = {
			...cloneDeep(newFieldValue),
			[type]: value
		}
		const { key, tag, description } = updatedFieldData
		const updatedError = {
			key: false,
			tag: false,
			description: false,
			...cloneDeep(error)
		}

		if (type === 'key') {
			updatedError.key =
				isEmpty(key) || !ALPHANUMERIC_VALIDATION_REGEX.test(key)
		}

		if (type === 'tag') {
			updatedError.tag = isEmpty(tag) || !TAG_VALIDATION_REGEX.test(tag)
		}

		if (type === 'description') {
			updatedError.description =
				isEmpty(description) || !ALPHANUMERIC_VALIDATION_REGEX.test(description)
		}

		if (updatedError.tag || updatedError.description || updatedError.key) {
			onError(true)
			setError(updatedError)
		} else {
			setError({})
		}

		setNewFieldValue(updatedFieldData)
	}

	const onEditIconClick = (item: any, index: number) => {
		setUpdatedRowData({
			...item
		})
		setError({})
		setEditFieldIndex(index)
		setUpdatedIndex([...updatedIndex, index])
		setNewFieldValue({})
		setShowNewField(false)
	}

	if (isEmpty(items) || !isArray(items)) return null

	const filteredItems = items.filter((item: any) => {
		return searchedTerm
			? item[searchBy]
					.toLocaleLowerCase()
					.includes(searchedTerm.toLocaleLowerCase())
			: true
	})

	return (
		<>
			<Grid container style={{ margin: '8px 0em', padding: '0em' }} spacing={2}>
				<Grid style={{ padding: '8px 8px 0 0' }} item xs={2}>
					<FormControl fullWidth>
						<InputLabel id='demo-simple-select-label'>Search By</InputLabel>
						<Select
							label='Search By'
							value={searchBy}
							onChange={event => {
								setSearchBy(event.target.value)
								setSearchedTerm('')
							}}
						>
							<MenuItem value='key'>Key</MenuItem>
							<MenuItem value='tag'>Tag</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				<Grid style={{ padding: '8px 8px 0 0' }} item xs={10}>
					<TextField
						label='Search text'
						type='text'
						fullWidth
						value={searchedTerm}
						onChange={event => {
							setSearchedTerm(event.target.value)
							setEditFieldIndex(-1)
						}}
					/>
				</Grid>
			</Grid>
			<TableContainer
				style={{
					margin: '10px',
					display: 'flex',
					minWidth: '1100px',
					overflow: 'auto',
					maxHeight: '500px'
				}}
				component={Paper}
			>
				<Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
					<TableHead>
						<TableRow>
							<TableCell>Key</TableCell>
							<TableCell>Tag</TableCell>
							<TableCell>Description</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredItems.map((item: any, index: number) => (
							<TableRow
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0
									}
									// ...updatedIndex.includes(index) ? { background: 'rgb(227, 205, 4)' } : {}
								}}
								className={updatedIndex.includes(index) ? 'modifiedRow' : ''}
								key={item?.key}
							>
								<TableCell component='th' scope='row'>
									{item.key}
								</TableCell>
								<TableCell component='th' scope='row'>
									{editFieldIndex === index ? (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%'
											}}
										>
											<TextField
												defaultValue={item.tag}
												label='Tag Value'
												onBlur={event =>
													handleChange(event.target.value, 'tag', index)
												}
												error={error.tag}
												helperText={error.tag ? 'Invalid tag value' : ''}
											/>
										</div>
									) : (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%'
											}}
										>
											{item.tag}
										</div>
									)}
								</TableCell>
								<TableCell component='th' scope='row'>
									{editFieldIndex === index ? (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%'
											}}
										>
											<TextField
												defaultValue={item.description}
												label='Description Value'
												onBlur={event =>
													handleChange(event.target.value, 'description', index)
												}
												error={error.description}
												helperText={
													error.description ? 'Invalid description value' : ''
												}
											/>
										</div>
									) : (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%'
											}}
										>
											{item.description}
										</div>
									)}
								</TableCell>
								<TableCell component='th' scope='row'>
									{editFieldIndex === index ? (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}
										>
											<CheckIcon onClick={() => handleCheck(index, item)} />
											<CloseIcon onClick={() => handleClose()} />
										</div>
									) : (
										<EditIcon onClick={() => onEditIconClick(item, index)} />
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{showNewField ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						paddingTop: '8px'
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%'
						}}
					>
						<TextField
							sx={{ width: '95%' }}
							label='Key Value'
							onBlur={event => handleNewRecordBlur(event.target.value, 'key')}
							error={error.key}
							helperText={error.key ? 'Invalid key value' : ''}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%'
						}}
					>
						<TextField
							sx={{ width: '95%' }}
							label='Tag Value'
							onBlur={event => handleNewRecordBlur(event.target.value, 'tag')}
							error={error.tag}
							helperText={error.tag ? 'Invalid tag value' : ''}
						/>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%'
						}}
					>
						<TextField
							sx={{ width: '95%' }}
							label='Description Value'
							onBlur={event =>
								handleNewRecordBlur(event.target.value, 'description')
							}
							error={error.description}
							helperText={error.description ? 'Invalid description value' : ''}
						/>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<CheckIcon onClick={() => handleNewFieldCheck()} />
						<CloseIcon
							onClick={() => {
								setNewFieldValue({})
								setShowNewField(false)
								setError({})
							}}
						/>
					</div>
				</div>
			) : (
				<Button
					onClick={() => {
						setShowNewField(true)
						setError({})
						setEditFieldIndex(-1)
					}}
				>
					Add New
				</Button>
			)}
		</>
	)
}

export default TagGenerator
