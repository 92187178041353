import axios, {AxiosError} from "axios";
import { env } from "../../env";
import { returnAccessToken } from "../../Services/authService";

//creating an axios instance that will be used throughout the app
const Axios = axios.create({
  baseURL: env.baseURL_monarch,
});

Axios.interceptors.request.use(
  async (config: any) => {
    const token = await returnAccessToken("API");
    config.headers.common["Authorization"] = "Bearer " + token;
    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      throw new Error(`Your are UnAuthorized" , ${error.response.statusText}`);
    }
    
    return Promise.reject(error);
  }
);

export default Axios;
