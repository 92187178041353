// ./src/azure-storage-blob.ts

// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { env } from "../env";

const containerName = `commentbox-images-container`;
const satContainerName = `sat-uploaded-files`;
const sasToken = env.REACT_APP_STORAGESASTOKEN;
const storageAccountName = env.REACT_APP_STORAGERESOURCENAME;
// </snippet_package>
// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !storageAccountName || !sasToken ? false : true;
};
// </snippet_isStorageConfigured>

// <snippet_getBlobsInContainer>
// return list of blobs in container to display
async (containerClient: ContainerClient) => {
  const returnedBlobUrls: string[] = [];

  // get list of blobs in container
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
};
// </snippet_getBlobsInContainer>

// <snippet_createBlobInContainer>
const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File
) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadData(file, options);

  return blobClient.url.substring(0, blobClient.url.indexOf("?"));
};
// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (file: File | null): Promise<string> => {
  if (!file) return "";

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient =
    blobService.getContainerClient(containerName);
  await containerClient.createIfNotExists({
    access: "container",
  });
  // upload file
  const fileUpload = await createBlobInContainer(containerClient, file);
  // get url of uploaded blob
  return fileUpload;
};

//new upload service to blob for SAT
// <snippet_uploadFileToBlob>
export const uploadFileToBlobSAT = async (
  file: File | null
): Promise<string> => {
  if (!file) return "";

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient =
    blobService.getContainerClient(satContainerName);
  await containerClient.createIfNotExists({
    access: "container",
  });
  // upload file
  const fileUpload = await createBlobInContainer(containerClient, file);
  // get url of uploaded blob
  return fileUpload;
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;
