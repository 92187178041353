import { FC } from 'react'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { xs: 185, md: 570 },
	p: '20px',
	color: 'white',
	fontFamily: 'Poppins',
	borderRadius: '12px',
	bgcolor: '#293368',
	border: '#293368'
}

interface SubmitFilePopupProps {
	role?: any
	submitFileButton: any
	handleSubmitFileModalClose: any
	handleSubmitOEMDocs?: any
	handleSubmitPDDocs?: any
}

const SubmitFilePopup: FC<SubmitFilePopupProps> = ({
	role,
	submitFileButton,
	handleSubmitFileModalClose,
	handleSubmitOEMDocs,
	handleSubmitPDDocs
}) => {
	return (
		<Modal open={submitFileButton} onClose={handleSubmitFileModalClose}>
			<Box sx={style}>
				<Grid container direction='row' spacing={2}>
					<Grid item xs={12}>
						<Typography sx={{ fontSize: '1.2rem', fontWeight: '400' }}>
							Are you sure?
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography
							style={{
								fontWeight: '300',
								fontSize: '1rem'
							}}
						>
							Files once uploaded cannot be modified. Do you want to submit the
							uploaded files?
						</Typography>
					</Grid>
				</Grid>

				<Grid
					item
					xs={6}
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						gap: '20px',
						paddingTop: '30px'
					}}
				>
					<Button
						variant='outlined'
						sx={{
							width: '120px',
							height: 'auto',
							textTransform: 'normal',
							borderRadius: '30px'
						}}
						onClick={() => {
							handleSubmitFileModalClose()
						}}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						sx={{
							width: '120px',
							height: 'auto',
							textTransform: 'normal',
							borderRadius: '30px'
						}}
						onClick={() => {
							role === 'OEM' ? handleSubmitOEMDocs() : handleSubmitPDDocs()
							handleSubmitFileModalClose()
						}}
					>
						Yes, I agree
					</Button>
				</Grid>
			</Box>
		</Modal>
	)
}

export default SubmitFilePopup
