import {
  LOAD_MODELS,
  LOAD_MODELS_SUCCESS,
  LOAD_MODELS_ERROR,
} from "../Types/models.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all models from the api
const getModels = async () => {
  let models: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`model?pageSize=100&page=${page++}`);
    models = models.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return models;
};

export function* listModels(): any {
  try {
    const data = yield call(() =>
      getModels().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_MODELS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: LOAD_MODELS_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export function* listModelsSaga() {
  yield takeEvery(LOAD_MODELS, listModels);
}
