import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import { CircularProgress } from '@mui/material'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Asset, Plant } from '../../models/Types_Interfaces'
import { GREEN, RED, YELLOW } from '../../Utils/UtilFunctions/constant'
import {
	getFormatedDate,
	getTimeZoneTimeStamp
} from '../../Utils/UtilFunctions/timeZoneFormatter'

function AsepticCCPAlert(props: any) {
	const ticketsList = useSelector((state: any) => state.ticketsList)
	const plantsList = useSelector((state: any) => state.plantsList)
	const levelsList = useSelector((state: any) => state.levelsList)
	const messagesList = useSelector((state: any) => state.messagesList)
	const navigate = useNavigate()

	return (
		<Modal open={props.open} onClose={props.handleClose} closeAfterTransition>
			<Fade in={props.open}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						position: 'absolute' as const,
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: window?.innerWidth < 400 ? '300px' : '550px',
						boxShadow: '24',
						padding: '16px 40px',
						color: 'white',
						borderRadius: '12px',
						backgroundColor:
							props.alert.message_data.CurrentValue <
							props.alert.message_data.LowerThreshold
								? RED
								: props.alert.message_data.CurrentValue >
									  props.alert.message_data.UpperThreshold
									? YELLOW
									: GREEN,
						border:
							props.alert.message_data.CurrentValue <
							props.alert.message_data.LowerThreshold
								? `3px solid ${RED}`
								: props.alert.message_data.CurrentValue >
									  props.alert.message_data.UpperThreshold
									? `3px solid ${YELLOW}`
									: `3px solid ${GREEN}`
					}}
				>
					<div
						style={{
							marginTop: '20px'
						}}
					>
						{props.alert.message_data.CurrentValue <
						props.alert.message_data.LowerThreshold ? (
							<CancelIcon sx={{ fontSize: '60px' }} />
						) : props.alert.message_data.CurrentValue >
						  props.alert.message_data.UpperThreshold ? (
							<InfoIcon sx={{ fontSize: '60px' }} />
						) : (
							<CheckCircleIcon sx={{ fontSize: '60px' }} />
						)}
					</div>

					<div
						style={{
							marginBottom: '5px',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<h5 style={{ fontSize: '1.2rem' }}>
							Aseptic CIP -{' '}
							{plantsList.plants.find((plant: Plant) => {
								return plant._id === props.alert.plant_id
							}) !== undefined
								? plantsList.plants.find((plant: Plant) => {
										return plant._id === props.alert.plant_id
									}).plant_name
								: 'Plant not found'}
						</h5>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black',
								padding: '12px',
								borderRadius: '3px',
								boxShadow: '1px 1px 0px 0px #000000',
								width: '98%',
								margin: '5px'
							}}
						>
							<h5
								style={{
									fontWeight: '800',
									color: '#000',
									textAlign: 'left',
									fontSize: '12px'
								}}
							>
								Cleaning Line
							</h5>

							<p style={{ textAlign: 'left', fontSize: '12px' }}>
								{levelsList.levels.find((asset: Asset) => {
									return asset._id === props.alert.asset_id
								}) !== undefined
									? levelsList.levels.find((asset: Asset) => {
											return asset._id === props.alert.asset_id
										}).asset_alias ||
										levelsList.levels.find((asset: Asset) => {
											return asset._id === props.alert.asset_id
										}).asset_name
									: 'Asset Not Found'}
							</p>
						</div>

						<div
							style={{
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
								color: 'black',
								padding: '12px',
								borderRadius: '3px',
								boxShadow: '1px 1px 0px 0px #000000',
								width: '98%',
								margin: '5px'
							}}
						>
							<h5
								style={{
									fontWeight: '800',
									color: '#000',
									textAlign: 'left',
									fontSize: '12px'
								}}
							>
								Alert Type
							</h5>

							<p style={{ textAlign: 'left', fontSize: '12px' }}>
								{props.alert.message_data.CurrentValue <
								props.alert.message_data.LowerThreshold
									? 'Critical'
									: 'Informational'}
							</p>
						</div>
					</div>

					<div
						style={{
							backgroundColor: 'rgba(255, 255, 255, 0.7)',
							color: 'black',
							padding: '12px',
							borderRadius: '3px',
							boxShadow: '1px 1px 0px 0px #000000',
							width: '98%',
							margin: '5px'
						}}
					>
						<h5
							style={{
								fontWeight: '800',
								color: '#000',
								fontSize: '12px'
							}}
						>
							Description
						</h5>

						<p>
							At{' '}
							{getFormatedDate(
								plantsList.plants.find((plant: any) => {
									return plant._id === props.alert.plant_id
								})?.plant_timezone,
								getTimeZoneTimeStamp(
									plantsList.plants.find((plant: any) => {
										return plant._id === props.alert.plant_id
									})?.plant_timezone,
									props.alert.timestamp
								),
								'YYYY-MM-DD hh:mm A z'
							)}
							{', '}
							{props.alert.message_data.CurrentValue <
							props.alert.message_data.LowerThreshold
								? `the '${props.alert.parameter}' parameter went below threshold.`
								: props.alert.message_data.CurrentValue >
									  props.alert.message_data.UpperThreshold
									? `the '${props.alert.parameter}' parameter went above threshold.`
									: `the '${props.alert.parameter}' parameter is back in range.`}
						</p>
						<p>Current Value: {props.alert.message_data.CurrentValue}</p>
						<p>Lower Threshold: {props.alert.message_data.LowerThreshold}</p>
						<p>Upper Threshold: {props.alert.message_data.UpperThreshold}</p>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								props.handleClose()
							}}
							type='button'
						>
							Close
						</button>

						<button
							style={{
								color: 'black',
								backgroundColor: 'white',
								marginLeft: '2em',
								margin: '20px 5px',
								width: '45%',
								padding: '8px',
								borderRadius: '8px',
								fontWeight: 'bold'
							}}
							onClick={() => {
								props.setOpen(false)
								navigate(`/aseptic-cip/wash-alert/${props.alert.message_id}`, {
									replace: true
								})
							}}
							disabled={ticketsList.loading || messagesList.loading}
							type='button'
						>
							{(ticketsList.loading || messagesList.loading) && (
								<CircularProgress
									sx={{
										color: 'black',
										height: '1px',
										margin: '0px 20px 0px 10px'
									}}
									size={18}
								/>
							)}
							Go to Wash Details
						</button>
					</div>
				</div>
			</Fade>
		</Modal>
	)
}

export default AsepticCCPAlert
