import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import InfoIcon from '@mui/icons-material/Info'
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useDispatch, useSelector } from 'react-redux'
import { env } from '../../../../env'
import uploadFileToBlob from '../../../../Services/azure-storage-blob'
import { loadSnackbar } from '../../../../Utils/util'
import Userbox from './Userbox'

const validFileFormats = [
	'text/plain',
	'text/csv',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	// Added Video upload support for ticket closing
	'video/mp4',
	'video/quicktime',
	'video/mpeg',
	// Added image upload support for downtime tickets
	'image/png',
	'image/jpg',
	'image/jpeg'
]

const fileSizeLimit = 20 // size in MB

export const defaultTheme: Theme = createTheme({})

const appMode = localStorage.getItem('appMode')

const muiRteTheme: any = {
	overrides: {
		MUIRichTextEditor: {
			root: {
				width: '100%',
				borderRadius: '12px',
				border: '1px solid #1245A8',

				'& .MuiPaper-root': {
					background: appMode === 'Dark' ? '#293368' : ''
				}
			},
			container: {
				display: 'flex',
				flexDirection: 'column',
				height: '200px',
				maxHeight: '200px'
			},
			editor: {
				padding: '18px',
				height: '160px',
				maxHeight: '200px',
				overflow: 'auto',
				marginTop: '0px',
				marginBottom: '5px'
			},
			toolbar: {
				margin: '5px'
			},
			placeHolder: {
				paddingLeft: 20,
				margin: '0px',
				padding: '2px 0px 8px 0px'
			},
			anchorLink: {
				color: '#333333',
				textDecoration: 'underline'
			}
		}
	}
}

Object.assign(defaultTheme, muiRteTheme)

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: '95%',
		backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',

		[theme.breakpoints.down('sm')]: {
			padding: '0 10px'
		}
	},
	email_container: {
		border: '1px solid black',
		borderRadius: '8px'
	},
	header_container: {
		textAlign: 'center',
		padding: '20px'
	},
	header: {
		fontSize: '24px',
		fontWeight: 600,
		color: 'white',
		padding: '10px 0px'
	},
	sub_header: {
		fontSize: '18px',
		fontWeight: 600,
		color: 'white'
	},
	body_container: {
		padding: '10px',
		fontSize: '14px'
	},
	salutation: {
		marginBottom: '50px'
	},
	greetings: {
		marginTop: '50px'
	},
	titlePadding: {
		paddingTop: '15px'
	},
	boxPadding: {
		padding: '1%',
		[theme.breakpoints.down('lg')]: {
			padding: '3%'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '5%'
		}
	},
	option: {
		backgroundColor: appMode === 'Dark' ? '#293368' : ''
	},
	checkBoxMargin: {
		'&.MuiCheckbox-root': {
			marginRight: 8
		}
	},
	gridContainer: {
		margin: '20px 0px 10px',
		padding: '20px'
	},
	bgColor: {
		color: appMode === 'Dark' ? '#293368' : ''
	},
	buttonContainer: {
		width: '100%',
		textAlign: 'right'
	},
	demoWrapper: {
		'&.rdw-suggestion-dropdown': {
			background: 'none !important'
		}
	},
	demoWapperAcceptable: {
		'&.rdw-editor-wrapper': {
			border: '2px solid #77C190 !important'
		}
	},
	demoWapperDanger: {
		'&.rdw-editor-wrapper': {
			border: '2px solid #FF0000 !important'
		}
	},
	demoWapperWarning: {
		'&.rdw-editor-wrapper': {
			border: '2px solid #FFFF00 !important'
		}
	},
	demoToolbarCustom: {
		background: theme.palette.mode === 'dark' ? '#293368 !important' : '',
		color: theme.palette.mode === 'dark' ? '#000 !important' : '',
		border: '0px solid #1245a8 !important',
		backgroundColor: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		borderRadius: '12px 12px 0px 0px !important'
	},
	commentboxOptions: {
		background: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		border: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		'&rdw-dropdownoption-active': {
			background: theme.palette.mode === 'dark' ? '#8476EF !important' : ''
		},
		'&rdw-dropdownoption-highlighted': {
			background: theme.palette.mode === 'dark' ? '#8476EF !important' : ''
		}
	},
	commentboxDropdownOptions: {
		background: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		border: theme.palette.mode === 'dark' ? '#232a58 !important' : '',
		backgroundColor: '#232a58',
		scrollBarWidth: 'thin',
		scrollbarColor: '#232A5830',
		'&::-webkit-scrollbar, & *::-webkit-scrollbar': {
			backgroundColor: '#232A5830'
		},
		'&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
			borderRadius: 10,
			backgroundColor: '#29336880',
			minHeight: 24
			// border: "3px solid #2b2b2b",
		}
	}
}))

function RichTextEditor(props: any) {
	const classes = useStyles()
	const usersList = useSelector((state: any) => state.usersList)
	const users = usersList.users

	const [loading, setLoading] = useState<boolean>(false)
	const [value, setValue] = useState<string>('')
	const [rawValue, setRawValue] = useState<any>({})
	const [tempEditor, setTempEditor] = useState(
		EditorState.moveFocusToEnd(
			EditorState.createWithContent(
				ContentState.createFromText(
					props?.text?.item?.text?.blocks[0]?.text || ''
				)
			)
		)
	)
	const dispatch = useDispatch()
	const [lines, setLines] = useState(0)
	const editorRef = useRef<any>(null)

	const onEditorChange = (editorState: any) => {
		setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())))
		setRawValue(convertToRaw(editorState.getCurrentContent()))
		setTempEditor(editorState)
	}

	const handleEditorChange = function (e: any) {
		const prevLines = lines
		if (prevLines !== e.blocks.length) {
			setLines(e.blocks.length)
		}
	}

	useEffect(() => {
		if (editorRef.current) {
			const scrollDiv = editorRef.current?.querySelector('.rdw-editor-main')
			scrollDiv.scrollTop = scrollDiv?.scrollHeight
		}
	}, [lines])

	const sendMentionNotification = (
		mentions: Array<{ username: string; email: string }>
	) => {
		const parser = new DOMParser()
		const htmlDoc = parser.parseFromString(value, 'text/html')
		axios.post(env.LogicApp_CommentMention, {
			mentionFrom: usersList.currentUser.user_ad_name,
			mentions: mentions,
			linkToTicket: window.location.href,
			comment: value,
			teamsComment: htmlDoc.body.innerText,
			attachments: []
		})
	}
	const postComment = () => {
		const mentionedUsers = Object.values(
			convertToRaw(tempEditor.getCurrentContent()).entityMap
		)
			.filter((ele: any) => {
				return ele.type === 'MENTION'
			})
			.map((ele: any) => {
				return ele.data.value
			})

		const mentionedToEmails = mentionedUsers
			.map((username: string) => {
				return usersList.users.find((user: any) => {
					return user.user_ad_name === username
				})?.mail
			})
			.filter(ele => {
				return ele !== undefined
			})
		const postComment = props.handleSubmit(rawValue)
		postComment
			?.then(() => {
				//props.setCheckTicMsgStatus(true);
				// onChangeCommentBoxValue();
				setValue('')
				setRawValue({})
				if (mentionedToEmails.length > 0) {
					const alreadyMentioned = [String]
					const mentions = []
					for (let i = 0; i < mentionedToEmails.length; i++) {
						const currEmail = mentionedToEmails[i]
						if (alreadyMentioned.includes(currEmail)) {
							continue
						}
						alreadyMentioned.push(mentionedToEmails[i])
						mentions.push({
							username: mentionedUsers[i],
							email: mentionedToEmails[i]
						})
					}
					sendMentionNotification(mentions)
				}
			})
			.catch(() => {
				dispatch(loadSnackbar())
			})

		setTempEditor(EditorState.createEmpty())
	}

	const uploadImage = async (file: File) => {
		try {
			if (file.type.split('/')[0] !== 'image') {
				dispatch(loadSnackbar('File format not supprted. Please try again'))
			} else {
				const url = await uploadFileToBlob(file)
				if (!url) return
				return { data: { link: url } }
			}
		} catch (error: any) {
			dispatch(loadSnackbar())
		}
	}

	const uploadFile = async (event: any) => {
		const file = event.target.files[0]
		event.target.value = null
		setLoading(true)
		try {
			if (
				validFileFormats.includes(file.type) &&
				file.size <= fileSizeLimit * 1000000
			) {
				const url = await uploadFileToBlob(file)
				if (!url) return
				let selection = tempEditor.getSelection()
				const entityKey = tempEditor
					.getCurrentContent()
					.createEntity('LINK', 'IMMUTABLE', {
						url: url,
						targetOption: '_blank'
					})
					.getLastCreatedEntityKey()
				let contentState = Modifier.replaceText(
					tempEditor.getCurrentContent(),
					tempEditor.getSelection(),
					`${file.name}`,
					tempEditor.getCurrentInlineStyle(),
					entityKey
				)
				let newEditorState = EditorState.push(
					tempEditor,
					contentState,
					'insert-characters'
				)

				//Insert space after link
				selection = newEditorState.getSelection().merge({
					anchorOffset: selection.get('anchorOffset') + file.name.length,
					focusOffset: selection.get('anchorOffset') + file.name.length
				})
				newEditorState = EditorState.acceptSelection(newEditorState, selection)
				contentState = Modifier.insertText(
					newEditorState.getCurrentContent(),
					selection,
					' ',
					newEditorState.getCurrentInlineStyle(),
					undefined
				)

				onEditorChange(newEditorState)
				setTempEditor(EditorState.createWithContent(contentState))
				setLoading(false)
			} else {
				setLoading(false)
				const message =
					file.size > fileSizeLimit * 1000000
						? `File size not supported. Max file size allowed is ${fileSizeLimit} MB.`
						: 'File format not supported. Only PDF, PowerPoint, Excel, Word, Text allowed.'
				dispatch(loadSnackbar(message))
			}
		} catch (error: any) {
			dispatch(loadSnackbar())
		}
	}

	const commentFileInput = useRef<HTMLInputElement>(null)
	const handleAttachClick = () => {
		commentFileInput?.current?.click()
	}

	let EditorBoxBorderColor = ''
	if (props.editorType === 'plans') {
		if (props.priority === 'acceptable') {
			EditorBoxBorderColor = classes.demoWapperAcceptable
		} else if (props.priority === 'danger') {
			EditorBoxBorderColor = classes.demoWapperDanger
		} else {
			EditorBoxBorderColor = classes.demoWapperWarning
		}
	}

	let editorOptions = [
		'inline',
		'blockType',
		'fontSize',
		'fontFamily',
		'list',
		'textAlign',
		'colorPicker',
		'link',
		'remove',
		'history'
	]
	//remove image upload from plan section
	if (props.editorType === 'closeTicket') {
		editorOptions = [
			'inline',
			'blockType',
			'fontSize',
			'fontFamily',
			'list',
			'textAlign',
			'colorPicker',
			'link',
			'image',
			'remove',
			'history'
		]
	}
	return (
		<>
			<Grid
				container
				spacing={1}
				className={classes.gridContainer}
				justifyContent='flex-end'
			>
				<Grid item xs={12} sm={12}>
					<ThemeProvider theme={defaultTheme}>
						<div className='row' ref={editorRef}>
							<Editor
								editorState={tempEditor}
								wrapperClassName={`${classes.demoWrapper} ${EditorBoxBorderColor}`}
								editorClassName='demo-editor'
								toolbarClassName={classes.demoToolbarCustom}
								onEditorStateChange={onEditorChange}
								onChange={e => handleEditorChange(e)}
								toolbar={{
									options: editorOptions,
									inline: {
										inDropdown: false,
										className: classes.commentboxOptions,
										component: undefined,
										dropdownClassName: classes.commentboxDropdownOptions,
										options: [
											'bold',
											'italic',
											'underline',
											'strikethrough',
											'superscript',
											'subscript'
										],
										bold: { className: classes.commentboxOptions },
										italic: { className: classes.commentboxOptions },
										underline: { className: classes.commentboxOptions },
										strikethrough: { className: classes.commentboxOptions },
										monospace: { className: classes.commentboxOptions },
										superscript: { className: classes.commentboxOptions },
										subscript: { className: classes.commentboxOptions }
									},
									blockType: {
										inDropdown: true,
										options: [
											'Normal',
											'H1',
											'H2',
											'H3',
											'H4',
											'H5',
											'H6',
											'Blockquote',
											'Code'
										],
										className: classes.commentboxOptions,
										component: undefined,
										dropdownClassName: classes.commentboxDropdownOptions
									},
									fontSize: {
										options: [
											8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96
										],
										className: classes.commentboxOptions,
										component: undefined,
										dropdownClassName: classes.commentboxDropdownOptions
									},
									fontFamily: {
										options: [
											'Arial',
											'Georgia',
											'Impact',
											'Tahoma',
											'Times New Roman',
											'Verdana'
										],
										className: classes.commentboxOptions,
										component: undefined,
										dropdownClassName: classes.commentboxDropdownOptions
									},
									list: {
										inDropdown: false,
										className: classes.commentboxOptions,
										component: undefined,
										dropdownClassName: classes.commentboxDropdownOptions,
										options: ['unordered', 'ordered', 'indent', 'outdent'],
										unordered: { className: classes.commentboxOptions },
										ordered: { className: classes.commentboxOptions },
										indent: { className: classes.commentboxOptions },
										outdent: { className: classes.commentboxOptions }
									},
									textAlign: {
										inDropdown: false,
										className: classes.commentboxOptions,
										component: undefined,
										dropdownClassName: classes.commentboxDropdownOptions,
										options: ['left', 'center', 'right', 'justify'],
										left: { className: classes.commentboxOptions },
										center: { className: classes.commentboxOptions },
										right: { className: classes.commentboxOptions },
										justify: { className: classes.commentboxOptions }
									},
									colorPicker: {
										className: classes.commentboxOptions,
										component: undefined,
										popupClassName: undefined
									},
									link: {
										inDropdown: false,
										className: classes.commentboxOptions,
										component: undefined,
										popupClassName: classes.commentboxOptions,
										dropdownClassName: classes.commentboxOptions,
										showOpenOptionOnHover: true,
										defaultTargetOption: '_self',
										options: ['link', 'unlink'],
										link: { className: classes.commentboxOptions },
										unlink: { className: classes.commentboxOptions },
										linkCallback: undefined
									},
									emoji: {
										className: classes.commentboxOptions,
										component: undefined,
										popupClassName: undefined
									},
									embedded: {
										className: classes.commentboxOptions,
										component: undefined,
										popupClassName: classes.commentboxOptions,
										embedCallback: undefined,
										defaultSize: {
											height: 'auto',
											width: 'auto'
										}
									},
									remove: {
										className: classes.commentboxOptions,
										component: undefined
									},
									history: {
										inDropdown: false,
										className: classes.commentboxOptions,
										component: undefined,
										dropdownClassName: classes.commentboxDropdownOptions,
										options: ['undo', 'redo'],
										undo: { className: classes.commentboxOptions },
										redo: { className: classes.commentboxOptions }
									},
									image: {
										className: classes.commentboxOptions,
										component: undefined,
										popupClassName: classes.commentboxOptions,
										urlEnabled: true,
										uploadEnabled: true,
										uploadCallback: uploadImage,
										alt: { present: false, mandatory: false },
										previewImage: true,
										inputAccept:
											'image/gif,image/jpeg,image/jpg,image/png,image/svg',
										defaultSize: {
											height: 'auto',
											width: '200px'
										}
									}
								}}
								mention={
									props.editorType === 'closeTicket'
										? {
												separator: ' ',
												trigger: '@',
												suggestions: users
													.sort((a: any, b: any) =>
														a.user_ad_name.toLowerCase() >
														b.user_ad_name.toLowerCase()
															? 1
															: b.user_ad_name.toLowerCase() >
																  a.user_ad_name.toLowerCase()
																? -1
																: 0
													)
													.map((user: any) => {
														return {
															text: (
																<Userbox
																	person={user}
																	tempEditor={null}
																	onEditorChange={null}
																	setTempEditor={null}
																/>
															),
															value: user.user_ad_name,
															url: `mailto:${user.mail}`
														}
													})
											}
										: {}
								}
							/>
						</div>
					</ThemeProvider>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					style={{ marginBottom: '15px', textAlign: 'end' }}
					justifyContent={
						props.editorType === 'closeTicket' ? 'space-between' : 'flex-end'
					}
					display='flex'
				>
					{props.editorType === 'closeTicket' && (
						<>
							<Button
								sx={{
									mt: { xs: 2, md: 0 }
								}}
								variant='contained'
								onClick={handleAttachClick}
								data-testid='attach-file-button'
								disabled={loading}
							>
								{loading && (
									<CircularProgress
										sx={{
											color: 'white',
											height: '1px',
											marginRight: '10px'
										}}
										size={18}
									/>
								)}
								Attach File
							</Button>
							<Typography
								style={{
									fontWeight: '900',
									color: appMode === 'Dark' ? 'text.secondary' : 'black',
									margin: '0px 0px 0px -200px',
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<InfoIcon style={{ margin: '0px 5px', cursor: 'unset' }} />
								It is advisable to attach supporting files.
							</Typography>

							<input
								type='file'
								ref={commentFileInput}
								onChange={uploadFile}
								style={{ display: 'none' }}
								accept='.mov,.mpeg,.mp4,.pdf,.ppt,.pptx,.txt,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.doc,.docx,application/msword,video/*)'
							/>
						</>
					)}
					<Button
						sx={{
							mt: { xs: 2, md: 0 },

							cursor: !tempEditor.getCurrentContent().hasText()
								? 'not-allowed'
								: 'pointer'
						}}
						disabled={
							(!tempEditor.getCurrentContent().hasText() ||
								!tempEditor.getCurrentContent().getPlainText().trim() ||
								loading) &&
							!(
								props?.usecase === 'downtime-request' &&
								tempEditor.getCurrentContent().hasText()
							)
						}
						variant='contained'
						onClick={postComment}
						data-testid='post-comment-button'
					>
						POST
					</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default RichTextEditor
