import { concat } from "lodash";
import { call, put, takeEvery } from "redux-saga/effects";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import {
  DELETE_LEVEL,
  DELETE_LEVEL_SUCCESS,
  LOAD_LEVELS,
  LOAD_LEVELS_ERROR,
  LOAD_LEVELS_SUCCESS,
  UPDATE_LEVEL,
  UPDATE_LEVEL_SUCCESS,
} from "../Types/levels.constants";
import store from "../store";

const PAGE_SIZE = 2000;
const fetchPage = async (page: any) => {
  try {
    const res = await axios.get(
      `assets/getAllAssets?pageSize=${PAGE_SIZE}&page=${page}`
    );
    return res.data.rows;
  } catch (error) {
    return [];
  }
};

const getTotalPages = async () => {
  try {
    const res = await axios.get("assets");
    return res.data.total;
  } catch (error) {
    return [];
  }
};

// const getAssetsByRole = async (fetchKey: any, fetchValue: any) => {
//   try {
//     let page = 1;
//     let res = null;
//     let ResponseMessage: any = [];
//     let reqURL: any = "assets/fileredAssetsByRole";
//     let payload: any = {
//       pageSize: PAGE_SIZE,
//     };
//     do {
//       payload = { ...payload, [fetchKey]: fetchValue, page: page++ };
//       res = await axios.post(reqURL, payload);
//       ResponseMessage = ResponseMessage.concat(res.data.rows);
//     } while (res.data.page < res.data.totalPages);
//     return ResponseMessage;
//   } catch (error) {
//     return [];
//   }
// };
const getAssetsByRole = async (role: any) => {
  const userAccessResponse = store.getState().userAccess;
  try {
    let page = 1;
    let res = null;
    let ResponseMessage: any = [];
    let uniqueUsecases: any = [];
    const reqURL: any = "assets/fileredAssetsByRole";
    let payload: any = {
      pageSize: PAGE_SIZE,
    };
    const userAccess = userAccessResponse?.userAccess;
    const UserprofileAccess = userAccess
      ? userAccess[0]?.profile_based_access
      : null;
    if (!UserprofileAccess?.isAdminRole) {
      if (role === "Asset_Manager") {
        const accessible_asset_plants =
          UserprofileAccess?.asset_access?.accessible_asset_plants?.filter(
            (e: any) => !UserprofileAccess?.plant_access?.includes(e)
          );
        if (accessible_asset_plants?.length > 0) {
          payload["accessibleAssets"] = UserprofileAccess?.asset_access?.assets;
          // let tempArr = [
          //   ...UserprofileAccess?.asset_access?.accessible_asset_plants,
          //   ...UserprofileAccess?.plant_access,
          // ];
          //[...new Set(tempArr)]
          payload["accessiblePlantsArray"] = accessible_asset_plants;
          if (UserprofileAccess?.usecase_access?.length > 0) {
            payload["usecaseIds"] = UserprofileAccess?.usecase_access;
          }
        }
      }
      if (role === "Plant_Manager") {
        payload["accessiblePlantsArray"] = UserprofileAccess?.plant_access;
        if (UserprofileAccess?.usecase_access?.length > 0) {
          payload["usecaseIds"] = UserprofileAccess?.usecase_access;
        }
      }
      if (role === "Usecase_Champion") {
        payload["usecaseIds"] = UserprofileAccess?.usecase_access;
      }
    }
    do {
      payload = { ...payload, page: page++ };
      res = await axios.post(reqURL, payload);
      uniqueUsecases = uniqueUsecases.concat(res.data.uniqueUsecaseIds);
      ResponseMessage = ResponseMessage.concat(res.data.rows);
    } while (res.data.page < res.data.totalPages);
    return { ResponseMessage, uniqueUsecases };
  } catch (error) {
    return [];
  }
};

const getAllAssets = async () => {
  try {
    let assets: any = [];
    const totalRecords = await getTotalPages();
    const totalPages = Math.ceil(totalRecords / PAGE_SIZE);

    // Create an array of page numbers from 1 to totalPages
    const pageNumbers = Array.from(
      { length: totalPages },
      (data: any, i: any) => i + 1
    );

    // Use Promise.all to fetch all pages concurrently
    const results = await Promise.all(pageNumbers.map(fetchPage));
    // Concatenate the results from all pages
    assets = results.reduce((acc: any, rows: any) => acc.concat(rows), []);
    return assets;
  } catch (error) {
    return [];
  }
};

//function to get all levels from the api
const getlevels = async () => {
  try {
    let levels: any = [];
    let uniqueUsecaseCollection: any = [];
    // let res = null,
    //   page = 1;

    // do {
    //   res = await axios.get(`assets?pageSize=500&page=${page++}`);
    //   levels = levels.concat(res.data.rows);
    // } while (res.data.page < res.data.totalPages);
    const usersList = store.getState().usersList;
    const userAccessResponse = store.getState().userAccess;
    const userAccess = userAccessResponse?.userAccess;
    const UserprofileAccess = userAccess
      ? userAccess[0]?.profile_based_access
      : null;
    if (
      usersList.currentUserPermissions.includes("ARCH_ADMIN") ||
      usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER") ||
      usersList.currentUserPermissions.includes("ARCH_DEVELOPER")
    ) {
      levels = await getAllAssets();
    } else {
      if (UserprofileAccess?.asset_access?.assets?.length > 0) {
        const assetResponse: any = await getAssetsByRole("Asset_Manager");
        levels = levels.concat(assetResponse.ResponseMessage);
        uniqueUsecaseCollection = concat(assetResponse.uniqueUsecases);
      }
      if (UserprofileAccess?.plant_access?.length > 0) {
        const assetResponse: any = await getAssetsByRole("Plant_Manager");
        levels = levels.concat(assetResponse.ResponseMessage);
        uniqueUsecaseCollection = concat(assetResponse.uniqueUsecases);
      }
      if (
        UserprofileAccess?.usecase_access?.length > 0 &&
        UserprofileAccess?.asset_access?.assets?.length === 0 &&
        UserprofileAccess?.plant_access?.length === 0
      ) {
        const assetResponse: any = await getAssetsByRole("Usecase_Champion");
        levels = levels.concat(assetResponse.ResponseMessage);
        uniqueUsecaseCollection = concat(assetResponse.uniqueUsecases);
      }
      // let usecaseRoles = usersList.currentUserPermissions.filter((ele: any) => {
      //   return ele.includes("ARCH_USECASE_");
      // });
      // const plantRoles = usersList.currentUserPermissions.filter((ele: any) => {
      //   return ele.includes("ARCH_PM_");
      // });
      //Plant manager role
      // if (plantRoles.length > 0) {
      //   const accessiblePlantsArray = plantRoles
      //     .map((ele: any) => {
      //       return ele.substring(8);
      //     })
      //     .map((plantCode: any) => {
      //       return plantsList.plants.find((plant: any) => {
      //         return plant.plant_code === plantCode;
      //       });
      //     })
      //     .map((plant: any) => plant._id);
      //   let assetsReponse = await getAssetsByRole(
      //     "accessiblePlantsArray",
      //     accessiblePlantsArray
      //   );
      //   levels = levels.concat(assetsReponse);
      // }
      //Asset Manager Role
      // if (usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")) {
      //   const accessibleAssets = usersList.currentUser.allowed_assets;
      //   let assetsReponse = await getAssetsByRole(
      //     "accessibleAssets",
      //     accessibleAssets
      //   );
      //   levels = levels.concat(assetsReponse);
      // }
      //Usecase champion role
      // if (usecaseRoles.length > 0) {
      //   let usecaseCodeName = usecaseRoles.map((usecaseName: any) => {
      //     return usecaseName.substring(13);
      //   });
      //   let usecaseIds = usecaseList.usecases
      //     .filter((usecaseData: any) => {
      //       if (usecaseCodeName.includes(usecaseData.level_use_code)) {
      //         return usecaseData.litmus_use_code;
      //       }
      //     })
      //     .map((data: any) => data._id);
      //   if (
      //     (plantRoles.length > 0 ||
      //       usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")) &&
      //     !usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
      //   ) {
      //     levels = levels.filter((asset: any) => {
      //       return usecaseIds.some((data: any) =>
      //         asset.use_case.includes(data)
      //       );
      //     });
      //   } else if (
      //     !usersList.currentUserPermissions.includes("ARCH_USECASE_ALL") &&
      //     plantRoles.length == 0 &&
      //     !usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")
      //   ) {
      //     let assetsReponse = await getAssetsByRole("usecaseIds", usecaseIds);
      //     levels = levels.concat(assetsReponse);
      //   } else if (
      //     usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
      //   ) {
      //     levels = await getAllAssets();
      //   }
      // }
    }
    return { levels, uniqueUsecaseCollection };

    // const plantsList = useSelector((state: any) => state.plantsList);
    // if (
    //   usersList.currentUserPermissions.includes("ARCH_ADMIN") ||
    //   usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER")
    // ) {
    //   return levels;
    // } else {
    //   let filteredUseCaseAssets: any = [];
    //   let usecaseRoles = usersList.currentUserPermissions.filter((ele: any) => {
    //     return ele.includes("ARCH_USECASE_");
    //   });
    //   if (usecaseRoles.length > 0) {
    //     if (usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")) {
    //       filteredUseCaseAssets = [...levels];
    //     } else {
    //       let usecaseCodeName = usecaseRoles.map((ele: any) => {
    //         return ele.substring(13);
    //       });
    //       let usecaseCode = usecaseList.usecases.filter((data: any) => {
    //         if (usecaseCodeName.includes(data.level_use_code)) {
    //           return true;
    //         }
    //       });
    //       let usecaseCodeID = usecaseCode.map((ele: any) => ele._id);
    //       filteredUseCaseAssets = levels.filter((asset: any) => {
    //         return usecaseCodeID.some((data: any) =>
    //           asset.use_case.includes(data)
    //         );
    //       });
    //     }
    //     if (
    //       !usersList.currentUserPermissions.some((ele: any) => {
    //         return ele.includes("ARCH_PM_");
    //       }) &&
    //       !usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")
    //     ) {
    //       return filteredUseCaseAssets;
    //     }
    //   } else {
    //     filteredUseCaseAssets = levels;
    //   }
    //   const plantRoles = usersList.currentUserPermissions.filter((ele: any) => {
    //     return ele.includes("ARCH_PM_");
    //   });
    //   const accessiblePlantsArray = plantRoles
    //     .map((ele: any) => {
    //       return ele.substring(8);
    //     })
    //     .map((plantCode: any) => {
    //       return plantsList.plants.find((plant: any) => {
    //         return plant.plant_code === plantCode;
    //       });
    //     });
    //   const accessible_assets = usersList.currentUser.accessible_assets
    //     .map((ele: any) => {
    //       return ele.assets.concat(ele.parent_asset);
    //     })
    //     .flat()
    //     .filter(function (value: any, index: any, array: any) {
    //       return array.indexOf(value) === index;
    //     });
    //   let filteredAssets = filteredUseCaseAssets.filter((asset: any) => {
    //     return (
    //       accessiblePlantsArray
    //         .map((plant: any) => {
    //           return plant._id;
    //         })
    //         .indexOf(asset.plant_id) !== -1 ||
    //       accessible_assets.indexOf(asset._id) !== -1
    //     );
    //   });
    //   return filteredAssets;
    // }
  } catch (error) {
    return [];
  }
};

export function* listLevels(): any {
  try {
    const data = yield call(() =>
      getlevels().then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_LEVELS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: LOAD_LEVELS_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export function* updateLevel(action: any): any {
  try {
    yield put({
      type: UPDATE_LEVEL_SUCCESS,
      payload: action.payload,
    });
  } catch (error: any) {
    yield put({
      type: LOAD_LEVELS_ERROR,
      payload: null,
    });
  }
}

export function* deleteLevel(action: any): any {
  try {
    yield put({
      type: DELETE_LEVEL_SUCCESS,
      payload: action.payload,
    });
  } catch (error: any) {
    yield put({
      type: LOAD_LEVELS_ERROR,
      payload: null,
    });
  }
}
export function* listLevelsSaga() {
  yield takeEvery(LOAD_LEVELS, listLevels);
  yield takeEvery(UPDATE_LEVEL, updateLevel);
  yield takeEvery(DELETE_LEVEL, deleteLevel);
}
