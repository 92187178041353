import { Helmet } from "react-helmet-async";
import UtilitiesLandingPage from "./UtilitiesLandingPage";

function UtilitiesDashbord() {
  return (
    <div>
      <Helmet>
        <title>Utilities Dashboard</title>
      </Helmet>
      <UtilitiesLandingPage />
    </div>
  );
}

export default UtilitiesDashbord;
