import {
	LOAD_SEEDS,
	LOAD_SEEDS_ERROR,
	LOAD_SEEDS_SUCCESS,
	UPDATE_ASEPTIC_RECIPE_SEED_DATA,
	UPDATE_ASEPTIC_RECIPE_SEED_DATA_ERROR,
	UPDATE_ASEPTIC_RECIPE_SEED_DATA_SUCCESS
} from '../Types/seeds.constants'

// import axios from "axios";
import { call, put, takeEvery } from 'redux-saga/effects'
import axios from '../../Utils/AxiosConfig/axiosConfigMonarch'

//function to get all plants from the api
const getSeeds = async () => {
	let seeds: any = []
	let res = null
	//loop to fetch all pages from api
	res = await axios.get(`seedconfigs`)
	seeds = res.data
	return seeds
}

const updateAspeticRecipeMappingSeed = async (payload: any) => {
	let seeds: any = []
	
	await axios.put('seedconfig/64cd3216dc9aba0045c0dbd0', {
		seed_data_value: payload
	})
	seeds = payload
	return seeds
}

export function* listSeeds(): any {
	try {
		const data = yield call(() =>
			getSeeds().then(res => {
				return res
			})
		)
		yield put({
			type: LOAD_SEEDS_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: LOAD_SEEDS_ERROR,
			payload: null
		})
	}
}

export function* updateRecipeSeed(action: any): any {
	try {
		const data = yield call(() =>
			updateAspeticRecipeMappingSeed(action.payload).then(res => {
				return res
			})
		)
		yield put({
			type: UPDATE_ASEPTIC_RECIPE_SEED_DATA_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: UPDATE_ASEPTIC_RECIPE_SEED_DATA_ERROR,
			payload: null
		})
	}
}

export function* listSeedsSaga() {
	yield takeEvery(LOAD_SEEDS, listSeeds)
	yield takeEvery(UPDATE_ASEPTIC_RECIPE_SEED_DATA, updateRecipeSeed)
}
