export const VIEW_ASSET = "VIEW_ASSET";
export const VIEW_ASSET_SUCCESS = "VIEW_ASSET_SUCCESS";
export const VIEW_ASSET_ERROR = "VIEW_ASSET_ERROR";
export const SCHEDULER_CURRENT_DATE = "SCHEDULER_CURRENT_DATE";
export const SCHEDULER_CURRENT_DATE_SUCCESS = "SCHEDULER_CURRENT_DATE_SUCCESS";
export const SCHEDULER_CURRENT_DATE_ERROR = "SCHEDULER_CURRENT_DATE_ERROR";
export const SCHEDULER_CURRENT_VIEW = "SCHEDULER_CURRENT_VIEW";
export const SCHEDULER_CURRENT_VIEW_SUCCESS = "SCHEDULER_CURRENT_VIEW_SUCCESS";
export const SCHEDULER_CURRENT_VIEW_ERROR = "SCHEDULER_CURRENT_VIEW_ERROR";
export const SCHEDULER_FILTER_DATA = "SCHEDULER_FILTER_DATA";
export const SCHEDULER_FILTER_DATA_SUCCESS = "SCHEDULER_FILTER_DATA_SUCCESS";
export const SCHEDULER_FILTER_DATA_ERROR = "SCHEDULER_FILTER_DATA_ERROR";
