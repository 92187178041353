import Snackbar from '@mui/material/Snackbar'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import Alert from './Alert'

interface CustomizedSnackbarsProps {
	type: string
	message: string
	vertical?: string
	horizontal?: string
	open: boolean
}

const CustomizedSnackbars: React.FC<CustomizedSnackbarsProps> = (
	props: any
) => {
	const dispatch = useDispatch()

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return
		}
		dispatch({ type: 'SNACKBAR_CLOSE' })
	}

	return (
		<Snackbar
			open={props.open}
			autoHideDuration={3000}
			onClose={handleClose}
			anchorOrigin={{ vertical: props.vertical, horizontal: props.horizontal }}
		>
			<Alert onClose={handleClose} severity={props.type} sx={{ width: '100%' }}>
				{props.message}
			</Alert>
		</Snackbar>
	)
}

CustomizedSnackbars.defaultProps = {
	vertical: 'top',
	horizontal: 'center'
}

export default CustomizedSnackbars
