import {
	LOAD_HEAT_MAP_DETAILS,
	LOAD_HISTORICAL_DATA,
	LOAD_LGV_LIVE_DATA_ERROR,
	LOAD_LGV_LIVE_DATA_SUCCESS,
	LOAD_PLS_LIVE_DATA_SUCCESS,
	LOAD_PLS_LIVE_DATA_ERROR
} from '../Types/lgvHealth.constants'

const INITIAL_STATE = {
	loading: false,
	plantHealthDetails: {},
	historicalData: [],
	liveData: [],
	plsData: [],
	error: null
}

export const lgvHealthReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case LOAD_HEAT_MAP_DETAILS:
			return {
				...state,
				plantHealthDetails: action.payload.plantHealth
			}
		case LOAD_HISTORICAL_DATA:
			return {
				...state,
				historicalData: action.payload.historicalData
			}
		// case LOAD_LIVE_DATA:
		// 	return {
		// 		...state,
		// 		liveData: action.payload
		// 	}
		case LOAD_LGV_LIVE_DATA_SUCCESS:
			return {
				...state,
				liveData: action.payload
			}
		case LOAD_LGV_LIVE_DATA_ERROR:
			return {
				...state,
				liveData: [],
				error: action.payload
			}
		case LOAD_PLS_LIVE_DATA_SUCCESS:
			return {
				...state,
				plsData: action.payload
			}
		case LOAD_PLS_LIVE_DATA_ERROR:
			return {
				...state,
				plsData: [],
				error: action.payload
			}
		default:
			return state
	}
}

