const formatNumberWithCommas = (number: any) => {
  // Convert the number to a string
  let numStr = number.toString();

  // Split the string into parts of three characters from the right
  const parts = [];
  while (numStr.length > 0) {
    parts.unshift(numStr.slice(-3));
    numStr = numStr.slice(0, -3);
  }

  // Join the parts with commas and return
  return parts.join(",");
};

const OverviewTable = (tableData: any) => {
  return `
    <td align="left" style="padding: 10px; font-size: 14px;">
    <table border="1" style="color:#ffffff; border-collapse:collapse">
    <tr style="background-color:#414E91;">
    <th style="padding:5px 0px;">NAME</th>
      <th>TRIAL</th>
      ${tableData[0].daysData
        .map((day: any, index: any) => {
          return `<th style="width:100px;">DAY ${index + 1}</th>`;
        })
        .join("")}
      <th style="padding:0px 25px;">CONTRACTUAL TARGET</th>
      <th style="padding:0px 25px;">FINAL RESULTS</th>
      <th style="padding:0px 25px;">STATUS</th>
    </tr>
     ${tableData
       .map((row: any, index: any) => {
         return `
    <tr style="background-color:${index % 2 === 0 ? "#242C59" : "#2D376D"}">
    <td style="padding:5px 10px;">${row.name}</td>
    <td  style="padding:0px 25px;">${row.trial}</td>
    ${row.daysData
      .map((day: any) => {
        return `<td style="width:100px;text-align:center; color:
        ${
          row.contractualTarget === "n/a"
            ? `#fff`
            : (row.compareOperator === "greater" &&
                parseFloat(day) >=
                  parseFloat(row.contractualTarget.replace("%", ""))) ||
              (row.compareOperator === "smaller" &&
                parseFloat(day) <=
                  parseFloat(row.contractualTarget.replace("%", "")))
            ? `#ADFF2F`
            : `#FF0000`
        }
        
        ">
        ${
          row.trial === "Bottles" ||
          row.trial === "Cases" ||
          row.trial === "Pallets"
            ? formatNumberWithCommas(day)
            : day
        }</td>`;
      })
      .join("")}
    <td  style="padding:0px 25px;text-align:center">${
      row.contractualTarget
    }</td>
    <td style="padding:0px 25px;text-align:center;color:${
      row.status === "Pass"
        ? "#ADFF2F"
        : row.status === "Fail"
        ? "#FF0000"
        : "#fff"
    }">${row.finalResult}</td>
    <td style="text-align:center; color:${
      row.status === "Pass"
        ? "#ADFF2F"
        : row.status === "Fail"
        ? "#FF0000"
        : "#fff"
    }">${row.status}</td>
  </tr>  
    `;
       })
       .join("")}
  </table>
  </td>
  `;
};

const DailyReportTemplate = (payload: any) => {
  const template = `
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml">
          <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </head>
          <body style="margin: 0; background-color: #cccccc;">
              <center style="text-align: center; font-family: calibri !important;">
                  <table style="border-radius:20px; margin: 20px auto;width:max-content" cellpadding="0" cellspacing="0" bgcolor="white" style="border: 1px solid black;">
                      <tr>
                          <td colspan="2" align="center"><img style="border-radius:20px 20px 0px 0px"; src="https://archwebdev.blob.core.windows.net/commentbox-images-container/SATEmailHeader.png" alt="niagaralogo" width="100%" height="100px" /></td>
                      </tr>
                      <tr>
                          <td colspan="2" align="left" style="padding: 10px; font-size: 14px;">
                          <p style="padding: 2px 0px;color:#000000";>Hello,</p>                        
                              <p style="padding: 2px 0px;color:#000000";>
                                Please find the ${
                                  payload.SAT_name
                                } results below for your reference, and detailed dashboards in the attachments.</p>
                                <p style="padding: 2px 0px;color:#000000";>
              To access a more comprehensive view, please click on this <a href="${
                payload.url
              }">link</a> to view the SAT dashboard </p>
                                </td>
                      </tr>    
                      <tr>
                      ${OverviewTable(payload.tableData)}
                      </tr>
                      <tr>
                        <td colspan="2" align="left" style="padding: 10px; font-size: 14px">
                          <p style="padding: 2px 0px;color:#000000;margin-top:-0.5em;"> For any queries,
                            <a style="color:#0000ee;text-decoration: underline;" href="mailto:${
                              payload.niagara_lead.mail
                            }?subject=${encodeURIComponent(
    "SAT: Query " + payload.SAT_name
  )}">
    Contact Niagara Lead
    </a>
  </p>        
  </td>
</tr>
  <tr>
                          <td colspan="2" cellpadding="2"><hr style="border-top: 1px solid rgb(122, 122, 122); margin: 0 auto;" /></td>
                      </tr>
                      <tr>
                          <td colspan="2" style="padding: 5px 10px; padding-bottom: 20px; color: rgb(0, 98, 152); font-size: 13px;" align="left">Note: This is an autogenerated message, please do not reply to this email</td>
                      </tr>
                  </table>
              </center>
          </body>
      </html>
      
      `;
  return template;
};

const OEMRecievingSignedDocs = (payload: any) => {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </head>
  <body style="margin: 0; background-color: #cccccc">
    <center style="text-align: center; font-family: calibri !important">
      <table
        style="border-radius: 20px; margin: 20px auto"
        cellpadding="0"
        cellspacing="0"
        width="550"
        bgcolor="white"
        style="border: 1px solid black"
      >
        <tr>
          <td colspan="2" align="center">
            <img
              style="border-radius: 20px 20px 0px 0px"
              ;
              src="https://archwebdev.blob.core.windows.net/commentbox-images-container/SATEmailHeader.png"
              alt="niagaralogo"
              width="100%"
              height="55"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2" align="left" style="padding: 10px; font-size: 14px">
            <p style="padding: 2px 0px">Hello,</p>
            <p style="padding: 2px 0px;">
            Attached, please find the documents signed by ${
              payload.plant_director === undefined
                ? "the plant director"
                : payload.plant_director.user_ad_name + ", Plant Director."
            }</p>
            <p style="padding: 2px 0px;margin-top:-0.5em;"> For any queries,
            <a href="mailto:${
              payload.niagara_lead.mail
            }?subject=${encodeURIComponent("SAT: Query " + payload.SAT_name)}">
              Contact Niagara Lead
              </a>
          </p>  
          </td>
        </tr>
        <tr>
          <td align="left" style="padding: 10px; font-size: 14px"></td>
        </tr>

        <tr>
          <td colspan="2" cellpadding="2">
            <hr
              style="border-top: 1px solid rgb(122, 122, 122); margin: 0 auto"
            />
          </td>
        </tr>
        <tr>
          <td
            colspan="2"
            style="
              padding: 5px 10px;
              padding-bottom: 20px;
              color: rgb(0, 98, 152);
              font-size: 13px;
            "
            align="left"
          >
            Note: This is an autogenerated message, please do not reply to this
            email
          </td>
        </tr>
      </table>
    </center>
  </body>
</html>
`;
};

const OEMUploadDocsEmailTemplate = (payload: any) => {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </head>
  <body style="margin: 0; background-color: #cccccc">
    <center style="text-align: center; font-family: calibri !important">
      <table
        style="border-radius: 20px; margin: 20px auto"
        cellpadding="0"
        cellspacing="0"
        width="550"
        bgcolor="white"
        style="border: 1px solid black"
      >
        <tr>
          <td colspan="2" align="center">
            <img
              style="border-radius: 20px 20px 0px 0px"
              ;
              src="https://archwebdev.blob.core.windows.net/commentbox-images-container/SATEmailHeader.png"
              alt="niagaralogo"
              width="100%"
              height="55"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2" align="left" style="padding: 10px; font-size: 14px">
            <p style="padding: 2px 0px">Hello,</p>
            <p style="padding: 2px 0px">
              I am pleased to inform you that the SAT for ${
                payload.SAT_name
              } has been accepted
              by ${
                payload.plant_director === undefined
                  ? "the plant director"
                  : payload.plant_director.user_ad_name + ", Plant Director"
              }. To proceed with the finalization
              process, please use the following
              <a
                href=${payload.url}
                >link</a
              >
              to upload documents pending the Plant Director's signature.
            </p>
            <p style="padding: 2px 0px;margin-top:-0.5em;"> For any queries,
            <a href="mailto:${
              payload.niagara_lead.mail
            }?subject=${encodeURIComponent("SAT: Query " + payload.SAT_name)}">
              Contact Niagara Lead
              </a>
          </p>    
          </td>
        </tr>
        <tr>
          <td align="left" style="padding: 10px; font-size: 14px"></td>
        </tr>

        <tr>
          <td colspan="2" cellpadding="2">
            <hr
              style="border-top: 1px solid rgb(122, 122, 122); margin: 0 auto"
            />
          </td>
        </tr>
        <tr>
          <td
            colspan="2"
            style="
              padding: 5px 10px;
              padding-bottom: 20px;
              color: rgb(0, 98, 152);
              font-size: 13px;
            "
            align="left"
          >
            Note: This is an autogenerated message, please do not reply to this
            email
          </td>
        </tr>
      </table>
    </center>
  </body>
</html>
`;
};

const PDtoSignDocs = (payload: any) => {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </head>
  <body style="margin: 0; background-color: #cccccc">
    <center style="text-align: center; font-family: calibri !important">
      <table
        style="border-radius: 20px; margin: 20px auto"
        cellpadding="0"
        cellspacing="0"
        width="550"
        bgcolor="white"
        style="border: 1px solid black"
      >
        <tr>
          <td colspan="2" align="center">
            <img
              style="border-radius: 20px 20px 0px 0px"
              ;
              src="https://archwebdev.blob.core.windows.net/commentbox-images-container/SATEmailHeader.png"
              alt="niagaralogo"
              width="100%"
              height="55"
            />
          </td>
        </tr>
        <tr>
          <td colspan="2" align="left" style="padding: 10px; font-size: 14px">
            <p style="padding: 2px 0px">Hello,</p>
            <p style="padding: 2px 0px">
            The OEM has uploaded the required documents, awaiting your signature. We kindly request that you review and sign the documents at your earliest convenience through this 
            <a
            href=${payload.url}
            >link.</a
          >
            </p>
            <p style="padding: 2px 0px;margin-top:-0.5em;"> For any queries,
            <a href="mailto:${
              payload.niagara_lead.mail
            }?subject=${encodeURIComponent("SAT: Query " + payload.SAT_name)}">
              Contact Niagara Lead
              </a>
          </p>   
          </td>
        </tr>
        <tr>
          <td align="left" style="padding: 10px; font-size: 14px"></td>
        </tr>

        <tr>
          <td colspan="2" cellpadding="2">
            <hr
              style="border-top: 1px solid rgb(122, 122, 122); margin: 0 auto"
            />
          </td>
        </tr>
        <tr>
          <td
            colspan="2"
            style="
              padding: 5px 10px;
              padding-bottom: 20px;
              color: rgb(0, 98, 152);
              font-size: 13px;
            "
            align="left"
          >
            Note: This is an autogenerated message, please do not reply to this
            email
          </td>
        </tr>
      </table>
    </center>
  </body>
</html>
`;
};

const findTemplate = (payload: any) => {
  if (payload.type === "OEMUploadLinkTemplate") {
    return OEMUploadDocsEmailTemplate(payload);
  } else if (payload.type === "OEMRecievingSignedDocs") {
    return OEMRecievingSignedDocs(payload);
  } else if (payload.type === "PDtoSignDocs") {
    return PDtoSignDocs(payload);
  } else if (payload.type === "DailyReport") {
    return DailyReportTemplate(payload);
  }
};

export default findTemplate;
