import Scrollbars from "react-custom-scrollbars-2";
const CustomScrollbars = (props:any) => {
    const autoHeightMin = props.autoHeightMin || 0;
    const autoHeightMax = props.autoHeightMax || 200;
    const styles = props.style || {};
    const autoHeights:any = props.autoHeight ? true : false;
    const className = props.className ? props.className : "";
  return (
    <Scrollbars
      autoHide
      autoHeight={autoHeights}
      autoHeightMax = {autoHeightMax}
      autoHeightMin={autoHeightMin}
      renderThumbVertical={({ style, ...props }: any) => (
        <div {...props} style={{ ...style, backgroundColor: "#667897"}} />
      )}
      renderTrackHorizontal={({ style, ...props }: any) => (
        <div {...props} style={{ ...style, backgroundColor: "#667897" }} />
      )}
      style={styles}
      className={className}
    >
      {props.children}  
    </Scrollbars>
  );
};

export default CustomScrollbars;
