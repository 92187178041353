import {
  LOAD_LOCATIONS,
  LOAD_LOCATIONS_SUCCESS,
  LOAD_LOCATIONS_ERROR,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
} from "../Types/locations.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all locations from the api
const getLocations = async () => {
  let locations: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`locations?pageSize=100&page=${page++}`);
    locations = locations.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return locations;
};

export function* listLocations(): any {
  try {
    const data = yield call(() =>
      getLocations().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_LOCATIONS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: LOAD_LOCATIONS_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}
export function* updateLocation(action: any): any {
  try {
    yield put({
      type: UPDATE_LOCATION_SUCCESS,
      payload: action.payload,
    });
  } catch (error: any) {
    yield put({
      type: LOAD_LOCATIONS_ERROR,
      payload: null,
    });
  }
}

export function* deleteLocation(action: any): any {
  try {
    yield put({
      type: DELETE_LOCATION_SUCCESS,
      payload: action.payload,
    });
  } catch (error: any) {
    yield put({
      type: LOAD_LOCATIONS_ERROR,
      payload: null,
    });
  }
}
export function* listLocationsSaga() {
  yield takeEvery(LOAD_LOCATIONS, listLocations);
  yield takeEvery(UPDATE_LOCATION, updateLocation);
  yield takeEvery(DELETE_LOCATION, deleteLocation);
}
