import {
	LOAD_USER_ACCESS,
	LOAD_USER_ACCESS_SUCCESS,
	LOAD_USER_ACCESS_ERROR
} from '../Types/userAccess.constants'

const INITIAL_STATE = {
	loading: false,
	userAccess: [],
	error: null
}

export const userAccessReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case LOAD_USER_ACCESS:
			return { ...state, loading: true, userAccess: [] }
		case LOAD_USER_ACCESS_SUCCESS:
			return {
				...state,
				loading: false,
				userAccess: action.payload
			}
		case LOAD_USER_ACCESS_ERROR:
			return { ...state, loading: false, error: action.payload }
		default:
			return state
	}
}
