import {
  LOAD_SATANNOTATIONS,
  LOAD_SATANNOTATIONS_SUCCESS,
  LOAD_SATANNOTATIONS_ERROR,
} from "../Types/satAnnotations.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfigMonarch";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all plants from the api
const getATAnnotations = async () => {
  let satAnnotations: any = [];
  let res = null;
  //loop to fetch all pages from api
  res = await axios.get(`SATAnnotations`);
  satAnnotations = res.data;
  return satAnnotations;
};

export function* listSATAnnotations(): any {
  try {
    const data = yield call(() =>
      getATAnnotations().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_SATANNOTATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_SATANNOTATIONS_ERROR,
      payload: null,
    });
  }
}

export function* listSATAnnotationsSaga() {
  yield takeEvery(LOAD_SATANNOTATIONS, listSATAnnotations);
}
