import { FC, useEffect } from 'react'
import Alert from '../../componets/Modal/RefreshModal'

interface RefreshModalProps {
	isAlertOpen: boolean
	setAlertClose: any
}

const RefreshModal: FC<RefreshModalProps> = ({
	isAlertOpen,
	setAlertClose
}) => {
	useEffect(() => {
		setAlertClose()
	}, [])

	const handleContinue = () => {
		window.location.href = ''
		setAlertClose()
	}

	return (
		<Alert
			open={isAlertOpen}
			handleContinue={handleContinue}
			setOpen={setAlertClose}
		/>
	)
}

export default RefreshModal
