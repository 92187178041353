const getTimeStampKey = (dimensionValue: any, resultSet: any) => {
	return `${dimensionValue?.substr(
		0,
		dimensionValue?.indexOf('.')
	)}.timestamp.${
		resultSet.loadResponses[0].query.timeDimensions[0]?.granularity
	}`
}

const getDescriptionKey = (dimensionValue: any) => {
	return `${dimensionValue?.substr(
		0,
		dimensionValue?.indexOf('.')
	)}.${dimensionValue?.substr(dimensionValue?.indexOf('.') + 1)}`
}

const getValueKey = (measureValue: any) => {
	return `${measureValue.substr(
		0,
		measureValue.indexOf('.')
	)}.${measureValue.substr(measureValue.indexOf('.') + 1)}`
}

const getTagKey = (dimensionValue2: any) => {
	return `${dimensionValue2?.substr(
		0,
		dimensionValue2?.indexOf('.')
	)}.${dimensionValue2?.substr(dimensionValue2?.indexOf('.') + 1)}`
}

const formatCubeData = (
	resultSet: any,
	dimensionValue: string,
	dimensionValue2: string,
	measureValue: string,
	pivotConfig: any,
	colors_array?: any
) => {
	const range: any = {}
	const categories = new Set()
	const moddedRange: any = []

	resultSet.tablePivot(pivotConfig).forEach((item: any) => {
		const getTimeStamp = getTimeStampKey(dimensionValue, resultSet)
		const getDescription = getDescriptionKey(dimensionValue)
		const getValue = getValueKey(measureValue)
		const getTag = getTagKey(dimensionValue2)
		categories.add(item[getTimeStamp])
		if (!range[item[getDescription]]) {
			range[item[getDescription]] = {
				name: item[getDescription],
				tag: item[getTag],
				data: [],
				color:
					colors_array?.length > 0
						? colors_array.find((ele: any) => {
								return item[getDescription]?.includes(ele.value)
							})?.color
						: null,
				categories: []
			}
		}
		range[item[getDescription]].data.push(parseFloat(item[getValue]))
		range[item[getDescription]].categories.push(item[getTimeStamp])
	})

	// For each Parameter check the timeStamp
	Object.values(range).forEach((obj: any) => {
		const newData: any = []
		categories.forEach((timeStamp: any) => {
			const idx = obj.categories.indexOf(timeStamp)
			if (idx !== -1) {
				newData.push(obj.data[idx])
			} else {
				// For missing timestamp add null to the data
				newData.push(null)
			}
		})
		moddedRange.push({
			name: obj.name,
			tag: obj.tag,
			data: newData,
			color: obj.color
		})
	})

	return [moddedRange, categories]
}

const kformatter = (num: any) => {
	return Math.abs(num) === 0
		? 0
		: Math.abs(num) < 0.01
			? Number(num.toExponential(1)).toPrecision(2)
			: Math.abs(num) > 999999
				? Number(Math.sign(num) * (Math.abs(num) / 1000000)).toPrecision(1) +
					'Mil'
				: Math.abs(num) > 999
					? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(2) + 'k'
					: (Math.sign(num) * Math.abs(num)).toFixed(2)
}

const kformatterTootip = (num: any) => {
	return Math.abs(num) === 0
		? 0
		: Math.abs(num) < 0.01
			? Number(num.toExponential(1)).toPrecision(2)
			: Math.abs(num) > 999999
				? Number(Math.sign(num) * (Math.abs(num) / 1000000)).toPrecision(1) +
					'Mil'
				: Math.abs(num) > 999
					? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(2) + 'k'
					: (Math.sign(num) * Math.abs(num)).toFixed(2)
}

const hasInvalidCubePayload = (queryObj: any): boolean => {
	if (queryObj === null || queryObj === undefined) return true
	if (Array.isArray(queryObj)) {
		return queryObj.length === 0 || queryObj.some(hasInvalidCubePayload)
	}
	if (typeof queryObj === 'object') {
		return (
			Object.keys(queryObj).length === 0 ||
			Object.values(queryObj).some(hasInvalidCubePayload)
		)
	}
	return !queryObj
}

export {
	formatCubeData,
	getDescriptionKey,
	getTagKey,
	getTimeStampKey,
	getValueKey,
	kformatter,
	kformatterTootip,
	hasInvalidCubePayload
}
