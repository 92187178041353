import {
	PublicClientApplication,
	Configuration,
	PopupRequest,
	InteractionRequiredAuthError
} from '@azure/msal-browser'
import AppSettings from './../AppSettings'

const msalConfig: Configuration = {
	auth: {
		clientId: AppSettings.clientId,
		authority: AppSettings.getAADTenantUrl(),
		redirectUri: window.location.origin
	},
	cache: {
		cacheLocation: 'localStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
	}
}

const loginRequest: PopupRequest = {
	scopes: ['User.Read']
}

const msConfig = new PublicClientApplication(msalConfig)

const userInfo: any = { userIsAuthenticated: true }

const acquireTokenSilent = async () => {
	const account = msConfig.getAllAccounts()
	if (account.length !== 0) {
		userInfo['userIsAuthenticated'] = true
		const accessTokenRequest = {
			scopes: ['api://78f2f6b9-bcba-4ada-a7e6-57d15d8749da/api', 'User.Read'],
			account: account[0]
		}
		await msConfig
			.acquireTokenSilent(accessTokenRequest)
			.then(function (accessTokenResponse) {
				const accessToken = accessTokenResponse.accessToken
				userInfo['accessToken'] = accessToken
			})
			.catch(async function (error) {
				if (error instanceof InteractionRequiredAuthError) {
					// fallback to interaction when silent call fails
					const tokenRedirect =
						await msConfig.acquireTokenRedirect(accessTokenRequest)
					return tokenRedirect
				}
			})
	} else {
		userInfo['userIsAuthenticated'] = false
	}
}

const returnAccessToken = async (access?: string) => {
	const accounts = msConfig.getAllAccounts()

	if (accounts.length > 0) {
		const accessTokenRequest = {
			scopes:
				access === 'API'
					? ['api://78f2f6b9-bcba-4ada-a7e6-57d15d8749da/api']
					: ['User.Read'],
			account: accounts[0]
		}
		const accessToken = await msConfig
			.acquireTokenSilent(accessTokenRequest)
			.then(response => {
				return response.accessToken
			})
			.catch(async error => {
				if (error instanceof InteractionRequiredAuthError) {
					// fallback to interaction when silent call fails
					const tokenRedirect =
						await msConfig.acquireTokenRedirect(accessTokenRequest)
					return tokenRedirect
				}
				return null
			})

		return accessToken
	}

	return null
}

export {
	msConfig,
	loginRequest,
	acquireTokenSilent,
	userInfo,
	returnAccessToken
}
