import { Box } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";

const LogoSignWrapper = experimentalStyled(Box)(
  () => `
        margin-top: 4px;
        transform: scale(.8);
`
);

function Logo() {
  return (
    <LogoSignWrapper>
      {/* <BrightnessAutoTwoToneIcon style={{ fontSize: 35 }} /> */}

      <div style={{ display: "flex" }}>
        <img
          src={`${window.location.origin + "/LogoNiagara.svg"}`}
          style={{ width: 100, marginTop: 16 }}
          alt="Niagara | MonARCH"
        />
      </div>
    </LogoSignWrapper>
  );
}

export default Logo;
