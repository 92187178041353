const getUserProfile = (accessToken: any) => {
  const headers = new Headers();
  const bearer = "Bearer " + accessToken;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
  };
  return fetch("https://graph.microsoft.com/v1.0/me", options)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    });
};

export default getUserProfile;
