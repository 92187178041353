import { ReactElement, useState } from 'react'
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	Grid,
	Typography
} from '@mui/material'
import { CircularProgress } from '@material-ui/core'
import { ticketDetailsStyles } from '../TicketDetailsStyles'
import { ReactComponent as CloseIcon } from '../Icons/close-icon.svg'
import AttachFile from '@mui/icons-material/AttachFile'
import { useDispatch, useSelector } from 'react-redux'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { importDowntimeTickets } from '../Services/SupplyChainTikcetService'
import { loadSnackbar } from '../../../../../Utils/util'
export interface logilityInferface {
	open: boolean
	onConfirm(): void
	onClose(): void
}

const ImportTickets = (props: logilityInferface): ReactElement => {
	const classes: any = ticketDetailsStyles()
	const usersList: any = useSelector((state: any) => state.usersList)
	const [attachment, setAttachment] = useState<any>({
		file: null
		// url: null,
	})
	const [loading, setLoadering] = useState<boolean>(false)
	const dispatch = useDispatch()
	const [actionLoader, setActionLoader] = useState<boolean>(false)
	const currentUser = usersList?.currentUser

	const handleFileChange = async (event: any): Promise<void> => {
		try {
			setLoadering(true)
			const selectedFile = event?.target?.files[0]
			setAttachment({
				file: selectedFile
			})
		} catch (error) {
			dispatch(loadSnackbar('File format not supprted. Please try again'))
		} finally {
			setLoadering(false)
			event.target.value = ''
		}
	}

	const uploadImportFile = async (): Promise<any> => {
		try {
			setActionLoader(true)
			const form_data = new FormData()
			form_data.append('file', attachment?.file)
			const response = await importDowntimeTickets(form_data, currentUser?._id)
			if (response?.data?.statusCode === 200) {
				dispatch({
					type: 'LOAD_SNACKBAR',
					payload: {
						message: 'Tickets Imported!',
						type: 'success',
						open: true
					}
				})
			} else {
				dispatch({
					type: 'LOAD_SNACKBAR',
					payload: {
						message: response?.data?.data,
						type: 'error',
						open: true
					}
				})
			}
			props?.onConfirm()
		} catch (error) {
			// message for upload failure
			dispatch(loadSnackbar('Upload failed. Please check file and try again.'))
		} finally {
			setActionLoader(false)
		}
	}

	return (
		<Dialog
			open={props.open}
			aria-labelledby='form-dialog-title'
			maxWidth={false}
			className={classes.imageViewerDialog}
		>
			<div className={classes.logilityDialogWrapper}>
				<div className='contentWrapper'>
					<div className={classes.logilityDialogTitle}>
						<DialogTitle>Import Tickets</DialogTitle>
						<CloseIcon
							style={{ cursor: 'pointer' }}
							onClick={() => {
								props?.onClose()
							}}
						/>
					</div>
					<Box p={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box
									display='flex'
									alignItems='center'
									gap='20px'
									className={classes.statusAttechment}
								>
									<label>Upload import file *</label>
									<input
										accept='.xlsx, .xls'
										style={{ display: 'none' }}
										id='fileInput'
										type='file'
										onChange={handleFileChange}
									/>
									<label htmlFor='fileInput'>
										<Button
											className={classes.statusAttechmentButton}
											variant='contained'
											component='span'
											endIcon={<AttachFile />}
										>
											Browse
										</Button>
									</label>
									{attachment?.file && (
										<Box ml={2} display='flex' alignItems='center'>
											<Typography variant='body1'>
												{attachment?.file?.name}
											</Typography>
											<HighlightOffRoundedIcon
												className={classes.attachmentCloseIcon}
												onClick={() => {
													setAttachment({
														file: null,
														url: null
													})
												}}
											/>
										</Box>
									)}
									{loading && (
										<CircularProgress
											sx={{
												color: 'white',
												height: '1px',
												marginRight: '10px'
											}}
											size={18}
										/>
									)}
								</Box>
							</Grid>

							<Grid
								item
								md={12}
								textAlign={'right'}
								marginTop={'8px'}
								className='btnFoot'
								style={{ marginTop: '150px' }}
							>
								<Button
									onClick={() => {
										props?.onClose()
									}}
									variant='outlined'
									style={{ marginRight: '10px' }}
								>
									Cancel
								</Button>
								<Button
									variant='contained'
									onClick={() => {
										uploadImportFile()
									}}
									className='saveBtn'
									disabled={!attachment?.file}
								>
									{actionLoader ? (
										<CircularProgress
											sx={{
												color: 'white',
												height: '1px',
												marginRight: '10px'
											}}
											size={18}
										/>
									) : (
										'Save'
									)}
								</Button>
							</Grid>
						</Grid>
					</Box>
				</div>
			</div>
		</Dialog>
	)
}

export default ImportTickets
