import { saveAs } from 'file-saver'
import { call, put, takeEvery } from 'redux-saga/effects'
import axios from '../../Utils/AxiosConfig/axiosConfig'
import store from '../store'
import {
	EXPORT_TICKETS,
	EXPORT_TICKETS_ERROR,
	EXPORT_TICKETS_SUCCESS,
	FILTER_STATUS,
	FILTER_STATUS_ERROR,
	FILTER_STATUS_SUCCESS,
	LOAD_ALL_TICKETS,
	LOAD_ALL_TICKETS_ERROR,
	LOAD_ALL_TICKETS_SUCCESS,
	LOAD_ALL_TICKETS_USECASE,
	LOAD_ALL_TICKETS_USECASE_ERROR,
	LOAD_ALL_TICKETS_USECASE_SUCCESS,
	LOAD_TICKETS,
	LOAD_TICKETS_BY_ALERTS,
	LOAD_TICKETS_BY_ALERTS_ERROR,
	LOAD_TICKETS_BY_ALERTS_SUCCESS,
	LOAD_TICKETS_ERROR,
	LOAD_TICKETS_SUCCESS,
	SET_CURRENT_TICKET,
	SET_CURRENT_TICKET_ERROR,
	SET_CURRENT_TICKET_SUCCESS,
	UPDATE_FILTER_PARAMS,
	UPDATE_FILTER_PARAMS_ERROR,
	UPDATE_FILTER_PARAMS_SUCCESS,
	UPDATE_PAGINATION,
	UPDATE_PAGINATION_ERROR,
	UPDATE_PAGINATION_SUCCESS,
} from '../Types/tickets.contants'

const PAGE_SIZE = 500

// fetching the ticket data based on the filter
const fetchPage = async (payload: any) => {
	try {
		const userAccessResponse = store.getState().userAccess
		const userAccess = userAccessResponse?.userAccess
		const UserprofileAccess = userAccess
			? userAccess[0]?.profile_based_access
			: null
		const isAdminRole = UserprofileAccess ? UserprofileAccess?.isAdminRole : null
		if (payload !== undefined) {
			const ticketList = store.getState().ticketsList
			const baseURL = `tickets/filterTickets`

			const requestData = {
				pageSize: ticketList.pagination.pageSize,
				page: ticketList.pagination.pageNo,
				...payload
			}

			if (isAdminRole !== null && isAdminRole === false) {
				requestData.UserprofileAccess = UserprofileAccess
			}

			const headers = {
				'Content-Type': 'application/json' // Set the content type to JSON
			}

			try {
				const res = await axios.post(baseURL, requestData, { headers })
				return { results: res.data.rows, totalData: res.data.total }
			} catch (error) {
				// Return an empty array or default parameters
				return { results: [], totalData: 0 }
			}
		}
	} catch (error) {
		// Return an empty array or default parameters
		return { results: [], totalData: 0 }
	}
}

const exportList = async (payload: any) => {
	try {
		const userAccessResponse = store.getState().userAccess
		const userAccess = userAccessResponse?.userAccess
		const UserprofileAccess = userAccess
			? userAccess[0]?.profile_based_access
			: null
		const isAdminRole = UserprofileAccess ? UserprofileAccess?.isAdminRole : null
		if (payload !== undefined) {
			const ticketList = store.getState().ticketsList
			const baseURL = `tickets/exportTickets`

			const requestData = {
				pageSize: ticketList.pagination.pageSize,
				page: ticketList.pagination.pageNo,
				...payload
			}

			if (isAdminRole !== null && isAdminRole === false) {
				requestData.UserprofileAccess = UserprofileAccess
			}

			const res = await axios.post(baseURL, requestData, {
				responseType: 'arraybuffer'
			})
			const blob = new Blob([res.data], {
				type: 'application/octet-stream'
			})
			const buff = saveAs(blob, `NB ARCH - Ticket List.xlsx`)
			return buff
		}
	} catch (error) {
		// Return an empty array or default parameters
		return { results: [], totalData: 0 }
	}
}
// const getTotalPages = async () => {
//   try {
//     const res = await axios.get("tickets");
//     return res.data.total;
//   } catch (error) {
//
//     return [];
//   }
// };

// const getTicketsByRole = async (fetchKey: any, fetchValue: any) => {
// 	try {
// 		let page = 1
// 		let res = null
// 		let ResponseMessage: any = []
// 		do {
// 			res = await axios.get(
// 				`tickets/fileredTicketsByRole?${fetchKey}=${JSON.stringify(
// 					fetchValue
// 				)}&pageSize=${PAGE_SIZE}&page=${page++}`
// 			)
// 			ResponseMessage = ResponseMessage.concat(res.data.rows)
// 		} while (res.data.page < res.data.totalPages)
// 		return ResponseMessage
// 	} catch (error) {
// 		return []
// 	}
// }

const getAllTickets = async (payload: any) => {
	try {
		let tickets: any = []
		// const totalRecords = await getTotalPages();
		// const totalPages = Math.ceil(totalRecords / PAGE_SIZE);

		// Create an array of page numbers from 1 to totalPages
		// const pageNumbers = Array.from(
		//   { length: totalPages },
		//   (data: any, i: any) => i + 1
		// );

		// Use Promise.all to fetch all pages concurrently

		const response = await fetchPage(payload)
		if (response) {
			const { results, totalData }: any = response
			tickets = results?.reduce((acc: any, rows: any) => acc.concat(rows), [])
			return { tickets: tickets, totalDataCount: totalData }
		} else {
			return { tickets: [], totalDataCount: 0 }
		}
		// Concatenate the results from all pages
	} catch (error) {
		return []
	}
}
//function to get all usecases from the api
const getTickets = async (payload: any) => {
	try {
		const ticketsRes: any = []
		let totalTicketsData: any = 0

		//loop to fetch all pages from api
		// do {
		//   res = await axios.get(`tickets?pageSize=500&page=${page++}`);
		//   tickets = tickets.concat(res.data.rows);
		// } while (res.data.page < res.data.totalPages);
		// const usersList = store.getState().usersList;
		// const plantsList = store.getState().plantsList;
		// const usecaseList = store.getState().usecaseList;
		// const useCaseId = payload.useCaseId;
		// const plantId = payload.plantId;
		// if (
		//   usersList.currentUserPermissions.includes("ARCH_ADMIN") ||
		//   usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER")
		// ) {
		const { tickets, totalDataCount }: any = await getAllTickets(payload)
		ticketsRes.push(...tickets)
		totalTicketsData = totalDataCount
		// if (!usecaseList.isCalled) {
		//   if (
		//     plantId.id !== null &&
		//     useCaseId.id !== null &&
		//     plantId.id !== "" &&
		//     useCaseId.id !== "" &&
		//     plantId.id !== undefined &&
		//     useCaseId.id !== undefined
		//   ) {
		//     await getAllMessages({});
		//   }
		// }
		// else {
		//   let usecaseRoles = usersList.currentUserPermissions.filter(
		//     (ele: any) => {
		//       return ele.includes("ARCH_USECASE_");
		//     }
		//   );
		//   const plantRoles = usersList.currentUserPermissions.filter(
		//     (ele: any) => {
		//       return ele.includes("ARCH_PM_");
		//     }
		//   );
		//   //Plant manager role
		//   if (
		//     plantRoles.length > 0 &&
		//     !usersList.currentUserPermissions.includes("ARCH_ADMIN")
		//   ) {
		//     const accessiblePlantsArray = plantRoles
		//       .map((ele: any) => {
		//         return ele.substring(8);
		//       })
		//       .map((plantCode: any) => {
		//         return plantsList.plants.find((plant: any) => {
		//           return plant.plant_code === plantCode;
		//         });
		//       })
		//       .map((plant: any) => plant._id);
		//     let assetsReponse = await getTicketsByRole(
		//       "accessiblePlantsArray",
		//       accessiblePlantsArray
		//     );
		//     if (
		//       plantId.id !== null &&
		//       useCaseId.id !== null &&
		//       plantId.id !== "" &&
		//       useCaseId.id !== "" &&
		//       plantId.id !== undefined &&
		//       useCaseId.id !== undefined
		//     ) {
		//       await getMessagesByRole(
		//         "accessiblePlantsArray",
		//         [plantId.id],
		//         "usecaseIds",
		//         [useCaseId.id]
		//       );
		//     }

		//     ticketsRes = ticketsRes.concat(assetsReponse);
		//   }
		//   //Asset Manager Role
		//   if (
		//     usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER") &&
		//     !usersList.currentUserPermissions.includes("ARCH_ADMIN")
		//   ) {
		//     const accessibleAssets = usersList.currentUser.allowed_assets;
		//     let assetsReponse = await getTicketsByRole(
		//       "accessibleAssets",
		//       accessibleAssets
		//     );
		//     if (
		//       plantId.id !== null &&
		//       useCaseId.id !== null &&
		//       plantId.id !== "" &&
		//       useCaseId.id !== "" &&
		//       plantId.id !== undefined &&
		//       useCaseId.id !== undefined
		//     ) {
		//       await getMessagesByRole(
		//         "accessibleAssets",
		//         accessibleAssets,
		//         "",
		//         ""
		//       );
		//     }
		//     ticketsRes = ticketsRes.concat(assetsReponse);
		//   }
		//   //Usecase champion role
		//   if (
		//     usecaseRoles.length > 0 &&
		//     !usersList.currentUserPermissions.includes("ARCH_ADMIN")
		//   ) {
		//     let usecaseCodeName = usecaseRoles.map((usecaseName: any) => {
		//       return usecaseName.substring(13);
		//     });
		//     let usecaseIds = usecaseList.usecases
		//       .filter((usecaseData: any) => {
		//         if (usecaseCodeName.includes(usecaseData.level_use_code)) {
		//           return usecaseData.litmus_use_code;
		//         }
		//       })
		//       .map((data: any) => data._id);
		//     if (
		//       (plantRoles.length > 0 ||
		//         usersList.currentUserPermissions.includes(
		//           "ARCH_ASSET_MANAGER"
		//         )) &&
		//       !usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
		//     ) {
		//       ticketsRes = ticketsRes.filter((ticket: any) =>
		//         usecaseIds.includes(ticket.usecase_id)
		//       );
		//     } else if (
		//       !usersList.currentUserPermissions.includes("ARCH_USECASE_ALL") &&
		//       plantRoles.length == 0 &&
		//       !usersList.currentUserPermissions.includes("ARCH_ASSET_MANAGER")
		//     ) {
		//       let assetsReponse = await getTicketsByRole(
		//         "usecaseIds",
		//         usecaseIds
		//       );
		//       if (
		//         plantId.id != null &&
		//         useCaseId.id != null &&
		//         plantId.id !== "" &&
		//         useCaseId.id !== "" &&
		//         plantId.id !== undefined &&
		//         useCaseId.id !== undefined
		//       ) {
		//         await getMessagesByRole(
		//           "usecaseIds",
		//           [useCaseId.id],
		//           "accessiblePlantsArray",
		//           [plantId.id]
		//         );
		//       }
		//       ticketsRes = ticketsRes.concat(assetsReponse);
		//     } else if (
		//       usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")
		//     ) {
		//       const { tickets, totalDataCount }: any = await getAllTickets(
		//         payload
		//       );
		//       if (
		//         plantId.id != null &&
		//         useCaseId.id != null &&
		//         plantId.id !== "" &&
		//         useCaseId.id !== "" &&
		//         plantId.id !== undefined &&
		//         useCaseId.id !== undefined
		//       ) {
		//         ticketsRes.push(...tickets);
		//         totalTicketsData = totalDataCount;
		// if (!usecaseList.isCalled) {
		//   await getAllMessages({});
		// }
		//       }
		//     }
		//   }
		// }
		return { ticketsRes: ticketsRes, ticketDataCount: totalTicketsData }
		// const usersList = store.getState().usersList;
		// const usecaseList = store.getState().usecaseList;
		// if (
		//   !usersList.currentUserPermissions.includes("ARCH_ADMIN") &&
		//   !usersList.currentUserPermissions.includes("ARCH_STAKEHOLDER")
		// ) {
		//   let usecaseRoles = usersList.currentUserPermissions.filter((ele: any) => {
		//     return ele.includes("ARCH_USECASE_");
		//   });
		//   if (usecaseRoles.length > 0) {
		//     if (!usersList.currentUserPermissions.includes("ARCH_USECASE_ALL")) {
		//       let usecaseCodeName = usecaseRoles.map((usecaseName: any) => {
		//         return usecaseName.substring(13);
		//       });
		//       let usecaseNames = usecaseList.usecases
		//         .filter((usecaseData: any) => {
		//           if (usecaseCodeName.includes(usecaseData.level_use_code)) {
		//             return usecaseData.litmus_use_code;
		//           }
		//         })
		//         .map((data: any) => data.litmus_use_code);
		//       tickets = tickets.filter((msg: any) =>
		//         usecaseNames.includes(msg.usecase_name)
		//       );
		//     }
		//   }
		// }
		// return tickets;
		//}
	} catch (error) {
		return []
	}
}

//function to get all usecases from the api
const getTicket = async (ticketId: any) => {
	let res = null
	//fetch single ticket based on alert
	res = await axios.get(`tickets/${ticketId}`)
	return res.data
}
//function to get all tickets from messages
const getTicketsByAlerts = async (params: any) => {
	const res = await axios.get(
		`tickets/getTicketsByAlertData?plant_id=${params.plant_id}&asset_id=${params.asset_id}&usecase_name=${params.usecase_name}`
	)
	return res
}

export function* listTickets(params: any): any {
	try {
		const data = yield call(() =>
			getTickets(params.payload).then(res => {
				return res
			})
		)
		yield put({
			type: LOAD_TICKETS_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: LOAD_TICKETS_ERROR,
			payload: null
		})
	}
}
// const allTickets = async (payload: any) => {
//   const res = await axios.post(`tickets/filterTickets`, payload);
//   const resp = res.data.total / 10;
//   const resss = resp.map(async () => {
//     await axios.post(`tickets/filterTickets`, payload);
//   });
//   // const resp = await axios.get(`tickets?pageSize=${res?.data?.total}&page=1`);
//   return res.data.rows;
// };

const allTickets = async (payload: any) => {
	try {
		const userAccessResponse = store.getState().userAccess
		const userAccess = userAccessResponse?.userAccess
		const UserprofileAccess = userAccess
			? userAccess[0]?.profile_based_access
			: null
		const isAdminRole = UserprofileAccess ? UserprofileAccess?.isAdminRole : null
		const requestData = {
			...payload
		}
		if (isAdminRole !== null && isAdminRole === false) {
			requestData.UserprofileAccess = UserprofileAccess
		}
		// Call the initial API to get the total
		const res = await axios.post('tickets/filterTickets', requestData)
		// Calculate the number of API calls needed
		const total = res.data.total
		const pageSize = 750
		const numberOfCalls = Math.ceil(total / pageSize)

		// Create an array of API call promises
		const apiCallPromises = []
		for (let page = 1; page <= numberOfCalls; page++) {
			// Modify the payload to include the current page
			const modifiedPayload = { ...requestData, page }
			apiCallPromises.push(axios.post('tickets/filterTickets', modifiedPayload))
		}
		// Wait for all API calls to complete
		const responses = await Promise.all(apiCallPromises)
		// Combine the data from all responses
		const combinedData = responses.reduce((acc, response) => {
			return acc.concat(response.data.rows)
		}, [])

		return combinedData
	} catch (error: any) {
		throw new Error(error)
	}
}

const allTicketsByAsset = async (params: any) => {
	try {
		const res = await axios.get(
			`tickets/getTciketsByUsecasePlantAssetIds?plantId=${params.plant_id}&assetId=${params.asset_id}&usecaseId=${params.usecase_id}&pageSize=${PAGE_SIZE}`
		)
		// Calculate the number of API calls needed
		const total = res.data.total
		const pageSize = 750
		const numberOfCalls = Math.ceil(total / pageSize)

		// Create an array of API call promises
		const apiCallPromises = []
		for (let page = 1; page <= numberOfCalls; page++) {
			// Modify the payload to include the current page
			apiCallPromises.push(
				axios.get(
					`tickets/getTciketsByUsecasePlantAssetIds?plantId=${params.plant_id}&assetId=${params.asset_id}&usecaseId=${params.usecase_id}&pageSize=${PAGE_SIZE}&page=${page}`
				)
			)
		}
		// Wait for all API calls to complete
		const responses = await Promise.all(apiCallPromises)
		// Combine the data from all responses
		const combinedData = responses.reduce((acc, response) => {
			return acc.concat(response.data.rows)
		}, [])

		return combinedData
	} catch (error: any) {
		throw new Error(error)
	}
}

// fetching all tickets initial
export function* allTicketList(payload: any): any {
	try {
		const data = yield call(() =>
			allTickets(payload.payload).then(res => {
				return res
			})
		)
		yield put({
			type: LOAD_ALL_TICKETS_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: LOAD_ALL_TICKETS_ERROR,
			payload: null
		})
	}
}

// const allTickets = async () => {
//   const res = await axios.get(`tickets`);
//   const resp = await axios.get(`tickets?pageSize=${res?.data?.total}&page=1`);
//   return resp.data.rows;
// };
// // fetching all tickets initial
// export function* allTicketList(): any {
//   try {
//     const data = yield call(() =>
//       allTickets().then((res) => {
//         return res;
//       })
//     );
//     yield put({
//       type: LOAD_ALL_TICKETS_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     yield put({
//       type: LOAD_ALL_TICKETS_ERROR,
//       payload: null,
//     });
//   }
// }

// export const exportList = async (payload: any) => {
//   debugger;
//   const resp = await axiosType.get(
//     `tickets/exportTickets?usecaseId=${payload?.useCaseId}&plantId=${payload?.plantId}&category=${payload?.category}&search=${payload?.ticketId}&role=${payload?.role}`
//   );
//   const buff = saveAs(resp.data, `NB ARCH - Ticket List.xlsx`);
//   return buff;
// };

// ticket list excel export functionality
export function* exportListItem(params: any): any {
	try {
		const data: any = yield call(() =>
			exportList(params.payload).then((res: any) => {
				return res
			})
		)
		yield put({
			type: EXPORT_TICKETS_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: EXPORT_TICKETS_ERROR,
			payload: null
		})
	}
}

export function* listTicketsByAlerts(params: any): any {
	try {
		const data = yield call(() =>
			getTicketsByAlerts(params.payload).then(res => {
				return res
			})
		)
		yield put({
			type: LOAD_TICKETS_BY_ALERTS_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: LOAD_TICKETS_BY_ALERTS_ERROR,
			payload: null
		})
	}
}

export function* updateTickets(action: any): any {
	try {
		const data = yield call(() =>
			getTicket(action.payload).then(res => {
				return res
			})
		)
		yield put({
			type: SET_CURRENT_TICKET_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: SET_CURRENT_TICKET_ERROR,
			payload: null
		})
	}
}

// updating pagination data
export function* updatePagination(action: any): any {
	try {
		yield put({
			type: UPDATE_PAGINATION_SUCCESS,

			payload: action.payload
		})
	} catch (error) {
		yield put({
			type: UPDATE_PAGINATION_ERROR,

			payload: action.payload
		})
	}
}

export function* filterStatus(action: any): any {
	try {
		yield put({
			type: FILTER_STATUS_SUCCESS,
			payload: action.payload
		})
	} catch (error) {
		yield put({
			type: FILTER_STATUS_ERROR,
			payload: action.payload
		})
	}
}
export function* filterParamsUpdate(action: any) {
	try {
		yield put({
			type: UPDATE_FILTER_PARAMS_SUCCESS,
			payload: action.payload
		})
	} catch (error) {
		yield put({
			type: UPDATE_FILTER_PARAMS_ERROR,
			payload: action.payload
		})
	}
}

// fetching all tickets for report page
export function* allTicketListForUsecase(payload: any): any {
	try {
		const data = yield call(() =>
			allTicketsByAsset(payload.payload).then(res => {
				return res
			})
		)
		yield put({
			type: LOAD_ALL_TICKETS_USECASE_SUCCESS,
			payload: data
		})
	} catch (error) {
		yield put({
			type: LOAD_ALL_TICKETS_USECASE_ERROR,
			payload: null
		})
	}
}

export function* listTicketsSaga() {
	yield takeEvery(LOAD_TICKETS, listTickets)
	yield takeEvery(LOAD_TICKETS_BY_ALERTS, listTicketsByAlerts)
	yield takeEvery(SET_CURRENT_TICKET, updateTickets)
	yield takeEvery(UPDATE_PAGINATION, updatePagination)
	yield takeEvery(LOAD_ALL_TICKETS, allTicketList)
	yield takeEvery(EXPORT_TICKETS, exportListItem)
	yield takeEvery(FILTER_STATUS, filterStatus)
	yield takeEvery(UPDATE_FILTER_PARAMS, filterParamsUpdate)
	yield takeEvery(LOAD_ALL_TICKETS_USECASE, allTicketListForUsecase)
}
