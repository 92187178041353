import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button, Stack, Typography } from '@mui/material'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(() => ({
	iconContainer: {
		'&.MuiSvgIcon-root': {
			fontSize: '30px',
			color: '#8476EF',
			marginBottom: '20px'
		}
	},
	containedButton: {
		'&.MuiButton-root': {
			backgroundColor: '#8476EF',
			color: 'white',
			marginTop: '30px',
			width: '150px',
			fontWeight: '500',
			fontSize: '14px',
			'&:hover': {
				backgroundColor: '#fff',
				color: '#8476EF',
				border: '1px solid #000'
			}
		}
	}
}))

function Alert(props: any) {
	const navigate = useNavigate()
	const classes = useStyles()

	const style = {
		position: 'absolute' as const,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: { xs: 285, md: 470 },
		boxShadow: '8px 24px 91px -11px rgba(12, 18, 52, 0.3)',
		p: '16px 40px',
		color: 'white',
		borderRadius: '12px',
		bgcolor: '#293368',
		border: '#293368',
		':focus': { outline: 'none' }
	}

	const viewRequest = () => {
		navigate(`/management/user-management`, {
			state: { tab: 'pending-approval' }
		})
		props.handleClose()
	}

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={props.open}
			onClose={() => {
				props.handleClose()
			}}
			closeAfterTransition
			onClick={e => e.stopPropagation()}
		>
			<Fade in={props.open}>
				<Stack sx={style} alignItems='center'>
					<AddCircleOutlineIcon
						style={{
							fontSize: '30px',
							color: '#8476EF',
							marginBottom: '20px'
						}}
					/>
					<Typography sx={{ fontSize: '1.2rem' }}>
						New Access Request Recieved
					</Typography>

					<Button
						className={classes.containedButton}
						onClick={() => {
							viewRequest()
						}}
					>
						View
					</Button>
				</Stack>
			</Fade>
		</Modal>
	)
}

export default Alert
