import lottie from 'lottie-web'
import * as animationData from './animationSVG.json'
import treesImg from './backgroundTrees.png'

const styles = {
	body: {
		marginBottom: '0%',
		paddingBottom: '0%',
		borderBottom: '0%'
	},
	image: {
		width: '65%',
		position: 'absolute' as const,
		right: '35vw',
		outline: '5px solid #10548c',
		outlineOffset: '-3px'
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		height: '100vh',
		alignItems: 'center',
		backgroundColor: '#10548c',
		overflow: 'hidden'
	},
	trees: {
		backgroundImage: `url(${treesImg})`,
		backgroundPosition: ' bottom ',
		backgroundSize: 'contain',
		backgroundRepeat: 'repeat-x',
		height: '20em',
		aspectRatio: '4.16',
		position: 'absolute' as const,
		bottom: '0vw',
		width: '100%'
	}
}

let showAnimation = true

function waitForElm(selector: string) {
	return new Promise(resolve => {
		if (document.querySelector(selector)) {
			return resolve(document.querySelector(selector))
		}

		const observer = new MutationObserver(() => {
			if (document.querySelector(selector)) {
				resolve(document.querySelector(selector))
				observer.disconnect()
			}
		})

		observer.observe(document.body, {
			childList: true,
			subtree: true
		})
	})
}

function displayLoadingAnimation(container: HTMLElement) {
	lottie.loadAnimation({
		container: container,
		animationData: animationData,
		renderer: 'svg', // "canvas", "html"
		loop: false, // boolean
		autoplay: true // boolean
	})
	const hideAnimation = () => {
		showAnimation = false
	}
	setTimeout(hideAnimation, 5000)
}

waitForElm('#loadingAnimationContainer').then(elm => {
	displayLoadingAnimation(elm as HTMLElement)
})

/*
const lottieAnimationOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
*/

function LoadingAnimation() {
	return (
		<div>
			{showAnimation === true ? (
				<div style={styles.container}>
					<div id='loadingAnimationContainer'></div>
					{/*
        <video autoPlay muted loop style={styles.image}>
        <source src={vidAnimation}></source>
      </video>
      <lottie
        options={lottieAnimationOptions}
        height={"auto"}
        width={"70%"}
        isClickToPauseDisabled={true}
        speed={0.8}
      />
        */}

					<div style={styles.trees}></div>
				</div>
			) : (
				<div></div>
			)}
		</div>
	)
}

export default LoadingAnimation
