import {
  clone,
  cloneDeep,
  find,
  get,
  intersection,
  isEmpty,
  set,
} from "lodash";
import { Asset_Usecase, Plant } from "../models/Types_Interfaces";
import {
  getSeedValues,
  sortAssetOrder,
  sortParentOrder,
} from "./UtilFunctions/useCaseUtilFunctions";

export const USE_CASE_ROUTES_CODE_MAPPER = {
  "Motors and Pumps": "motors-and-pumps",
  "Utilities CBM": "utilities",
  "WQMS": "wqms",
  "Airleaks Monitoring": "air-leaks-monitoring",
  "Servo Drives": "servo-drives",
  "Aseptic-Valves": "aseptic-valves",
  "Aseptic-Transmitters": "aseptic-transmitters",
  "Aseptic-CCP": "aseptic-ccp",
  "Run Hours V2": "run-hours-pm",
  "AGR Inspection System":"inspection-system"
};

export const LIST_COMPONENT_OPTIONS = ["AreasOrderArray", "AssetsOrderArray"];
export const LIST_COMPONENT_WITH_NESTED_DATA = ["RH - Gradient Values"];

export const ASEPTIC_ICON_COMPONENT_OPTIONS = ["AsepticIconMapping"];
export const CCPS_MONITORED_COMPONENT_OPTIONS = ["CCPsMonitored"];
export const DYNAMIC_QUERY_COMPONENT_OPTIONS = ["Dynamic Query"];
export const WQMS_TAG_PARAMETER_COMPONENT_OPTIONS = ["WQMStagParameter"];
export const WQMS_RECIPE_MAPPING_COMPONENT_OPTIONS = ["WQMSRecipeMapping"];
export const WQMS_PLANT_BASED_LENGTH_COMPONENT_OPTIONS = [
  "WQMSPlantBasedLength",
];
export const STRING_COMPONENT_OPTIONS = [
  "WQMSTagsEndingString",
  "AirLeaksTagParameter",
  "AGRMatrixViewTags"
];
export const TAG_GENERATOR_COMPONENT_OPTIONS = ["TagGenerator"];
export const RH_GRADIENT_CONFIG_COMPONENT_OPTIONS = ["RH - Gradient Config"];
export const RH_VP_PD_CONFIG_COMPONENT_OPTIONS = ["RH - VP and PD Config"];
export const RH_GANTT_CHART_COMPONENT_OPTIONS = ["RH - Gantt Chart"];
export const RH_FINAL_CONFIG_COMPONENT_OPTIONS = ["RH - Final Config"];

export const NUMBER_VALIDATION_REGEX = /^\d+$/;
export const TAG_VALIDATION_REGEX = /^[A-Za-z0-9_-]*$/;
export const ALPHANUMERIC_VALIDATION_REGEX = /^[\w\-\s]+$/;
export const WQMS_RECIPE_MAPPING_REGEX = /^[ A-Za-z0-9_.&-\s()]*$/;
export const COLOR_CODE_REGEX = /^#([A-Fa-f0-9]{6})$/;

/**
 * This function is to check whether user is allowed to view self service UI
 *
 * @params userPermissions array from usersList redux state * 
 * @returns boolean
 */
export const isUserAllowed = (userPermissions: any) => {
  return (
    userPermissions.includes("ARCH_ADMIN") ||
    userPermissions.includes("ARCH_DEVELOPER")
  );
};

type useCaseIdentifierType =
  | "Motors and Pumps"
  | "Utilities CBM"
  | "WQMS"
  | "Airleaks Monitoring"
  | "Servo Drives"
  | "Aseptic-Valves"
  | "Aseptic-Transmitters"
  | "Aseptic-CCP"
  | "Run Hours V2";

/**
 * This function returns current usecase object from redux store
 *
 * @params useCaseIdentifier string from component level
 * @params allUsecases object array from redux store
 * @returns current UseCase Data object from state
 */
export const getCurrentUseCaseData = (
  useCaseIdentifier: useCaseIdentifierType,
  allUsecases: any[]
) => {
  const routeCode = USE_CASE_ROUTES_CODE_MAPPER[useCaseIdentifier];

  if (isEmpty(allUsecases)) return {};

  return find(allUsecases, function (usecase) {
    if (usecase?.routes_use_code === routeCode) {
      return true;
    }
  });
};

/**
 * This function returns assets as per Areas Order From Levels List
 *
 * @params levelsList object array from redux store
 * @params selectedPlant object from redux store
 * @params currentUsecaseData object from redux store
 * @params seedsList object array from redux store
 * @params currentUseCase string from component level
 * @returns assets object array as per Areas Order
 */
export const getAreasOrderFromLevelsList = (
  levelsList: any,
  selectedPlant: any,
  currentUsecaseData: any,
  seedsList: any[],
  currentUseCase: string,
  plantsList: any
) => {
  if (levelsList.loading !== false || levelsList.levels.length === 0) return [];

  const defaultPlantCode = selectedPlant.plant_code || get(plantsList, "plants[0].plant_code", "HOU");

  const orderArray = getSeedValues(
    seedsList,
    "AreasOrderArray",
    currentUseCase,
    defaultPlantCode
  );

  let assets = [];

  if (currentUseCase === "Aseptic-Transmitters") {
    assets = levelsList.levels
      .filter((asset: any) => {
        return (
          asset.plant_id === selectedPlant?._id &&
          asset.use_case.includes(currentUsecaseData?._id) &&
          (asset.asset_category === "Asset Area" ||
            asset.asset_category === "Line" ||
            asset.asset_category === "Asset") &&
          levelsList.levels.filter((ele: any) => {
            return ele.parent_asset === asset._id;
          }).length > 0
        );
      })
      .sort((a: any, b: any) => sortParentOrder(a, b, orderArray));
  } else if (currentUseCase === "Aseptic-Valves") {
    assets = levelsList.levels
      .filter((asset: any) => {
        return (
          asset.plant_id === selectedPlant?._id &&
          asset.use_case.includes(currentUsecaseData?._id) &&
          (asset.asset_category === "Asset Area" ||
            asset.asset_category === "Asset")
        );
      })
      .sort((a: any, b: any) => sortParentOrder(a, b, orderArray));
  } else {
    assets = levelsList.levels
      .filter((asset: any) => {
        return (
          asset.plant_id === selectedPlant?._id &&
          (asset.asset_category === "Line" ||
            asset.asset_category === "Asset Area") &&
          asset.use_case.includes(currentUsecaseData?._id)
        );
      })
      .sort((a: any, b: any) => sortParentOrder(a, b, orderArray));
  }

  return assets;
};

type AssetsOrderFromLevelsListType = {
  levelsList: any;
  selectedPlant: any;
  currentUseCase: string;
  currentUseCaseData: any;
  seedsList: any[];
  selectedParentAsset?: any;
  selectedAssetType?: any;
  usecaseList?: any;
  tagsMasterList?: any;
  assetTags?: any;
  plantsList: any;
};

/**
 * This function returns assets as per Areas Order From Levels List
 *
 * @params levelsList object array from redux store
 * @params selectedPlant object from redux store
 * @params currentUsecaseData object from redux store
 * @params seedsList object array from redux store
 * @params currentUseCase string from component level
 * @returns assets object array as per Areas Order
 */
export const getAssetsOrderFromLevelsList = ({
  levelsList,
  selectedPlant,
  currentUseCase,
  currentUseCaseData,
  seedsList,
  selectedParentAsset = {},
  usecaseList,
  tagsMasterList,
  assetTags,
  plantsList
}: AssetsOrderFromLevelsListType) => {
  if (levelsList.loading !== false || levelsList.levels.length === 0) return [];

  const defaultPlantCode = selectedPlant.plant_code || get(plantsList, "plants[0].plant_code", "HOU");

  // fetching seed for assets
  const orderArrayForAssets = getSeedValues(
    seedsList,
    "AssetsOrderArray",
    currentUseCase,
    defaultPlantCode
  );

  let assets = [];

  if (currentUseCase === "Run Hours V2") {
    assets = levelsList.levels.filter((asset: any) => {
      return (
        asset.plant_id === selectedPlant?._id &&
        asset.use_case.includes(
          usecaseList.usecases.find((usecase: Asset_Usecase) => {
            return usecase.level_use_case === currentUseCase;
          })?._id
        ) &&
        asset.level === "608a39155f91fe00111da08e" &&
        asset?.devices?.filter((device: any) => {
          return device.usecase === undefined
            ? true
            : device.usecase ===
                usecaseList.usecases.find((usecase: any) => {
                  return (
                    usecase.level_use_case ===
                    currentUseCaseData?.level_use_case
                  );
                })?._id;
        })[0]?.tag
      );
    });
  } else if (currentUseCase === "Aseptic-CCP") {
    assets = levelsList.levels.filter((ele: any) => {
      return (
        ele.plant_id === selectedPlant?._id &&
        ele.devices.length !== 0 &&
        ele.use_case.includes(currentUseCaseData?._id)
      );
    });

    assets = assets.filter((ele: any) => {
      const deviceTag = ele.devices.filter((device: any) => {
        return device.usecase === undefined
          ? true
          : device.usecase === currentUseCaseData?._id;
      })[0]?.tag;

      const uniqueTags =
        ele?.asset_name === "T40 Flex"
          ? [
              "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS_CIPStatus_PV",
              "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS_Recipe_PV_RecipeName",
              "T40TemplePlant_T40AsepticGroup_T40AsepticProcessing_T40AsepticUltraHighTemperature_T40AsepticVTIS_Status_STAT",
            ]
          : tagsMasterList?.filter((tag: any) => {
              return (
                tag.includes(`${deviceTag}_`) &&
                assetTags?.some((assetTag: any) => tag.endsWith(assetTag))
              );
            });

      return uniqueTags.length > 1;
    });
  } else {
    assets = levelsList.levels
      .filter((ele: any) => {
        return (
          ele.plant_id === selectedPlant?._id &&
          ele.parent_asset === selectedParentAsset?._id &&
          ele.use_case.includes(currentUseCaseData?._id)
        );
      })
  }

  assets.sort((a: any, b: any) => sortAssetOrder(a, b, orderArrayForAssets));

  return assets;
};

/**
 * This function returns boolean value stringified JSON value is valid JSON or not
 *
 * @params str - stringified JSON value
 * @returns boolean value stringified JSON value is valid JSON or not
 */
export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

type ListItemsFunctionType = {
  selectedOption: string;
  selectedSeedData: any;
  currentUseCase: string;
  selectedPlant: Plant;
  levelsList: any;
  seedsList: any;
  currentUseCaseData: any;
  selectedParentAsset?: any;
  selectedAssetType?: any;
  usecaseList?: any;
  tagsMasterList?: any;
  assetTags?: any;
  plantsList?: any;
  currentPlantId?: any;
};

/**
 * This function returns boolean checking asset items array Equality
 *
 * @params itemsArray- assets list object array from seed
 * @params itemsFromLevelsList- assets list object array from redux store
 * @returns boolean value after checking asset items array Equality
 */
const checkListItemsEquality = (itemsArray: any, itemsFromLevelsList: any) => {
  if (itemsFromLevelsList?.length !== itemsArray?.length) return false;

  const intersectionArray = intersection(
    itemsArray,
    itemsFromLevelsList.map((obj: any) => obj.asset_alias)
  );

  if (intersectionArray?.length !== itemsArray?.length) return false;

  return true;
};

// This function return data for list component related data, i.e., for AreasOrderArray, AssetsOrderArray
export const getListItems = ({
  selectedOption,
  selectedSeedData,
  currentUseCase,
  selectedPlant,
  levelsList,
  seedsList,
  currentUseCaseData,
  selectedParentAsset,
  selectedAssetType,
  usecaseList,
  tagsMasterList,
  assetTags,
  plantsList
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = find(selectedSeedData?.seed_data_value, function (obj) {
    if (obj.key === currentUseCase) {
      return true;
    }
  });

  if (isEmpty(items)) return [];

  const parsedItems = JSON.parse(items.value);
  let itemsArray = parsedItems[selectedPlant.plant_code];
  let itemsFromLevelsList = [];

  if (selectedOption === "AreasOrderArray")
    itemsFromLevelsList = getAreasOrderFromLevelsList(
      levelsList,
      selectedPlant,
      currentUseCaseData,
      seedsList,
      currentUseCase,
      plantsList
    );

  if (selectedOption === "AssetsOrderArray")
    itemsFromLevelsList = getAssetsOrderFromLevelsList({
      levelsList,
      selectedPlant,
      currentUseCase,
      currentUseCaseData,
      seedsList,
      plantsList,
      selectedParentAsset,
      selectedAssetType,
      usecaseList,
      tagsMasterList,
      assetTags,
    });

  const keyToPick =
    currentUseCase === "Run Hours V2" ? "asset_name" : "asset_alias";

  if (
    isEmpty(itemsArray) ||
    !checkListItemsEquality(itemsArray, itemsFromLevelsList)
  ) {
    itemsArray = itemsFromLevelsList.map((obj: any) => obj[keyToPick]);
  }

  return itemsArray;
};

type ListItemsWithNestedData = {
  selectedOption: string;
  selectedSeedData: any;
};

export const getListItemsWithNestedData = ({
  selectedOption,
  selectedSeedData,
}: ListItemsWithNestedData) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = selectedSeedData?.seed_data_value;

  if (isEmpty(items)) return [];

  const itemsArray = items.map((item: any) => {
    return { key: item.key, value: JSON.parse(item.value) };
  });

  return itemsArray;
};

type AsepticIconItemsFunctionType = {
  selectedOption: string;
  selectedSeedData: any;
  currentUseCase: string;
  selectedPlant: Plant;
  levelsList: any;
  seedsList: any;
  currentUseCaseData: any;
  plantsList: any
};

// This function return data for Aseptic Icon component related data, i.e., for AsepticIconMapping
export const getAsepticIconItems = ({
  selectedOption,
  selectedSeedData,
  currentUseCase,
  selectedPlant,
  levelsList,
  seedsList,
  currentUseCaseData,
  plantsList
}: AsepticIconItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  let items = get(selectedSeedData, "seed_data_value", []);

  if (isEmpty(items)) {
    items = getAreasOrderFromLevelsList(
      levelsList,
      selectedPlant,
      currentUseCaseData,
      seedsList,
      currentUseCase,
      plantsList
    );
  }

  return items;
};

type AsepticIconDropDownOptionsFunctionType = {
  selectedOption: string;
  selectedSeedData: any;
  currentUseCase?: string;
  selectedPlant: Plant;
  levelsList: any;
  seedsList: any;
  currentUseCaseData: any;
  plantsList: any;
};

// This function return data for Aseptic Icon Dropdown options, i.e., for AsepticIconMapping
export const getDropDownOptionsForAsepticIcons = ({
  selectedOption,
  selectedSeedData,
  selectedPlant,
  levelsList,
  seedsList,
  currentUseCaseData,
  plantsList,
}: AsepticIconDropDownOptionsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const defaultPlantCode = selectedPlant.plant_code || get(plantsList, "plants[0].plant_code", "HOU");

  const orderArray = getSeedValues(
    seedsList,
    "AreasOrderArray",
    "Aseptic-Transmitters",
    defaultPlantCode
  );

  const filteredAssets = levelsList.levels
    .filter((asset: any) => {
      return (
        // asset.plant_id === selectedPlant?._id &&
        asset.use_case.includes(currentUseCaseData?._id) &&
        (asset.asset_category === "Asset Area" ||
          asset.asset_category === "Line" ||
          asset.asset_category === "Asset") &&
        levelsList.levels.filter((ele: any) => {
          return ele.parent_asset === asset._id;
        }).length > 0
      );
    })
    .sort((a: any, b: any) => sortParentOrder(a, b, orderArray));

  return filteredAssets;
};

type CCPsMonitoredItemsFunctionType = {
  selectedOption: string;
  selectedSeedData: any;
  currentUseCase: string;
  selectedPlant: Plant;
  levelsList: any;
  seedsList: any;
  currentUseCaseData: any;
};

// This function return data for CCPs Monitored component related data, i.e., for CCPsMonitored
export const getCCPsMonitoredItems = ({
  selectedOption,
  selectedSeedData,
  selectedPlant,
}: CCPsMonitoredItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  if (isEmpty(items)) return [];

  const currentPlantItem = find(items, function (item) {
    if (item?.key === selectedPlant.plant_code) {
      return true;
    }
  });

  const parsedItems = JSON.parse(currentPlantItem.value);

  return parsedItems;
};

const findParentAsset = (
  plantId: string,
  useCaseName: string,
  assetId: string,
  selectedAssetType: any,
  levelsList: any,
  usecaseList: any,
) => {
  return levelsList.levels.filter((asset: any) => {
    return (
      asset.plant_id === plantId &&
      asset.parent_asset === assetId &&
      asset.use_case.includes(
        usecaseList.usecases.find((usecase: Asset_Usecase) => {
          return usecase.level_use_case === useCaseName;
        })._id
      ) &&
      (selectedAssetType !== null
        ? asset.asset_type === selectedAssetType._id
        : true)
    );
  });
};

/**
 * This function return asset data for Dyanmic Query selected area assets
 *
 * @params selectedPlant object from component level
 * @params levelsList object array from redux store
 * @params seedsList object array from redux store
 * @params usecaseList object array from redux store
 * @params currentUsecaseData object from redux store
 * @params selectedParentAsset object array from redux store
 * @params selectedAssetType object array from redux store
 * @params plantsList object array from redux store
 * @returns assets or area object array
 */

export const getDynamicQueryAreaAssets = ({
  selectedPlant,
  levelsList,
  usecaseList,
  currentUseCaseData,
  selectedParentAsset,
  selectedAssetType,
  seedsList,
  plantsList,
}: ListItemsFunctionType) => {

  const defaultPlantCode =
    selectedPlant.plant_code || get(plantsList, "plants[0].plant_code", "HOU");

  const orderArrayForAssets = getSeedValues(
    seedsList,
    "AssetsOrderArray",
    "Utilities CBM",
    defaultPlantCode
  );

  const parentAssets = findParentAsset(
    selectedPlant?._id,
    currentUseCaseData?.level_use_case,
    selectedParentAsset?._id,
    selectedAssetType,
    levelsList,
    usecaseList
  ).sort((a: any, b: any) => sortAssetOrder(a, b, orderArrayForAssets));

  let selectedAreaAssets: any[] = [];
  parentAssets.forEach((ele: any) => {
    const childAsset = findParentAsset(
      selectedPlant?._id,
      currentUseCaseData?.level_use_case,
      ele?._id,
      selectedAssetType,
      levelsList,
      usecaseList
    ).sort((a: any, b: any) => {
      return a.asset_alias
        ?.toLowerCase()
        .localeCompare(b.asset_alias?.toLowerCase(), undefined, {
          numeric: true,
          sensitivity: "base",
        });
    });
    selectedAreaAssets = [...selectedAreaAssets, ...childAsset];
  });

  return { parentAssets, selectedAreaAssets };
};

/**
 * This function return data for Dyanmic Query component related data, i.e., for Dynamic Query
 *
 * @params selectedOption- string selected option from UI component level
 * @params selectedSeedData- seed data object array from redux store
 * @params currentUseCase string from component level
 * @params selectedPlant object from component level
 * @params levelsList object array from redux store
 * @params seedsList object array from redux store
 * @params usecaseList object array from redux store
 * @params currentUsecaseData object from redux store
 * @params selectedParentAsset object array from redux store
 * @params selectedAssetType object array from redux store
 * @params plantsList object array from redux store
 * @returns assets or area object array
 */

export const getDynamicQueryItems = ({
  selectedOption,
  selectedSeedData,
  currentUseCase,
  selectedPlant,
  levelsList,
  usecaseList,
  currentUseCaseData,
  selectedParentAsset,
  selectedAssetType,
  seedsList,
  plantsList
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = find(selectedSeedData?.seed_data_value, function (obj) {
    if (obj.key === currentUseCase) {
      return true;
    }
  });

  if (isEmpty(items)) return [];

  const parsedItems = JSON.parse(items.value);
  const itemsArray = parsedItems[selectedPlant.plant_code];

  const { selectedAreaAssets } = getDynamicQueryAreaAssets({
    selectedOption,
    selectedSeedData,
    currentUseCase,
    selectedPlant,
    levelsList,
    usecaseList,
    currentUseCaseData,
    selectedParentAsset,
    selectedAssetType,
    seedsList,
    plantsList,
  });

  const selectedAreaAssetsNames = selectedAreaAssets.map((asset: any) => asset.asset_name);

  const filteredAssets = itemsArray?.assetTags?.filter(
    (item: any) =>
      selectedAreaAssetsNames.includes(item.asset) || item.asset === "others"
  );
  const remainingAssets = itemsArray?.assetTags?.filter(
    (item: any) =>
      !(selectedAreaAssetsNames.includes(item.asset) || item.asset === "others")
  );

  return {
    ...itemsArray,
    assetTags: filteredAssets,
    remainingAssets
  };
};

// This function return data for WQMS Stag Parameter component related data, i.e., for WQMStagParameter
export const getWQMStagParameterItems = ({
  selectedOption,
  selectedSeedData
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  return items;
};

// This function return data for WQMS Recipe Mapping component related data, i.e., for WQMSRecipeMapping
export const getWQMSRecipeItems = ({
  selectedOption,
  selectedSeedData,
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  return items;
};

// This function return data for WQMS Plant Based Length component related data, i.e., for WQMSPlantBasedLength
export const getWQMSPlantBasedItems = ({
  selectedOption,
  selectedSeedData,
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  return items;
};

// This function return data for WQMS Plant Based Length component related data, i.e., for WQMSPlantBasedLength
export const getWQMSTagsEndingItems = ({
  selectedOption,
  selectedSeedData,
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  return items;
};

// This function return data for Tag Generator component related data, i.e., for TagGenerator
export const getTagGenerator = ({
  selectedOption,
  selectedSeedData,
  currentUseCase,
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  const currentUseCaseItem = find(items, function (usecase) {
    if (usecase?.key === currentUseCase) {
      return true;
    }
  });

  if (isEmpty(currentUseCaseItem)) return [];

  const currentUseCaseItemValue = JSON.parse(currentUseCaseItem.value);

  const modifiedItemsArray: any[] = [];

  if (isEmpty(currentUseCaseItemValue)) return [];

  Object.keys(currentUseCaseItemValue).map((key) => {
    const currentObject = currentUseCaseItemValue[key],
      objectToPush = { key: "", tag: "", description: "" };
    const length = get(currentObject, "tags.length", 0);

    if (length > 0) {
        objectToPush.key = key;
        objectToPush.tag = currentObject.tags[0];
        objectToPush.description = currentObject.description[0];
        modifiedItemsArray.push(clone(objectToPush));
    }
  });

  return modifiedItemsArray;
};

// This function return data before saving Tag Generator in API call
export const returnUpdatedTagGeneratorData = ({
  updatedConfiguration,
}: {
  updatedConfiguration: any[];
}) => {
  if (isEmpty(updatedConfiguration)) return [];

  const DEFAULT_OBJECT: { tags: string[]; description: string[] } = {
    tags: [],
    description: [],
  };

  const modifiedItemsObject = {};
  updatedConfiguration.map(
    (config: { key: string; tag: string; description: string }) => {
      if (isEmpty(get(modifiedItemsObject, `[${config.key}]`, null))) {
        set(modifiedItemsObject, `[${config.key}]`, cloneDeep(DEFAULT_OBJECT));
      }
      const currentValue = get(
        modifiedItemsObject,
        `[${config.key}]`,
        cloneDeep(DEFAULT_OBJECT)
      );
      currentValue.tags.push(config.tag);
      currentValue.description.push(config.description);
      set(modifiedItemsObject, `[${config.key}]`, currentValue);
    }
  );

  return modifiedItemsObject;
};

// This function return data for RH Gradient Config component related data, i.e., for RH - Gradient Config
export const getGradientConfig = ({
  selectedOption,
  selectedSeedData,
  selectedPlant,
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  if (isEmpty(items)) return [];

  let currentPlantItem = find(items, function (item) {
    if (item?.key === selectedPlant.plant_code) {
      return true;
    }
  });

  if (isEmpty(currentPlantItem))
    currentPlantItem = { key: selectedPlant.plant_code, value: "240 hours" };

  return currentPlantItem;
};

// This function return data for RH VP and PD Config component related data, i.e., for RH - VP and PD Config
export const getVPPDConfig = ({
  selectedOption,
  selectedSeedData,
  selectedPlant,
}: CCPsMonitoredItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  if (isEmpty(items)) return [];

  const currentPlantItem = find(items, function (item) {
    if (item?.key === selectedPlant.plant_code) {
      return true;
    }
  });

  let parsedItems = { VP: [], PD: [] };

  if (!isEmpty(currentPlantItem) || isJson(currentPlantItem))
    parsedItems = JSON.parse(currentPlantItem.value);

  return parsedItems;
};

// This function return data for RH Gantt Chart component related data, i.e., for RH - Gantt Chart
export const getRHGanttChartItems = ({
  selectedOption,
  selectedSeedData,
}: ListItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  return items;
};

// This function return data for RH VP and PD Config component related data, i.e., for RH - VP and PD Config
export const getRHFinalConfigItems = ({
  selectedOption,
  selectedSeedData,
  selectedPlant,
}: CCPsMonitoredItemsFunctionType) => {
  if (!selectedOption) return [];

  if (isEmpty(selectedSeedData)) return [];

  const items = get(selectedSeedData, "seed_data_value", []);

  if (isEmpty(items)) return [];

  const defaultPlantItem = find(items, function (item) {
    if (item?.key === "default") {
      return true;
    }
  });
  const currentPlantItem = find(items, function (item) {
    if (item?.key === selectedPlant.plant_code) {
      return true;
    }
  });

  let parsedItems = JSON.parse(defaultPlantItem.value);

  if (!isEmpty(currentPlantItem) || isJson(currentPlantItem))
    parsedItems = JSON.parse(currentPlantItem.value);

  return parsedItems;
};
