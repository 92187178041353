import {
  LOAD_SATPLANTS,
  LOAD_SATPLANTS_SUCCESS,
  LOAD_SATPLANTS_ERROR,
} from "../Types/satPlants.constants";
import axios from "../../Utils/AxiosConfig/axiosConfigSAT";
import { put, call, takeEvery } from "redux-saga/effects";

export const getPlants = async () => {
  const res = await axios.get(`getsatplants`);
  return res.data;
};

export function* listSATPlants(): any {
  try {
    const data = yield call(() =>
      getPlants().then((res) => {
        return res;
      })
    );
    yield put({
      type: LOAD_SATPLANTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_SATPLANTS_ERROR,
      payload: null,
    });
  }
}

export function* listSATPlantsSaga() {
  yield takeEvery(LOAD_SATPLANTS, listSATPlants);
}
