import moment from "moment";

export const createTicketStatus = [
  {
    name: "Open"
  }
];

export const pdfExtensions = ["pdf", "PDF"];
export const imageExtensions = ["png", "jpg", "jpeg", "PNG", "JPG", "JPEG"];
export const docExtentions = [
  "doc",
  "docs",
  "DOC",
  "DOCS",
  "docx",
  "DOCX",
  "txt",
  "TXT"
];
export const xlExtentions = ["xl", "xls", "xlsx", "XL", "XLS", "XLSX"];
export const csvExtentions = ["csv", "CSV"];
export const getSelectedPlantTypes = (plant: any): any => {
  const key = "type";
  const arrayUniqueByKey: any = [
    ...new Map(
      plant?.plant_actioner?.map((item: any) => [item[key], item])
    ).keys()
  ];

  // Uniques types,
  const uniqueTypes = arrayUniqueByKey.map((item: string) => {
    return {
      name: item
    };
  });
  return uniqueTypes;
};

export const getSelectedPlanRegion = (plant: any): any => {
  const uniqueRegion = [
    ...new Set(plant?.plant_actioner?.map((item: any) => item.region))
  ];

  return uniqueRegion?.[0] || "";
};

export const getUniqueLines = (plant: any, type: any) => {
  const uniqueLines: any = plant?.plant_actioner
    ?.map((obj: any) => {
      if (obj.type === type.name) {
        return {
          name: obj.resource_code
        };
      }
      return "";
    })
    .filter((item: any) => item !== "")
    .filter(
      (value: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t.name === value.name)
    );

  return uniqueLines;
};

export const getLineMpAndFgPlanners = (plant: any, line: any): any => {
  const uniqueLineArray = plant?.plant_actioner
    ?.map((obj: any) => {
      if (obj.resource_code === line.name) {
        return obj;
      }
      return "";
    })
    .filter((item: any) => item !== "");

  let fgPlnnerNames: any = [];
  let masterPlannerNames: any = [];
  let brandPlannerNames: any = [];

  uniqueLineArray?.map((item: any) => {
    if (item?.role?.length > 0) {
      item?.role?.map((eachRole: any) => {
        if (eachRole?.actioner_role === "FG Planner") {
          fgPlnnerNames = [...fgPlnnerNames, ...(eachRole?.actioner_list || [])];
        }

        if (eachRole?.actioner_role === "Core Master Planner") {
          masterPlannerNames = [
            ...masterPlannerNames,
            ...(eachRole?.actioner_list || [])
          ];
        }

        if (eachRole?.actioner_role === "Brand Planner") {
          brandPlannerNames = [
            ...brandPlannerNames,
            ...(eachRole?.actioner_list || [])
          ];
        }
      });
    }
  });
  return {
    mp_planner_names: masterPlannerNames,
    fg_planner_names: fgPlnnerNames,
    brand_planner_names: brandPlannerNames
  };
};

export const getDatesDifference = (startDate: any, endDate: any): number => {
  const date1 = moment(endDate);
  const date2 = moment(startDate);
  const days = date1.diff(date2, "days");
  return days;
};

export const dateTimeFormatter = (date: any, format = "YYYY-MM-DD") => {
  if (!date) {
    return "-";
  }
  const formattedDateAndTime = moment
    .utc(date)
    .tz("America/Los_Angeles")
    .format(format);
  return formattedDateAndTime;
};

export const adminStatusChangeOptions = [
  {
    name: "Open"
  },
  {
    name: "In Progress"
  },
  {
    name: "Master Planner Confirmed"
  },
  {
    name: "Finished Goods Planner Confirmed"
  },
  {
    name: "TBD"
  },
  {
    name: "Closed"
  },
  { name: "Rejected" }
];

export const mpPlannerStatusChangeOptions = [
  {
    name: "In Progress"
  },
  {
    name: "Master Planner Confirmed"
  },
  {
    name: "TBD"
  },
  {
    name: "Rejected"
  }
];
export const mpPlannerStatusChangeOptionsWithClose = [
  {
    name: "In Progress"
  },
  {
    name: "Master Planner Confirmed"
  },
  {
    name: "TBD"
  },
  {
    name: "Rejected"
  },
  {
    name: "Closed"
  }
];
export const fgPlannerStatusChangeOptions = [
  {
    name: "Finished Goods Planner Confirmed"
  },
  {
    name: "TBD"
  },
  {
    name: "Rejected"
  }
];
export const fgPlannerStatusChangeOptionsWithClose = [
  {
    name: "Finished Goods Planner Confirmed"
  },
  {
    name: "TBD"
  },
  {
    name: "Rejected"
  },
  {
    name: "Closed"
  }
];

export const closeOptions = [
  {
    name: "Closed"
  }
];
export const downtimeTicketStatusOptions = [
  { name: "None", label: "None" },
  {
    name: "Open",
    label: "Open"
  },
  {
    name: "In Progress",
    label: "In Progress"
  },
  {
    name: "Master Planner Confirmed",
    label: "MP Confirmed"
  },
  {
    name: "Finished Goods Planner Confirmed",
    label: "FG Confirmed"
  },
  {
    name: "TBD",
    label: "TBD"
  },
  {
    name: "Closed",
    label: "Closed"
  },
  { name: "Reject", label: "Rejected" }
];

export const brandPlannerStatusOptions = [
  {
    name: "In Progress"
  },
  {
    name: "Brand Planner Confirmed"
  },
  {
    name: "TBD"
  },
  {
    name: "Rejected"
  }
];
export const brandPlannerStatusOptionsWithClose = [
  {
    name: "In Progress"
  },
  {
    name: "Brand Planner Confirmed"
  },
  {
    name: "TBD"
  },
  {
    name: "Rejected"
  }
];

/**
 * Function to get ticket status option based on roles
 * Priority Order Admin > core master planner > fg planner
 * @param userPermissions
 * @returns
 */
export const getTicketStatusOptions = (
  userPermissions: any,
  ticketDetails: any,
  currentUser: any
): any => {
  if (ticketDetails?.dt_ticket_status === "Closed") {
    return [];
  }
  // User have olny one permission and those are Admin, NPI Analyst, plant manager
  if (
    userPermissions?.length === 1 &&
    (userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
      userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
      userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR"))
  ) {
    return [];
  }

  // User have olny one permission and those are core master planner and fg planner
  if (userPermissions?.length === 1) {
    if (userPermissions.includes("ARCH_SCM_CORE_MASTER_PLANNER")) {
      if (
        !ticketDetails?.master_planner?.master_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return mpPlannerStatusChangeOptions;
      }

      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return closeOptions;
      }
      return [];
    }
    if (userPermissions.includes("ARCH_SCM_FG_PLANNER")) {
      if (
        !ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return fgPlannerStatusChangeOptions;
      }
      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return closeOptions;
      }
      return [];
    }
    if (
      userPermissions.includes("ARCH_ADMIN") ||
      userPermissions.includes("ARCH_SCM_ADMIN")
    ) {
      return closeOptions;
    }
    return [];
  }

  // User has combination of roles.

  if (
    userPermissions.includes("ARCH_ADMIN") ||
    userPermissions.includes("ARCH_SCM_ADMIN") ||
    userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
    userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
    userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR")
  ) {
    // Core Master Planner
    if (
      userPermissions.includes("ARCH_SCM_CORE_MASTER_PLANNER") &&
      currentUser.mail === ticketDetails?.master_planner?.master_planner_mail
    ) {
      if (
        !ticketDetails?.master_planner?.master_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        if (
          userPermissions.includes("ARCH_ADMIN") ||
          userPermissions.includes("ARCH_SCM_ADMIN")
        ) {
          return mpPlannerStatusChangeOptionsWithClose;
        }
        return mpPlannerStatusChangeOptions;
      }

      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return closeOptions;
      }
    }
    // FG Planner
    if (userPermissions.includes("ARCH_SCM_FG_PLANNER")) {
      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        !ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser.mail ===
          ticketDetails?.finished_goods_planner?.finished_goods_planner_mail &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        if (
          userPermissions.includes("ARCH_ADMIN") ||
          userPermissions.includes("ARCH_SCM_ADMIN")
        ) {
          return fgPlannerStatusChangeOptionsWithClose;
        }
        return fgPlannerStatusChangeOptions;
      }
      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser.mail ===
          ticketDetails?.finished_goods_planner?.finished_goods_planner_mail &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return closeOptions;
      }
      return [];
    } // Admin
    if (
      userPermissions.includes("ARCH_ADMIN") ||
      userPermissions.includes("ARCH_SCM_ADMIN")
    ) {
      return closeOptions;
    }
    return [];
  }
  return [];
};

export const getTicketBeverageTypeOptions = (
  userPermissions: any,
  ticketDetails: any,
  currentUser: any
): any => {
  if (ticketDetails?.dt_ticket_status === "Closed") {
    return [];
  }

  // User have olny one permission and those are Admin, NPI Analyst, plant manager
  if (
    userPermissions?.length === 1 &&
    (userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
      userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
      userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR"))
  ) {
    return [];
  }

  // User have olny one permission and those are core master planner and fg planner
  if (userPermissions?.length === 1) {
    if (userPermissions.includes("ARCH_SCM_BRAND_PLANNER")) {
      if (
        !ticketDetails?.brand_planner?.brand_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return brandPlannerStatusOptions;
      }

      if (
        ticketDetails?.brand_planner?.brand_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return closeOptions;
      }

      return [];
    }
    if (
      userPermissions.includes("ARCH_ADMIN") ||
      userPermissions.includes("ARCH_SCM_ADMIN")
    ) {
      return closeOptions;
    }

    return [];
  }

  // User has combination of roles.

  if (
    userPermissions.includes("ARCH_ADMIN") ||
    userPermissions.includes("ARCH_SCM_ADMIN") ||
    userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
    userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
    userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR")
  ) {
    // Core Master Planner
    if (
      userPermissions.includes("ARCH_SCM_BRAND_PLANNER") &&
      currentUser.mail === ticketDetails?.brand_planner?.brand_planner_mail
    ) {
      if (
        !ticketDetails?.brand_planner?.brand_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        if (
          userPermissions.includes("ARCH_ADMIN") ||
          userPermissions.includes("ARCH_SCM_ADMIN")
        ) {
          return brandPlannerStatusOptionsWithClose;
        }
        return brandPlannerStatusOptions;
      }

      if (
        ticketDetails?.brand_planner?.brand_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return closeOptions;
      }
    }
    // Admin
    if (
      userPermissions.includes("ARCH_ADMIN") ||
      userPermissions.includes("ARCH_SCM_ADMIN")
    ) {
      return closeOptions;
    }
    return [];
  }
  return [];
};

export const getDiasableStateOfTicketStatus = (
  userPermissions: any,
  ticketDetails: any,
  currentUser: any
): boolean => {
  if (
    ticketDetails.dt_ticket_status === "Reject" ||
    ticketDetails.dt_ticket_status === "Closed"
  ) {
    return true;
  }

  if (
    userPermissions?.length === 1 &&
    (userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
      userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
      userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR"))
  ) {
    return true;
  }

  if (userPermissions?.length === 1) {
    if (userPermissions.includes("ARCH_SCM_CORE_MASTER_PLANNER")) {
      if (
        !ticketDetails?.master_planner?.master_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(
          ticketDetails?.plant_id?._id
        ) &&
        !ticketDetails?.finished_goods_planner?.finished_goods_planner_confirmed
      ) {
        return false;
      }

      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }

      return true;
    }
    if (userPermissions.includes("ARCH_SCM_FG_PLANNER")) {
      if (
        !ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(
          ticketDetails?.plant_id?._id
        ) &&
        ticketDetails?.master_planner?.master_planner_confirmed
      ) {
        return false;
      }

      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }

      return true;
    }
    if (
      userPermissions.includes("ARCH_ADMIN") ||
      userPermissions.includes("ARCH_SCM_ADMIN")
    ) {
      return false;
    }
    return true;
  }

  if (
    userPermissions.includes("ARCH_ADMIN") ||
    userPermissions.includes("ARCH_SCM_ADMIN") ||
    userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
    userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
    userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR")
  ) {
    // Core Master Planner
    if (
      userPermissions.includes("ARCH_SCM_CORE_MASTER_PLANNER") &&
      currentUser.mail === ticketDetails?.master_planner?.master_planner_mail
    ) {
      if (
        !ticketDetails?.master_planner?.master_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }

      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }
    }
    // FG Planner
    if (userPermissions.includes("ARCH_SCM_FG_PLANNER")) {
      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        !ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser.mail ===
          ticketDetails?.finished_goods_planner?.finished_goods_planner_mail &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }
      if (
        ticketDetails?.master_planner?.master_planner_confirmed &&
        ticketDetails?.finished_goods_planner
          ?.finished_goods_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }
      return true;
    }
    // Admin
    if (
      userPermissions.includes("ARCH_ADMIN") ||
      userPermissions.includes("ARCH_SCM_ADMIN")
    ) {
      return false;
    }
    return true;
  }
  return true;
};

export const getDiasableStateOfBeverageTicketStatus = (
  userPermissions: any,
  ticketDetails: any,
  currentUser: any
): boolean => {
  if (
    ticketDetails.dt_ticket_status === "Reject" ||
    ticketDetails.dt_ticket_status === "Closed"
  ) {
    return true;
  }

  if (
    userPermissions?.length === 1 &&
    (userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
      userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
      userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR"))
  ) {
    return true;
  }

  if (userPermissions?.length === 1) {
    if (userPermissions.includes("ARCH_SCM_BRAND_PLANNER")) {
      if (
        !ticketDetails?.brand_planner?.brand_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      } else if (
        ticketDetails?.brand_planner?.brand_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }
      return true;
    }
    if (
      userPermissions.includes("ARCH_ADMIN") ||
      userPermissions.includes("ARCH_SCM_ADMIN")
    ) {
      return false;
    }
    return true;
  }

  if (
    userPermissions.includes("ARCH_ADMIN") ||
    userPermissions.includes("ARCH_SCM_ADMIN") ||
    userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
    userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
    userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR")
  ) {
    // Brand Planner
    if (
      userPermissions.includes("ARCH_SCM_BRAND_PLANNER") &&
      currentUser.mail === ticketDetails?.brand_planner?.brand_planner_mail
    ) {
      if (
        !ticketDetails?.brand_planner?.brand_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }

      if (
        ticketDetails?.brand_planner?.brand_planner_confirmed &&
        currentUser?.scm_user_plant_group.includes(ticketDetails?.plant_id?._id)
      ) {
        return false;
      }

      return true;
    }
    // Admin
    if (
      userPermissions.includes("ARCH_ADMIN") ||
      userPermissions.includes("ARCH_SCM_ADMIN")
    ) {
      return false;
    }
    return true;
  }
  return true;
};

export const getTicketOptions = (userPermissions: any, ticketDetails: any) => {
  if (
    userPermissions.includes("ARCH_ADMIN") ||
    userPermissions.includes("ARCH_SCM_ADMIN")
  ) {
    if (ticketDetails.dt_ticket_status === "Open") {
      return [
        {
          label: "Edit Ticket",
          key: "edit_ticket"
        },
        {
          label: "Export Ticket",
          key: "export_ticket"
        },
        {
          label: "Delete Ticket",
          key: "delete_ticket"
        }
      ];
    }
    return [
      // {
      //   label: "Edit Ticket",
      //   key: "edit_ticket"
      // },
      {
        label: "Export Ticket",
        key: "export_ticket"
      },
      {
        label: "Delete Ticket",
        key: "delete_ticket"
      }
    ];
  } else {
    return [
      {
        label: "Export Ticket",
        key: "export_ticket"
      }
    ];
  }
};

/**
 * Function to check user has only fg planner role
 * @param userPermissions
 * @returns
 */
export const userHasOnlyFgPlannerRole = (userPermissions: any): boolean => {
  if (
    userPermissions?.length === 1 &&
    userPermissions.includes("ARCH_SCM_FG_PLANNER")
  ) {
    return true;
  } else {
    return false;
  }
};

export const getTimeDifference = (startDateString: any, endDateString: any) => {
  if (!startDateString || !endDateString) {
    return "-";
  }
  const startDate: any = new Date(startDateString);
  const endDate: any = new Date(endDateString);

  const timeDiff = Math.abs(endDate - startDate);

  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hoursDiff = Math.floor(
    (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  return `${daysDiff} Days, ${hoursDiff}Hrs`;
};

export const removeHtmlTags = (s: string) => {
  const pattern = /(<([^>]+)>)/gi;
  // to erase every tags enclosed in <>
  s = new String(s).replace(pattern, "");
  return s;
};

export const getImportTicketsOption = (userPermissions: any): boolean => {
  if (
    userPermissions.includes("ARCH_ADMIN") ||
    userPermissions.includes("ARCH_SCM_NPI_ANALYST") ||
    userPermissions.some((element: string) => element.includes("ARCH_PM")) ||
    userPermissions.includes("ARCH_SCM_PLANT_DIRECTOR") ||
    userPermissions.includes("ARCH_SCM_ADMIN")
  ) {
    return false;
  }
  return true;
};
