import MuiAlert, { AlertProps } from '@mui/material/Alert'
import * as React from 'react'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
	function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
	}
)

export default Alert
