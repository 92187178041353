import { all } from 'redux-saga/effects'
import { listUseCasesSaga } from './Actions/usecase.actions'
import { listLocationsSaga } from './Actions/locations.actions'
import { listPlantsSaga } from './Actions/plants.actions'
import { listLevelsSaga } from './Actions/levels.actions'
import { listAssetsSaga } from './Actions/assets.actions'
// import { listReportsSaga } from "./Actions/reports.actions";
import { listDashboardsSaga } from './Actions/dashboards.actions'
// import { listMappedReportsSaga } from "./Actions/loadReports.actions";
// import { listAPIReportsSaga } from "./Actions/reportsAPI.actions";
import { listAssetTypesSaga } from './Actions/assetTypes.actions'
import { listOEMSaga } from './Actions/OEM.actions'
import { listModelsSaga } from './Actions/models.actions'
// import { listPlantsReportsSaga } from "./Actions/plantsReports.actions";
import { listTicketsSaga } from './Actions/tickets.actions'
import { listMessagesSaga } from './Actions/messages.actions'
import { listChatroomSaga } from './Actions/chatroom.actions'
import { addNotificationsSaga } from './Actions/notifications.actions'
import { listDevicesSaga } from './Actions/devices.actions'
import { listMachinesSaga } from './Actions/machines.actions'
import { listSensorsSaga } from './Actions/sensors.actions'
import { listUsersSaga } from './Actions/users.actions'
import { listUserPermissionsSaga } from './Actions/userPermissions.actions'
import { addSnackBarSaga } from './Actions/snackBar.actions'
import { userImagesSaga } from './Actions/usersImages.actions'
import { listKpisSaga } from './Actions/kpis.actions'
import { listVisualsSaga } from './Actions/visuals.actions'
import { listAliasSaga } from './Actions/alias.actions'
import { listSeedsSaga } from './Actions/seeds.actions'
import { addBannerSaga } from './Actions/banner.actions'
import { listSATPlantsSaga } from './Actions/satPlants.actions'
import { listHierarchySATPlantsSaga } from './Actions/satHierarchy.actions'
import { listSatKpiDataSaga } from './Actions/satKpiData.actions'
import { listSATAnnotationsSaga } from './Actions/satAnnotations.actions'
import { listUserAccess } from './Actions/userAccess.actions'
import { listTroubleAssetSaga } from './Actions/troubleshoot.actions'
import { listSATDetailsSaga } from './Actions/satDetails.actions'
import { listCIPWashSaga } from './Actions/cipWash.actions'
import { lgvHealthSaga } from './Actions/lgvHealth.actions'
import { schedulerSaga } from './Actions/scheduler.action'

export default function* rootSaga() {
	yield all([
		listLocationsSaga(),
		listUseCasesSaga(),
		listPlantsSaga(),
		listAssetsSaga(),
		listLevelsSaga(),
		// listReportsSaga(),
		listDashboardsSaga(),
		// listMappedReportsSaga(),
		// listAPIReportsSaga(),
		listAssetTypesSaga(),
		listOEMSaga(),
		listModelsSaga(),
		// listPlantsReportsSaga(),
		listTicketsSaga(),
		listMessagesSaga(),
		listChatroomSaga(),
		addNotificationsSaga(),
		listDevicesSaga(),
		listMachinesSaga(),
		listSensorsSaga(),
		listUsersSaga(),
		listUserPermissionsSaga(),
		addSnackBarSaga(),
		userImagesSaga(),
		listKpisSaga(),
		listVisualsSaga(),
		listAliasSaga(),
		listSeedsSaga(),
		addBannerSaga(),
		listSATPlantsSaga(),
		listHierarchySATPlantsSaga(),
		listSatKpiDataSaga(),
		listSATAnnotationsSaga(),
		listTroubleAssetSaga(),
		listSATDetailsSaga(),
		listUserAccess(),
		listCIPWashSaga(),
		lgvHealthSaga(),
		schedulerSaga()
	])
}
