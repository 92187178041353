import { call, put, takeEvery } from 'redux-saga/effects'
import {
	LOAD_LGV_LIVE_DATA,
	UPDATE_LGV_LIVE_DATA,
	LOAD_LGV_LIVE_DATA_ERROR,
	LOAD_LGV_LIVE_DATA_SUCCESS,
	LOAD_PLS_LIVE_DATA,
	LOAD_PLS_LIVE_DATA_SUCCESS,
	LOAD_PLS_LIVE_DATA_ERROR
} from '../Types/lgvHealth.constants'
import Axios from '../../Utils/AxiosConfig/axiosConfig'
import store from '../store'

// Interfaces
interface ResponseData {
	deviceName: string
	description: string
	timestamp: number
	tag?: string
	tagName?: string
	value: string
	location: string
	__v?: number
}

interface BatchRecord {
	key?: string | null
	offset?: number
	partition?: number
	timestamp?: number
	topic?: string
	value: {
		DATATYPE?: string
		DESCRIPTION: string
		DEVICEID?: string
		DEVICENAME: string
		REGISTERID?: string
		SUCCESS?: string
		TAGNAME: string
		TIMESTAMP: string
		VALUE: string
	}
}

// Constants
const tagMap = {
	// Navigation Quality
	NavigationalQuality_PV: 'navQuality',
	LGVX: 'xPos',
	LGVY: 'yPos',
	LGVHeadingAngle_PV: 'headingAngle',
	CurrentSegmentID_PV: 'segmentId',
	NavigationSeenReflectors_PV: 'NavigationSeenReflectors',
	NavigationUsedReflectors_PV: 'NavigationUsedReflectors',

	// PlS Sensors
	PLSVerticalRightSensorDirty_COV: 'PLSVerticalRightSensorDirty',
	PLSVerticalLeftSensorDirty_COV: 'PLSVerticalLeftSensorDirty',
	PLSBackwardRightSensorDirty_COV: 'PLSBackwardRightSensorDirty',
	PLSBackwardLeftSensorDirty_COV: 'PLSBackwardLeftSensorDirty',
	PLSBackwardSensorDirty_COV: 'PLSBackwardSensorDirty',
	PLSForwardSensorDirty_COV: 'PLSForwardSensorDirty',

	// Hydraulic Health
	HydraulicHealthLevel: 'HydraulicHealthLevel',
	Pump1InverterTemperature_PV: 'pump1InverterTemperature',
	Pump1MotorTemperature_PV: 'pump1MotorTemperature',
	Pump1MotorTorque_PV: 'pump1MotorTorque',
	Pump1MotorSpeed_Scaled_PV: 'pump1MotorSpeed',
	OilPumpCloggedFilter1_STAT: 'OilPumpCloggedFilter1',
	OilPumpCloggedFilter2_STAT: 'OilPumpCloggedFilter2',

	// Battery Health
	StateofCharge_PV: 'StateofCharge',
	BatteryChargeCurrent_PV: 'BatteryChargeCurrent',
	BatteryCapacity_PV: 'BatteryCapacity',
	MaxBatteryCapacity: 'MaxBatteryCapacity',
	_BatteryVoltage_Scaled_PV: 'BatteryVoltage',
	BatteryHighestCellTemp_PV: 'BatteryHighestCellTemp',
	BatteryLowestCellTemp_PV: 'BatteryLowestCellTemp',
	CyclesFromRephasing_PV: 'CyclesFromRephasing',
	CyclesFromBalancing_PV: 'CyclesFromBalancing',
	TotalCycles_PV: 'TotalCycles',

	// Inverter Health
	InverterHealthLevel: 'InverterHealthLevel',
	Drive1InverterTemperature_PV: 'drive1InverterTemperature',
	Drive2InverterTemperature_PV: 'Drive2InverterTemperature',
	Steer1InverterTemperature_PV: 'Steer1InverterTemperature',
	Steer2InverterTemperature_PV: 'Steer2InverterTemperature'
	// Pump1InverterTemperature_PV: "pump1InverterTemperature",
}

// Helper Functions
const getAllLGVData = async (plant: any): Promise<{ data: ResponseData[] }> => {
	const plantCode = plant?.plant_name?.replace(' ', '')
	const response = await Axios.get(`lgvData/allLgvRecords/${plantCode}`)
	return response?.data
}

const updateLGVRecord = (response: ResponseData, lgvData: ResponseData[]) => {
	const deviceName = response.deviceName
	if (deviceName.includes('LGV')) {
		let device: any = lgvData.find((d: any) => d.deviceName === deviceName)

		if (!device) {
			device = { deviceName }
			lgvData.push(device)
		}

		device.timestamp = response.timestamp
		device.convertedTimestamp = new Date(response.timestamp)

		for (const [key, value] of Object.entries(tagMap)) {
			if (response.tag?.includes(key) || response.tagName?.includes(key)) {
				device[value] = response.value
			}
		}
	}
}

const processBatchUpdateLGVRecords = (
	batchData: { plant: any; data: BatchRecord[] },
	lgvData: ResponseData[]
) => {
	if (!Array.isArray(batchData.data) || !Array.isArray(batchData.plant)) {
		return
	}

	const { plant, data } = batchData
	const selectedPlant = plant[0]
	const plantName = selectedPlant?.plant_name?.replace(' ', '')

	for (const eachMessage of data) {
		const { DEVICENAME, VALUE, TIMESTAMP, TAGNAME, DESCRIPTION } =
			eachMessage.value
		if (TAGNAME.includes(plantName)) {
			const responseObject: ResponseData = {
				deviceName: DEVICENAME,
				description: DESCRIPTION,
				timestamp: Number(TIMESTAMP),
				tagName: TAGNAME,
				value: VALUE,
				location: TAGNAME?.split('_')[0] || ''
			}
			updateLGVRecord(responseObject, lgvData)
		}
	}
}

const fetchPlsData = async (plant_code: string) => {
	try {
		const plantCode = plant_code?.replace('LGV', '')
		const liveData = await Axios.get(
			`lgvData/plsLiveSensorRecords?whse=${plantCode}`
		)
		return liveData.data?.data || []
	} catch (error) {
		return error
	}
}

// ACTIONS
export function* getLgv(action: any): any {
	try {
		// Fetch the Data
		const response = yield call(getAllLGVData, action?.payload)
		// Do the Transformation
		const lgvData: any = []
		response?.data?.forEach((response: any) =>
			updateLGVRecord(response, lgvData)
		)
		// Dispatch to Reducer
		yield put({
			type: LOAD_LGV_LIVE_DATA_SUCCESS,
			payload: lgvData
		})
	} catch (error: any) {
		const errorMessage =
			error?.response?.data?.message || error.message || 'Unknown error'
		yield put({
			type: LOAD_LGV_LIVE_DATA_ERROR,
			payload: errorMessage
		})
	}
}

export function* updateLgv(action: any): any {
	// Do the Transformation
	const lgvHealth = store.getState().lgvHealth
	if (lgvHealth?.liveData?.length > 0) {
		const lgvData: any = lgvHealth?.liveData
		processBatchUpdateLGVRecords(action?.payload, lgvData)
		// Dispatch to Reducer
		yield put({
			type: LOAD_LGV_LIVE_DATA_SUCCESS,
			payload: lgvData
		})
	}
}

export function* getPlsLiveData(action: any): any {
	try {
		const response = yield call(fetchPlsData, action?.payload.plantCode)
		yield put({
			type: LOAD_PLS_LIVE_DATA_SUCCESS,
			payload: response
		})
	} catch (error: any) {
		const errorMessage =
			error?.response?.data?.message || error.message || 'Unknown error'
		yield put({
			type: LOAD_PLS_LIVE_DATA_ERROR,
			payload: errorMessage
		})
	}
}

export function* lgvHealthSaga() {
	yield takeEvery(LOAD_LGV_LIVE_DATA, getLgv)
	yield takeEvery(UPDATE_LGV_LIVE_DATA, updateLgv)
	yield takeEvery(LOAD_PLS_LIVE_DATA, getPlsLiveData)
}
