import {
  LOAD_USER_PERMISSIONS,
  LOAD_USER_PERMISSIONS_SUCCESS,
  LOAD_USER_PERMISSIONS_ERROR,
  UPDATE_USER_PERMISSION,
  UPDATE_USER_PERMISSIONS_SUCCESS,
  UPDATE_USER_PERMISSIONS_ERROR,
  DELETE_USER_PERMISSION,
  DELETE_USER_PERMISSIONS_SUCCESS,
  DELETE_USER_PERMISSIONS_ERROR,
} from "../Types/userPermissions.constants";

// import axios from "axios";
import axios from "../../Utils/AxiosConfig/axiosConfig";
import { put, call, takeEvery } from "redux-saga/effects";

//function to get all userPermissions from the api
const getUserPermissions = async () => {
  let userPermissions: any = [];
  let res = null,
    page = 1;
  //loop to fetch all pages from api
  do {
    res = await axios.get(`userPermissions?pageSize=100&page=${page++}`);
    userPermissions = userPermissions.concat(res.data.rows);
  } while (res.data.page < res.data.totalPages);
  return userPermissions;
};

export function* listUserPermissions(): any {
  try {
    const data = yield call(() =>
      getUserPermissions().then((res) => {
        return res;
      })
    );

    yield put({
      type: LOAD_USER_PERMISSIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: LOAD_USER_PERMISSIONS_ERROR,
      payload: null,
    });
  }
}

export function* updateUserPermission(action: any): any {
  try {
    yield put({
      type: UPDATE_USER_PERMISSIONS_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: UPDATE_USER_PERMISSIONS_ERROR,
      payload: null,
    });
  }
}

export function* deleteUserPermission(action: any): any {
  try {
    yield put({
      type: DELETE_USER_PERMISSIONS_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: DELETE_USER_PERMISSIONS_ERROR,
      payload: null,
    });
  }
}

export function* listUserPermissionsSaga() {
  yield takeEvery(LOAD_USER_PERMISSIONS, listUserPermissions);
  yield takeEvery(UPDATE_USER_PERMISSION, updateUserPermission);
  yield takeEvery(DELETE_USER_PERMISSION, deleteUserPermission);
}
