import { Checkbox, Theme } from '@material-ui/core'
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
	clone,
	cloneDeep,
	find,
	get,
	isBoolean,
	isEmpty,
	isString,
	set,
	unset
} from 'lodash'
import React, { useEffect, useState } from 'react'
import {
	ALPHANUMERIC_VALIDATION_REGEX,
	TAG_VALIDATION_REGEX
} from '../../Utils/SelfServiceHelper'
import WQMSTagsEndingString from './WQMSTagsEndingString'

const useStyles = makeStyles((theme: Theme) => ({
	textBg: {
		'&.MuiTypography-subtitle2': {
			color: theme.palette.mode === 'dark' ? '#FFF' : ''
		}
	},
	activeButton: {
		'&.MuiIconButton-root': {
			color: theme.palette.mode === 'dark' ? '#FFF' : '',
			backgroundColor: '#8476EF',
			width: '5em',
			padding: '5px 5px',
			'&:hover': {
				backgroundColor: '#8476EF'
			},
			border: '1px solid #8476EF'
		}
	},
	inactiveButton: {
		'&.MuiIconButton-root': {
			color: theme.palette.mode === 'dark' ? '' : '#FFF',
			backgroundColor: '#232A58',
			padding: '5px 5px',
			width: '5em',
			'&:hover': {
				backgroundColor: '#8476EF33'
			},
			border: '1px solid #7D7D7D'
		}
	}
}))

const RHFinalConfig = (props: any) => {
	const { items: itemData, onSeqChange, onError, resetListItems } = props
	const classes = useStyles()
	const [items, setItems] = useState<any>(itemData)
	const [error, setError] = useState<any>({})
	const [selectedLine, selectLine] = useState('')
	const [selectedTab, selectTab] = useState('Area Config')
	const [StatusError, setStatusError] = useState<any>({})

	useEffect(() => {
		setItems([...itemData])
	}, [itemData])
	const handleChangeAreaConfig = (value: boolean | string, key: string) => {
		const updatedData = clone(items)

		if (
			!isBoolean(value) &&
			(isEmpty(value) || !TAG_VALIDATION_REGEX.test(value))
		) {
			onError(true)
			setError({ areaConfigTag: 'Invalid value' })
		} else {
			updatedData.forEach((data: any) => {
				if (data.Line === selectedLine) {
					set(data, `config.areaConfig.${key}`, value)
				}
			})
			onSeqChange(updatedData, false)

			if (!isBoolean(value)) {
				setError({})
				onError(false)
			}
		}
	}

	const checkErrorUnderCurrentSelection = () => {
		let isErrorPresent = false

		Object.keys(StatusError).forEach(indexKey => {
			Object.keys(StatusError[indexKey]).forEach(key => {
				if (isEmpty(StatusError[indexKey][key])) isErrorPresent = true
			})
		})

		return isErrorPresent
	}

	const handleItemValueChange = (
		data: any,
		editIndex: number,
		updatedKey = ''
	) => {
		const clonedUpdatedItems = cloneDeep(items)

		const displayValueCondition =
			(updatedKey === 'buttonText' ||
				updatedKey === 'filterKey' ||
				updatedKey === 'frequency') &&
			(isEmpty(data[updatedKey]) ||
				!ALPHANUMERIC_VALIDATION_REGEX.test(data[updatedKey]))
		const valueCondition = isEmpty(data[updatedKey])
		if (displayValueCondition || valueCondition) {
			const clonedError = clone(StatusError)
			setStatusError({
				...clonedError,
				[editIndex]: {
					...clonedError[editIndex],
					[updatedKey]: 'Invalid value'
				}
			})

			onError(true)
		} else {
			set(
				clonedUpdatedItems,
				`[0].config.buttonConfig.values`,
				get(clonedUpdatedItems, `[0].config.buttonConfig.values`, []).map(
					(item: any, index: number) => {
						if (editIndex === index) {
							return data
						}
						return item
					}
				)
			)
			const clonedStatusError = clone(StatusError)

			if (clonedStatusError[editIndex])
				delete clonedStatusError[editIndex][updatedKey]

			setStatusError({
				...clonedStatusError
			})
			const isErrorPresent = checkErrorUnderCurrentSelection()
			onSeqChange(clonedUpdatedItems, !isErrorPresent)
		}
	}

	const addNew = () => {
		const clonedUpdatedItems = items
		if (!clonedUpdatedItems[0].config.buttonConfig.values) {
			clonedUpdatedItems[0].config.buttonConfig = {
				values: [],
				isEnabled: clonedUpdatedItems[0].config.buttonConfig.isEnabled
			}
		}

		clonedUpdatedItems[0].config.buttonConfig.values.push({
			filterKey: '',
			buttonText: '',
			frequency: ''
		})
		onSeqChange(clonedUpdatedItems, false)
	}

	const removeItem = (index: any) => {
		const clonedUpdatedItems = cloneDeep(items)
		set(
			clonedUpdatedItems,
			'[0].config.buttonConfig.values',
			clonedUpdatedItems[0].config.buttonConfig.values.filter(
				(_: any, i: any) => i !== index
			)
		)
		const clonedStatusError = clone(StatusError)

		delete clonedStatusError[index]

		onSeqChange(clonedUpdatedItems, false)
	}

	const checkAndSaveGradientTag = (item: any) => {
		const clonedUpdatedItems = cloneDeep(items)
		if (item.length === 1) {
			set(clonedUpdatedItems, '[0].config.visualConfig.gradientTag', item[0])
		} else {
			set(clonedUpdatedItems, '[0].config.visualConfig.gradientTag', item)
		}

		onSeqChange(clonedUpdatedItems, false)
	}

	const checkAndSaveGaugeTag = (item: any) => {
		const clonedUpdatedItems = cloneDeep(items)
		if (item.length === 1) {
			set(clonedUpdatedItems, '[0].config.visualConfig.gaugeTag', item[0])
		} else {
			set(clonedUpdatedItems, '[0].config.visualConfig.gaugeTag', item)
		}

		onSeqChange(clonedUpdatedItems, false)
	}

	const handleChangeButtonConfig = (value: boolean | string, key: string) => {
		const updatedData = clone(items)

		if (!isBoolean(value) && isEmpty(value)) {
			onError(true)
			setError({ buttonConfigValue: 'Invalid value' })
		} else {
			updatedData.forEach((data: any) => {
				if (data.Line === selectedLine) {
					set(data, `config.buttonConfig.${key}`, value)
					if (value === false) {
						unset(data, 'config.buttonConfig.values')
						set(data, `config.buttonConfig.buttonConfig`, [])
					}
				}
			})

			onSeqChange(updatedData, false)

			if (!isBoolean(value)) {
				setError({})
				onError(false)
			}
		}
	}

	const handleChangeVisualConfig = (
		value: boolean | string,
		key: 'gaugeTag' | 'gradientTag' | 'isActionable'
	) => {
		const updatedData = clone(items)

		const keyMapper = {
			isActionable: '',
			gaugeTag: 'visualConfigGaugeTag',
			gradientTag: 'visualConfigGradientTag'
		}

		if (
			!isBoolean(value) &&
			(isEmpty(value) || !TAG_VALIDATION_REGEX.test(value))
		) {
			onError(true)
			setError({ [keyMapper[key]]: 'Invalid value' })
		} else {
			updatedData.forEach((data: any) => {
				if (data.Line === selectedLine) {
					set(data, `config.visualConfig.${key}`, value)
				}
			})

			onSeqChange(updatedData, false)

			if (!isBoolean(value)) {
				setError({})
				onError(false)
			}
		}
	}

	if (isEmpty(items)) return null

	const statusOptions = items.map((item: any) => item.Line)
	const tabs = ['Area Config', 'Button Config', 'Visual Config']
	const currentLineObj = find(items, (item: any) => {
		if (item.Line === selectedLine) return true
	})

	return (
		<div className='selfConfigComponent'>
			<FormControl
				variant='outlined'
				fullWidth
				size='medium'
				sx={{ marginTop: '16px' }}
			>
				<InputLabel id='demo-multiple-name-label'>Select Line</InputLabel>
				<Select
					value={selectedLine}
					onChange={(event: any) => {
						setError({})
						selectLine(event.target.value)
					}}
					input={<OutlinedInput label='Select Status' />}
				>
					{statusOptions.map((option: string) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			{!isEmpty(selectedLine) ? (
				<Box style={{ margin: '2.5em 0em 1em 0em' }}>
					{tabs.map((ele: any, index: any) => {
						return (
							<IconButton
								className={
									selectedTab === ele
										? classes.activeButton
										: classes.inactiveButton
								}
								style={{
									borderRadius:
										index === 0
											? '20px 0px 0px 20px'
											: index === tabs.length - 1
												? '0px 20px 20px 0px'
												: '0px 0px 0px 0px'
								}}
								color='primary'
								onClick={() => {
									selectTab(ele)
									resetListItems()
								}}
							>
								<Typography
									ml={1}
									variant='subtitle2'
									className={selectedTab === ele ? classes.textBg : ''}
									sx={{ marginLeft: 0 }}
								>
									<b>{ele}</b>
								</Typography>
							</IconButton>
						)
					})}
				</Box>
			) : null}

			{!isEmpty(selectedLine) && selectedTab === 'Area Config' ? (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleChangeAreaConfig(event.target.checked, 'isActionable')
								}}
								checked={get(
									currentLineObj,
									'config.areaConfig.isActionable',
									false
								)}
								name='isActionable'
							/>
						}
						label='Is Actionable'
					/>
					<TextField
						defaultValue={get(currentLineObj, 'config.areaConfig.tag', '')}
						value={get(currentLineObj, 'config.areaConfig.tag', '')}
						label='Area Config Tag'
						sx={{ marginTop: '8px' }}
						onChange={event =>
							handleChangeAreaConfig(event.target.value, 'tag')
						}
						error={!!error?.areaConfigTag}
						helperText={error?.areaConfigTag || ''}
					/>
				</div>
			) : null}

			{!isEmpty(selectedLine) && selectedTab === 'Button Config' ? (
				<>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleChangeButtonConfig(event.target.checked, 'isEnabled')
								}}
								checked={get(
									currentLineObj,
									'config.buttonConfig.isEnabled',
									false
								)}
								name='isEnabled'
							/>
						}
						label='Is Enabled'
					/>
					{get(currentLineObj, 'config.buttonConfig.isEnabled', false) && (
						<div style={{ marginTop: '16px' }}>
							{get(currentLineObj, 'config.buttonConfig.values', [])?.map(
								(item: any, index: number) => (
									<React.Fragment key={`${index + 1}`}>
										<Grid
											container
											style={{ margin: '0em 0em', padding: '0em' }}
											spacing={2}
											key={item}
										>
											<Grid style={{ padding: '8px 8px 0 0' }} item xs={4}>
												<TextField
													defaultValue={item.buttonText}
													value={item.buttonText}
													label='Button Text'
													type='text'
													fullWidth
													onChange={(event: any) => {
														const sendData = {
															...item,
															buttonText: event.target.value
														}
														handleItemValueChange(sendData, index, 'buttonText')
													}}
													sx={{
														marginTop: '16px'
													}}
													error={!!StatusError[index]?.buttonText}
													helperText={StatusError[index]?.buttonText || ''}
												/>
											</Grid>
											<Grid style={{ padding: '8px 8px 0 0' }} item xs={4}>
												<TextField
													defaultValue={item.filterKey}
													value={item.filterKey}
													label='Filter Key'
													type='text'
													fullWidth
													onChange={(event: any) => {
														const sendData = {
															...item,
															filterKey: event.target.value
														}
														handleItemValueChange(sendData, index, 'filterKey')
													}}
													sx={{
														marginTop: '16px'
													}}
													error={!!StatusError[index]?.filterKey}
													helperText={StatusError[index]?.filterKey || ''}
												/>
											</Grid>
											<Grid style={{ padding: '8px 16px 0 0' }} item xs={3}>
												<TextField
													defaultValue={item.frequency}
													value={item.frequency}
													label='Frequency'
													type='text'
													fullWidth
													onChange={(event: any) => {
														const sendData = {
															...item,
															frequency: event.target.value
														}
														handleItemValueChange(sendData, index, 'frequency')
													}}
													sx={{
														marginTop: '16px'
													}}
													error={!!StatusError[index]?.frequency}
													helperText={StatusError[index]?.frequency || ''}
												/>
											</Grid>
											<Grid
												item
												xs={1}
												onClick={() => {
													removeItem(index)
												}}
												style={{ marginTop: '16px' }}
											>
												x
											</Grid>
										</Grid>
									</React.Fragment>
								)
							)}

							<Button
								style={{ marginTop: '10px' }}
								variant='outlined'
								onClick={() => {
									addNew()
								}}
							>
								+ Add New
							</Button>
						</div>
					)}
				</>
			) : null}

			{!isEmpty(selectedLine) && selectedTab === 'Visual Config' ? (
				<>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleChangeVisualConfig(event.target.checked, 'isActionable')
								}}
								checked={get(
									currentLineObj,
									'config.visualConfig.isActionable',
									false
								)}
								name='isActionable'
							/>
						}
						label='Is Actionable'
					/>
					{get(currentLineObj, 'config.visualConfig.isActionable', false) ? (
						<>
							<div>Visual Config GaugeTag</div>

							<WQMSTagsEndingString
								items={
									isString(items[0].config.visualConfig.gaugeTag)
										? [items[0].config.visualConfig.gaugeTag]
										: get(currentLineObj, 'config.visualConfig.gaugeTag', [])
								}
								onSeqChange={checkAndSaveGaugeTag}
								onError={onError}
								isUseCaseRunHours={true}
							/>

							<div style={{ marginTop: '16px' }}>
								Visual Config Gradient Tag
							</div>

							{
								<WQMSTagsEndingString
									items={
										isString(items[0].config.visualConfig.gradientTag)
											? [items[0].config.visualConfig.gradientTag]
											: get(
													currentLineObj,
													'config.visualConfig.gradientTag',
													[]
												)
									}
									onSeqChange={checkAndSaveGradientTag}
									onError={onError}
									isUseCaseRunHours={true}
								/>
							}
						</>
					) : null}
				</>
			) : null}
		</div>
	)
}

export default RHFinalConfig
