import Axios from '../Utils/AxiosConfig/axiosConfig'
import getUserProfile from './GraphAPI'
import store from '../Redux/store'
import { loadSnackbar } from '../Utils/util'

const getUsers = async () => {
	let users: any = []
	let res = null,
		page = 1
	//loop to fetch all pages from api
	do {
		res = await Axios.get(`users?page=${page++}`)
		users = users.concat(res.data.rows)
	} while (res.data.page < res.data.totalPages)
	return users
}

const CreateUser = (token: any, userDetails: any) => {
	const usersPromise = getUsers()

	usersPromise.then(response => {
		if (
			response.filter((user: any) => {
				return user.user_ad_id === userDetails.localAccountId
			}).length === 0
		) {
			const graphResponse: any = getUserProfile(token)
			graphResponse.then((response: any) => {
				const requestBody = {
					user_ad_id: userDetails.localAccountId,
					user_ad_name: userDetails.name,
					user_ad_firstname: response.givenName,
					user_ad_lastname: response.surname,
					user_ad_location: response.officeLocation,
					organization_id: '6082b4d50e4acb04e8b5df29',
					settings: {
						teams_notification: {
							receiveNotification: false,
							healthStatus: ''
						},
						email_notification: {
							receiveNotification: false,
							healthStatus: ''
						},
						sms_notification: {
							receiveNotification: false,
							healthStatus: ''
						},
						web_notification: {
							receiveNotification: false,
							healthStatus: ''
						},
						primary_email_address: userDetails.username,
						preferred_theme: 'Dark'
					},
					business_phones: [],
					display_name: response.displayName,
					given_name: response.givenName,
					job_title: response.jobTitle,
					mail: response.mail,
					mobile_phone: response.mobilePhone,
					office_location: response.officeLocation,
					surname: response.surname,
					user_ad_group: userDetails.idTokenClaims?.roles,
					user_principal_name: response.userPrincipalName
				}
				Axios.post(`users`, requestBody)
					.then(() => {
						store.dispatch({ type: 'LOAD_USERS' })
					})
					.catch(() => store.dispatch(loadSnackbar()))
			})
		}
	})
}

export default CreateUser
