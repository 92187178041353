import {
	Autocomplete,
	Chip,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip
} from '@mui/material'
import { cloneDeep, get, isEmpty, set } from 'lodash'
import { useEffect, useState } from 'react'
import { TAG_VALIDATION_REGEX } from '../../Utils/SelfServiceHelper'

const DynamicQuery = (props: any) => {
	const { items, onSeqChange, selectedAreaAssets, isModified, onError } = props

	const [assetData, setAssetData] = useState({})
	const [error, setError] = useState<any>({
		tag: false,
		value: false
	})

	useEffect(() => {
		if (!isEmpty(items)) {
			const { data } = getUpdated(get(assetData, 'asset', ''))
			setAssetData(data)
		}
	}, [isModified, items])

	const handleMasterTagChange = (value: string[]) => {
		const clonedUpdatedItems = cloneDeep(items)
		set(clonedUpdatedItems, 'masterTag', value)
		onSeqChange(clonedUpdatedItems)
	}

	const getUpdated = (value: string) => {
		let isNew = true

		const defaultAssetData = {
			asset: value,
			tag: '',
			value: { 0: 'running', 1: 'notrunning' }
		}

		let updatedAssetData = defaultAssetData

		const clonedUpdatedItems = cloneDeep(items)
		clonedUpdatedItems?.assetTags?.forEach((item: any) => {
			if (item.asset === value) {
				isNew = false
				updatedAssetData = item
			}
		})

		if (isNew) {
			clonedUpdatedItems?.assetTags?.push(defaultAssetData)
		}
		return { data: updatedAssetData, clonedItems: clonedUpdatedItems }
	}

	const handleAssetChange = (event: any) => {
		const { value } = event.target
		const { data } = getUpdated(value)
		setAssetData(data)
	}

	const getRunningValue = () => {
		const value = get(assetData, 'value', null)

		if (!value) return ''

		return get(value, '0') === 'running' ? 0 : 1
	}

	const handleRunningChange = (event: any) => {
		const { value } = event.target
		const { clonedItems: clonedUpdatedItems } = getUpdated(
			get(assetData, 'asset', '')
		)
		set(
			clonedUpdatedItems,
			'assetTags',
			get(clonedUpdatedItems, 'assetTags', []).map((asset: any) => {
				if (get(assetData, 'asset') === asset.asset) {
					const nonValue = value === '0' ? '1' : '0'
					asset.value[value] = 'running'
					asset.value[nonValue] = 'notrunning'
					setAssetData(asset)
				}
				return asset
			})
		)

		onSeqChange(clonedUpdatedItems)
	}

	const handleAssetTagChange = (event: any) => {
		let isError = !!error
		const updatedError = { tag: false, value: false }
		const tagValue = event.target.value
		isError = isEmpty(tagValue) || !TAG_VALIDATION_REGEX.test(tagValue)

		if (isError) {
			onError(true)
			updatedError.tag = true
		}

		if (updatedError.tag) {
			setError(updatedError)
		} else {
			setError(updatedError)
			const { value } = event.target
			const { clonedItems: clonedUpdatedItems } = getUpdated(
				get(assetData, 'asset', '')
			)
			set(
				clonedUpdatedItems,
				'assetTags',
				get(clonedUpdatedItems, 'assetTags', []).map((asset: any) => {
					if (get(assetData, 'asset') === asset.asset) {
						asset.tag = value
					}
					return asset
				})
			)

			onSeqChange(clonedUpdatedItems)
		}

		setAssetData({ ...assetData, tag: tagValue })
	}


	const { masterTag } = items

	return (
		<div className='selfConfigComponent'>
			<div style={{ width: '470px', maxHeight: '500px', overflowY: 'auto' }}>
				<Grid style={{ padding: '8px 0px 0 0' }}>
					<FormControl fullWidth>
						<Autocomplete
							size='medium'
							multiple
							options={[]}
							freeSolo
							value={masterTag || []}
							onChange={(e, value: any) => {
								handleMasterTagChange(value)
							}}
							renderTags={(
								value: any[],
								getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
							) =>
								value.map((option: string, index: any) => {
									return (
										<Tooltip title={option}>
											<Chip
												key={option}
												variant='outlined'
												label={option}
												{...getTagProps({ index })}
											/>
										</Tooltip>
									)
								})
							}
							renderInput={(params: any) => (
								<TextField size='small' {...params} label='Master Tags' />
							)}
						/>
					</FormControl>
				</Grid>

				<Grid style={{ padding: '8px 0px 0 0' }}>
					<FormControl fullWidth>
						<InputLabel>Asset Selection</InputLabel>
						<Select
							label='Asset Selection'
							// value={selectedOption}
							onChange={handleAssetChange}
						>
							{selectedAreaAssets.map((option: any) => (
								<MenuItem value={option.asset_alias}>
									{option.asset_alias}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid
					container
					style={{ margin: '0em 0em', padding: '0em', width: 'auto' }}
					spacing={2}
				>
					<Grid style={{ padding: '8px 0px 0 0' }} item xs={6}>
						<FormControl fullWidth>
							<TextField
								label='Asset Tag'
								value={get(assetData, 'tag', '')}
								onChange={handleAssetTagChange}
								error={!!error.tag}
								helperText={error.tag ? 'Invalid tag value' : ''}
							/>
						</FormControl>
					</Grid>

					<Grid style={{ padding: '8px 0px 0 0' }} item xs={6}>
						<FormControl fullWidth>
							<InputLabel>Running</InputLabel>
							<Select
								label='Running'
								placeholder='Running'
								value={getRunningValue()}
								onChange={handleRunningChange}
							>
								<MenuItem value={0}>0</MenuItem>
								<MenuItem value={1}>1</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default DynamicQuery
